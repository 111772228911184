import React, { createContext, useContext, useEffect, useState } from 'react';
import { ISsoCertificate } from '@/users/domain/vo/sso/ISsoCertificate';
import { useDIContext } from '@/Framework/DI/DIContext';
import SSORepository from '@/users/infrastructure/repository/SSORepository';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { ISsoCertificatePayload } from '@/users/domain/vo/sso/ISsoCertificatePayload';
import { useEnableSSOContext } from '@/condor/application/Accounts/AccountProfile/AccountSSO/EnableSSO';

interface ICertificateForDelete {
  id: string,
  name: string,
}

interface ICertificateForView {
  number: number,
  content: string,
}

const useUploadSsoCertificate = () => {
  const { container } = useDIContext();
  const { setIsCertificatesExist } = useEnableSSOContext();
  const ssoRepository = container.get(SSORepository);

  const [certificates, setCertificates] = useState<ISsoCertificate[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [certificateForDelete, setCertificateForDelete] = useState<ICertificateForDelete | null>(null);
  const [certificateForView, setCertificateForView] = useState<ICertificateForView | null>(null);

  useEffect(() => {
    setIsCertificatesExist(certificates.length > 0);
  }, [certificates]);

  const getListCertificates = async (accountId: number) => {
    setIsFetching(true);

    try {
      const data = await ssoRepository.getListCertificates(accountId);

      setCertificates(data);
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  };

  const addCertificate = async (payload: ISsoCertificatePayload) => {
    setIsFetching(true);

    try {
      await ssoRepository.addCertificate(payload);
      await getListCertificates(payload.accountId);
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  };

  const deleteCertificate = async (id: string, accountId: number) => {
    setIsFetching(true);

    try {
      await ssoRepository.deleteCertificate(id);
      setCertificateForDelete(null);
      await getListCertificates(accountId);
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  };

  return {
    certificates,
    isFetching,
    certificateForDelete,
    certificateForView,
    getListCertificates,
    addCertificate,
    setCertificateForDelete,
    deleteCertificate,
    setCertificateForView,
  };
};

type UploadSsoCertificateContextType = ReturnType<typeof useUploadSsoCertificate>;

const UploadSsoCertificateContext = createContext<UploadSsoCertificateContextType>(null);

export const useUploadSsoCertificateContext = () => {
  const context = useContext(UploadSsoCertificateContext);

  if (!context) {
    throw new Error('useUploadSsoCertificateContext must be used within the UploadSsoCertificateContext');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const UploadSsoCertificateContextProvider = ({ children }: IProps) => (
  <UploadSsoCertificateContext.Provider value={ useUploadSsoCertificate() }>
    { children }
  </UploadSsoCertificateContext.Provider>
);

export default UploadSsoCertificateContextProvider;
