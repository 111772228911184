import { useMemo } from 'react';
import cn from 'classnames';
import { components, OptionProps } from 'react-select';
import { Select } from '@dealroadshow/uikit/core/components/Select';
import { Field } from '@/Framework/UI/Organisms/FinalForm';
import styles from './contacts.scss';

interface IProps {
  label: string,
  dataTest: string,
  className?: string,
  collection: any[],
  getCompanyByQuery: (query: string) => void,
}

const CustomOption = (props: OptionProps<{ isVerified: boolean }>) => {
  const { data, children } = props;

  return (
    <components.Option { ...props }>
      <span className={ cn({ [styles.verifiedCompany]: data.isVerified }) }>
        { children }
      </span>
    </components.Option>
  );
};

const CompanyField = ({ label, dataTest, className, collection, getCompanyByQuery }: IProps) => {
  const companiesOptions = useMemo(() => collection.map((company) => ({
    label: company.name,
    value: company.id,
    isVerified: company.verified,
  })), [collection]);

  return (
    <Field
      name="company"
      className={ className }
      render={ ({ input, meta }) => (
        <Select
          placeholder="Select Account"
          label={ label }
          dataTest={ dataTest }
          options={ companiesOptions }
          onChange={ (option) => {
            input.onChange(option);
          } }
          components={ { Option: CustomOption } }
          onInputChange={ (input, { prevInputValue }) => {
            if (input !== prevInputValue) getCompanyByQuery(input);
          } }
          { ...input }
          { ...meta }
        />
      ) }
    />
  );
};

export default CompanyField;
