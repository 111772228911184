import { useState } from 'react';
import { useContactProfileContext } from '../ContactProfileContext';
import { useGenerateLinks } from '@/users/application/useGenerateLinks';
import { options } from './Section/options';

interface ILinkSectionState {
  value: string,
  label?: string,
  callbackUrl?: string,
}

export const useLinks = () => {
  const { user } = useContactProfileContext();
  const {
    isFetchingPassword,
    passwordResetLink,
    generateUserPasswordResetLink,
    isFetchingActivation,
    activationLink,
    generateUserActivationLink,
  } = useGenerateLinks();

  const [isVisibleInvalidateModal, setIsVisibleInvalidateModal] = useState<boolean>(false);
  const [passwordReset, setPasswordReset] = useState<ILinkSectionState>(options[0]);
  const [activation, setActivation] = useState<ILinkSectionState>(options[0]);

  return {
    isVisibleInvalidateModal,
    setIsVisibleInvalidateModal,

    passwordReset,
    setPasswordReset,
    isFetchingPassword,
    passwordResetLink,
    generateUserPasswordResetLink: () => generateUserPasswordResetLink(
      user.id,
      passwordReset.value,
      passwordReset.callbackUrl,
    ),

    activation,
    setActivation,
    isFetchingActivation,
    activationLink,
    generateUserActivationLink: () => generateUserActivationLink(
      user.email,
      activation.value,
      activation.callbackUrl,
    ),
  };
};
