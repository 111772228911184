import { useEffect, useMemo, useState } from 'react';
import { unionBy } from 'lodash';

import EventRepository from '@/openMarket/infrastructure/repository/Condor/EventRepository';
import ProfileRepository from '@/openMarket/infrastructure/repository/Condor/ProfileRepository';
import { IDisplayedProfile, IEvent, IRanking } from '@/openMarket/domain/Condor/vo/IEvent';
import useFetchCollection from '@/Framework/State/useFetchCollection';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { useDIContext } from '@/Framework/DI/DIContext';
import { getInitialValues, getUpdateFormValues } from '../helpers';
import { IFormValues, IUseEventFormProps } from '../interfaces';

interface IProps extends IUseEventFormProps {
  redirectToTable: () => void,
  getRoadshowData: (id: string) => void,
}

export const useEventFormInit = ({ eventId, initProfile, redirectToTable, getRoadshowData }: IProps) => {
  const { container } = useDIContext();
  const eventRepository = container.get<EventRepository>(EventRepository);
  const profileRepository = container.get<ProfileRepository>(ProfileRepository);

  const [profilesCollection, setProfilesCollection] = useState<IDisplayedProfile[]>([]);
  const [rankingsCollection, setRankingsCollection] = useState<IRanking[]>([]);
  const [event, setEvent] = useState<IEvent | null>(null);

  const [isFetchingRanking, setIsFetchingRanking] = useState<boolean>(false);
  const [isFetchingInitialValues, setIsFetchingInitialValues] = useState<boolean>(false);

  const initEventForm = async () => {
    setIsFetchingInitialValues(true);
    try {
      const event = await eventRepository.getEventById(eventId);
      getRoadshowData(event.roadshowId);
      setEvent(event);
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
      redirectToTable();
    } finally {
      setIsFetchingInitialValues(false);
    }
  };

  const initialValues = useMemo<IFormValues>(() => {
    if (event) {
      return getUpdateFormValues(event);
    }

    return getInitialValues(initProfile);
  }, [event]);

  useEffect(() => {
    if (eventId) {
      initEventForm();
    }
  }, [eventId]);

  const {
    collection,
    isFetching: isFetchingProfiles,
    paginate,
    page,
    debouncedSearch,
    getCollection,
    totalCount,
  } = useFetchCollection(
    profileRepository.getProfiles,
    {},
    {
      perPage: 100,
    },
  );

  useEffect(() => {
    if (collection.length && profilesCollection.length !== totalCount) {
      setProfilesCollection((prevState) => unionBy(prevState, collection, 'id'));
    }
  }, [collection]);

  const getRankings = async () => {
    setIsFetchingRanking(true);
    try {
      const rankingsCollections = await eventRepository.getRankings();
      setRankingsCollection(rankingsCollections);
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
    } finally {
      setIsFetchingRanking(false);
    }
  };

  useEffect(() => {
    getRankings();
  }, []);

  const isFetchingData =
    collection.length && rankingsCollection.length
      ? false
      : isFetchingRanking || isFetchingInitialValues || isFetchingProfiles;

  return {
    rankingsCollection,
    profilesCollection,
    isFetchingProfiles,
    isFetchingData,
    paginate,
    page,
    debouncedSearch,
    getCollection,
    totalCount,
    event,
    initialValues,
  };
};
