import { RpcError } from '@dealroadshow/json-rpc-dispatcher';
import actionTypes from './actionTypes';
import { getCollectionActions } from '@/Framework/State/Redux/collectionFactory';
import createAction from '@/Framework/State/Redux/createAction';
import createAsyncRequest from '@/Framework/State/Redux/createAsyncRequest';
import ImportRepository from '@/finsight/infrastructure/repository/ImportRepository';
import selectors from './selectors';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';

/**
 * @param {String} importId
 */
export const deleteImport = (importId) => (dispatch, getState) => {
  const importRepository = getState().container.get(ImportRepository);

  return dispatch(createAsyncRequest({
    method: importRepository.deleteCompanyImport,
    types: [
      actionTypes.DELETE_CONDOR_FINSIGHT_IMPORT_COMPANY_AUDIT_TRAIL,
      actionTypes.DELETE_CONDOR_FINSIGHT_IMPORT_COMPANY_AUDIT_TRAIL_SUCCESS,
      actionTypes.DELETE_CONDOR_FINSIGHT_IMPORT_COMPANY_AUDIT_TRAIL_ERROR,
    ],
    payload: {
      importId,
    },
    callbacks: {
      onError: (data) => {
        // TODO: When BE will send all data for error, we can move this huck to FE error text
        if ((data.error instanceof RpcError) && data.error.getCode() >= 70501 && data.error.getCode() <= 70515) {
          AlertManager.error(data.error.getMessage());
        } else {
          AlertManager.error(getErrorMessage(data.error));
        }
      },
      onSuccess: ({ dispatch, getState }) => {
        const deletedItem = selectors.getDeleteItem(getState());
        dispatch(changeDeleteImportModalVisible(false));
        dispatch(resetSelectedImport());
        AlertManager.success(
          `You have successfully deleted import # ${ deletedItem.importNumber }
          with ${ deletedItem.companyCount } compan${ deletedItem.companyCount > 1 ? 'ies' : 'y' }.`,
        );
        dispatch(getCompaniesImportByFilter());
      },
    },
  }));
};

/**
 * @param {Boolean} payload
 */
export const changeDeleteImportModalVisible = (payload) => createAction(
  actionTypes.CHANGE_CONDOR_FINSIGHT_IMPORT_COMPANY_AUDIT_TRAIL_DELETE_MODAL_VISIBLE,
  payload,
);

/**
 * @param {String} payload
 */
export const setSelectedImport = (payload) => createAction(
  actionTypes.SET_CONDOR_FINSIGHT_IMPORT_COMPANY_AUDIT_TRAIL_DELETE_ITEM,
  payload,
);

export const resetSelectedImport = () => createAction(
  actionTypes.SET_CONDOR_FINSIGHT_IMPORT_COMPANY_AUDIT_TRAIL_DELETE_ITEM,
  {},
);

export const {
  getCollection: getCompaniesImportCollection,
  sort,
  itemsPerPage,
  paginate,
  reset: resetCompaniesImportCollection,
} = getCollectionActions(
  actionTypes,
  (payload, dispatch, getState) => {
    const importRepository = getState().container.get(ImportRepository);
    return importRepository.companiesImport(payload);
  },
);

export const getCompaniesImportByFilter = () => async (dispatch, getState) => {
  const state = getState();

  dispatch(getCompaniesImportCollection({
    perPage: selectors.getPerPage(state),
    page: selectors.getPage(state),
    sortBy: selectors.getSortBy(state),
    sortOrder: selectors.getSortOrder(state),
  }));
};
