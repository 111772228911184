import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import AccountsRepository from '@/dmPortal/infrastructure/repository/AccountsRepository';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import getMessage from '@/Framework/Message/getMessage';
import { messageCodes } from '@/Framework/Message/messages';

const useAccounts = () => {
  const { container } = useDIContext();
  const [isFetching, setIsFetching] = useState(false);

  const saveAccounts = async (payload: {
    id: number | string,
    suggestNames?: string[],
    suggestFor?: string,
    name: string,
    tenant: string,
  }, accountsForSave?: { [key: string]: string[] }, isNotificationHidden?: boolean) => {
    setIsFetching(true);

    try {
      const accountsRepository = container.get<AccountsRepository>(AccountsRepository);
      if (accountsForSave) {
        const promises = Object.keys(accountsForSave).map((suggestFor) => (
          accountsRepository.requestToCreateAccount({
            ...payload,
            suggestNames: accountsForSave[suggestFor],
            suggestFor,
          })
      ));
        await Promise.all(promises);
      } else {
        await accountsRepository.requestToCreateAccount(payload);
      }
      !isNotificationHidden && AlertManager.success(getMessage(messageCodes.ACCOUNT_NAME_SUGGESTED_CONTACT_US, {
        fieldName: payload.suggestFor ?? 'account',
      }));
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    saveAccounts,
  };
};

export default useAccounts;
