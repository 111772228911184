import { useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useArticleProfileContext } from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticleProfile';
import { IArticleActionModal } from '@/condor/domain/vo/Finsight/CreditFlowResearch/IArticle';
import { useDIContext } from '@/Framework/DI/DIContext';
import CreditFlowResearchRepository
  from '@/condor/infrastructure/repository/creditFlowResearch/CreditFlowResearchRepository';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { CONDOR_FINSIGHT_CREDIT_FLOW_ARTICLES_LIST } from '@/condor/ui/common/constants';
import { useForm } from '@/Framework/UI/Organisms/FinalForm';

const useUnpublishModal = () => {
  const { container } = useDIContext();
  const { push } = useHistory();
  const { change } = useForm();
  const creditFlowResearchRepository = container.get<CreditFlowResearchRepository>(CreditFlowResearchRepository);
  const { initialFormData, initialArticle, setIsActive } = useArticleProfileContext();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isUnpublishing, setIsUnpublishing] = useState(false);

  const article: IArticleActionModal = useMemo(() => ({
    headline: initialFormData.headline,
    author: initialArticle?.author ?? null,
    themes: initialFormData.themes.map((theme) => `${ theme.regionName } ${ theme.label }`),
  }), [initialFormData]);

  const onUnpublish = async () => {
    try {
      setIsUnpublishing(true);
      await creditFlowResearchRepository.unpublishArticleById(initialFormData.id);
      setIsActive(false);
      setIsOpenModal(false);
      change('isActive', false);
      AlertManager.success('Post has been successfully unpublished');
      push(CONDOR_FINSIGHT_CREDIT_FLOW_ARTICLES_LIST);
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
    } finally {
      setIsUnpublishing(false);
    }
  };

  return { isOpenModal, setIsOpenModal, article, onUnpublish, isUnpublishing };
};

export default useUnpublishModal;
