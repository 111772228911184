import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import RoadshowRepository from '@/dealroadshow/infrastructure/repository/RoadshowRepository';
import { getErrorMessage, getMessage } from '@/Framework/Message/Mapper/getMessage';
import { useDIContext } from '@/Framework/DI/DIContext';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { messageCodes } from '@/Framework/Message/messages';
import { ICustomModal } from '@/condor/ui/components/DealRoadshows/Roadshow/modules/CustomModal/interfaces';

export const useCustomModal = () => {
  const { roadshowId } = useParams();
  const { container } = useDIContext();
  const roadshowRepository = container.get<RoadshowRepository>(RoadshowRepository);

  const [customModalData, setCustomModalData] = useState<ICustomModal>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    getCustomModal();
  }, []);

  const getCustomModal = async () => {
    setIsFetching(true);
    try {
      const response = await roadshowRepository.getAdminCustomModal(roadshowId);
      setCustomModalData(response);
    } catch (err) {
      AlertManager.error(getErrorMessage(err));
    } finally {
      setIsFetching(false);
    }
  };

  const saveCustomModal = async (data: ICustomModal) => {
    setIsFetching(true);
    try {
      await roadshowRepository.saveAdminCustomModal({
        id: roadshowId,
        ...data,
      });
      AlertManager.success(getMessage(messageCodes.CONDOR_SAVED));
      getCustomModal();
    } catch (err) {
      AlertManager.error(getErrorMessage(err));
    } finally {
      setIsFetching(false);
    }
  };

  return {
    getCustomModal,
    roadshowId,
    customModalData,
    isFetching,
    setIsFetching,
    saveCustomModal,
  };
};
