import Modal from '@dealroadshow/uikit/core/components/Modal';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';

import styles from './invalidatePasswordModal.scss';
import modalStyles from '@/condor/ui/common/Layout/modal.scss';
import {
  useContactProfileContext,
} from '@/condor/ui/components/AccountsAndContacts/Contacts/ContactProfile/ContactProfileContext';
import { useRegeneratePassword } from '@/users/application/useRegeneratePassword';

interface IProps {
  onCloseClicked: () => void,
}

const InvalidatePasswordModal = ({ onCloseClicked }: IProps) => {
  const { user } = useContactProfileContext();
  const { regeneratePassword } = useRegeneratePassword();

  return (
    <Modal
      title="Invalidate Password"
      onCloseClicked={ onCloseClicked }
      isVisible
      dataTest="invalidatePasswordModal"
      footer={ (
        <>
          <Button
            variant={ ButtonVariantType.action }
            onClick={ async () => {
              await regeneratePassword(user);
              onCloseClicked();
            } }
            title="Invalidate Password"
            dataTest="invalidatePasswordButton"
          />
          <Button
            variant={ ButtonVariantType.text }
            onClick={ onCloseClicked }
            title="Cancel"
            dataTest="invalidatePasswordCancelButton"
          />
        </>
      ) }
    >
      <div className={ styles.warning }>
        Invalidating password will block this contact from logging into all Finsight applications. Invalidating
        password will NOT generate an automated systems email notifying the Contact of this action. Support must
        manually notify Contact to change their password.
      </div>
      <div className={ modalStyles.areYouSureLabel }>
        Are you sure you want to Invalidate the password for <b>{ user.email }</b>?
      </div>
    </Modal>
  );
};

export default InvalidatePasswordModal;
