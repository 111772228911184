import { IAudioVideoFormData, IAudioVideoFormDataPayload, IUploadedMedia } from '../interfaces';
import { ICuePoint, IVideoOnlyCuePoint } from '@/dealroadshow/domain/vo/roadshow/ICuePoint';

const filterCuePoints = (cuePointsData: ICuePoint[] = []) => (
  cuePointsData
    .filter(({ slideNumber, time }) => slideNumber && time >= 0)
    .map(({ slideNumber, time, displayName }) => ({
      slideNumber,
      time,
      displayName: displayName && displayName !== '<p></p>' ? displayName : null,
    }))
);

const filterVideoOnlyCuePoints = (cuePointsData: IVideoOnlyCuePoint[] = []) => (
  cuePointsData
    .filter(({ number, time }) => number && time >= 0)
    .map(({ number, time, sectionName = null }) => ({
      number,
      time,
      sectionName,
    }))
);

function filterDealAudioVideoFormData(
  formData: IAudioVideoFormData,
  id: string,
  uploadedMedia: IUploadedMedia,
): IAudioVideoFormDataPayload {
  const saveData = {
    id,
    type: formData.type,
    presentationMedia: {
      mediaAudio: uploadedMedia.presentationMediaAudio.audio,
      mediaVideo: uploadedMedia.presentationMediaVideo.video,
    },
    cuePoints: filterCuePoints(formData.cuePoints),
    videoOnlyCuePoints: filterVideoOnlyCuePoints(formData.videoOnlyCuePoints),
    audioConferenceCode: formData.audioConferenceCode || null,
  };
  return {
    ...saveData,
  };
}

export default filterDealAudioVideoFormData;
