import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFieldArray } from '@/Framework/UI/Organisms/FinalForm';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import {
  initUploadRelatedRoadshowLogo,
  uploadClear,
  uploadRelatedRoadshowLogoCancel,
} from '@/Framework/UI/Organisms/Upload/actions';
import { messageCodes } from '@/Framework/Message/messages';
import { RELATED_ROADSHOWS_FIELD_NAME } from '../constants';
import { IRelatedRoadshow } from '../../interfaces';

export const useEdit = () => {
  const [editModalRow, setEditModalRow] = useState<IRelatedRoadshow | null>(null);
  const { fields: { update, value: relatedRoadshows } } = useFieldArray<IRelatedRoadshow>(RELATED_ROADSHOWS_FIELD_NAME);
  // @ts-ignore
  const updatedLogo = useSelector((state) => state.upload.relatedRoadshowLogo);
  const dispatch = useDispatch();

  const showEditModal = useCallback((row: IRelatedRoadshow) => {
    if (row.logoUrl) {
      dispatch(initUploadRelatedRoadshowLogo({ url: row.logoUrl }));
    } else {
      dispatch(uploadClear());
    }

    setEditModalRow(row);
  }, []);

  const hideEditModal = useCallback(() => {
    update(editModalRow.rowIndex, {
      ...editModalRow,
      text: editModalRow.text,
      logoName: editModalRow.logoName,
      logoUrl: editModalRow.logoUrl,
    });
    setEditModalRow(null);
  }, [editModalRow]);

  const applyEditModal = () => {
    const { rowIndex } = editModalRow;

    if (!updatedLogo.canSave) {
      AlertManager.warning(getMessage(messageCodes.CONDOR_FILE_UPLOADING, { name: updatedLogo.name }));
    } else {
      update(rowIndex, {
        ...relatedRoadshows[rowIndex],
        logoUrl: updatedLogo.url || null,
        logoName: updatedLogo?.uploadFile?.name || null,
      });
      dispatch(uploadRelatedRoadshowLogoCancel(updatedLogo.process.uuid));
      setEditModalRow(null);
    }
  };

  return {
    editModalRow,
    showEditModal,
    hideEditModal,
    applyEditModal,
  };
};
