import isEmpty from 'lodash/isEmpty';
import { ICompaniesListPayload, ICompany } from '@/finsight/domain/vo/Company/ICompany';
import { useDIContext } from '@/Framework/DI/DIContext';
import CompanyRepository from '@/finsight/infrastructure/repository/CompanyRepository';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';

const useCompany = () => {
  const { container } = useDIContext();
  const companyRepository = container.get<CompanyRepository>(CompanyRepository);

  const loadCompanies = async (payload: ICompaniesListPayload): Promise<ICompany[]> => {
    try {
      const company = await companyRepository.getCompaniesList(payload);

      return company.collection;
    } catch (error) {
      AlertManager.error(getErrorMessage(error));

      return [];
    }
  };

  const loadParentByIds = async (parentIds: string[]) => {
    if (isEmpty(parentIds)) {
      return [];
    }

    const payload = { page: 1, perPage: 200 };
    const parentFilter = { includedRole: 'parent', includedIds: parentIds };

    return loadCompanies({ ...payload, filter: parentFilter });
  };

  const loadIssuerByIds = async (issuerIds: string[]) => {
    if (isEmpty(issuerIds)) {
      return [];
    }

    const payload = { page: 1, perPage: 200 };
    const issuerFilter = { includedRole: 'issuer', includedIds: issuerIds };

    return loadCompanies({ ...payload, filter: issuerFilter });
  };

  return { loadCompanies, loadParentByIds, loadIssuerByIds };
};

export default useCompany;
