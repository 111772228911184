import { createContext, useContext, useEffect, useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import SectorRepository from '@/finsight/infrastructure/repository/SectorRepository';
import IndustryRepository from '@/finsight/infrastructure/repository/IndustryRepository';
import { ISector } from '@/dealroadshow/domain/vo/sector/Sector';
import { IIndustry } from '@/finsight/domain/vo/IDeal';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';

const useSectorIndustries = () => {
  const { container } = useDIContext();

  const sectorRepository = container.get<SectorRepository>(SectorRepository);
  const industryRepository = container.get<IndustryRepository>(IndustryRepository);

  const [sectors, setSectors] = useState<ISector[]>([]);
  const [industries, setIndustries] = useState<IIndustry[]>([]);
  const [isInitialized, setIsInitialized] = useState(false);

  const loadSectorsIndustries = async () => {
    try {
      const [sectors, industries] = await Promise.all([
        sectorRepository.getList(),
        industryRepository.getList(),
      ]);
      setIsInitialized(true);
      setSectors(sectors);
      setIndustries(industries);
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
    }
  };

  useEffect(() => {
    if (sectors.length || industries.length) return;
    loadSectorsIndustries();
  }, [sectors.length, industries.length]);

  return { sectors, industries, isInitialized };
};

type SectorsIndustriesContextType = ReturnType<typeof useSectorIndustries>;

const SectorsIndustriesContext = createContext<SectorsIndustriesContextType>(null);

export const useSectorsIndustriesContext = () => {
  const context = useContext(SectorsIndustriesContext);

  if (!context) {
    throw new Error('useSectorsIndustriesContext must be used within a SectorsIndustriesContextProvider');
  }

  return context;
};

export const SectorsIndustriesContextProvider = ({ children }) => (
  <SectorsIndustriesContext.Provider value={ useSectorIndustries() }>
    { children }
  </SectorsIndustriesContext.Provider>
);
