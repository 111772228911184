import React, { useMemo, useCallback } from 'react';
import { Field } from 'redux-form';
import cn from 'classnames';
import { components as ReactSelectComponents } from 'react-select';

import Product from '@/finsight/domain/Product';
import { DEAL_PROFILE_FIELDS } from '../constants';
import { ISSUER, PARENT, UNDERWRITER } from '@/finsight/application/actions/pageFilters/constants';

import withTooltipOnInputLabel from '@/Framework/UI/Molecules/Form/withTooltipOnInputLabel';
import { mapOptionToValue } from '@/Framework/dataHelpers/selectOptionMappers';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import { messageCodes } from '@/Framework/Message/messages';

import TableRowFields from '@/condor/ui/common/Layout/TableRowFields';
import ReduxFormSelect from '@/Framework/UI/Molecules/Form/Select/legacy/ReduxFormSelect';
import { CheckboxInput } from '@dealroadshow/uikit/core/components/Checkbox';
import Input from '@dealroadshow/uikit/core/components/Input';
import IconCancel from '@dealroadshow/uikit/core/components/Icon/IconCancel';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import CompanySelect from './CompanySelectContainer';
import IssuerSelect from './IssuerSelect';
import CondorDatePicker from '@/condor/ui/common/CondorDatePicker';
import CopyToClipboardButton from '@/Framework/UI/Atoms/Button/CopyToClipboardButton';
import type { ITranche } from '@/condor/domain/vo/Finsight/Deal/Tranche';

import styles from '../dealProfile.scss';
import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';

const IssuerSelectWithTooltipOnLabel = withTooltipOnInputLabel(IssuerSelect);
const CompanySelectWithTooltipOnLabel = withTooltipOnInputLabel(CompanySelect);

interface IFieldValue {
  abbreviation: string,
  issuerTicker: string,
  label: string,
  name: string,
  value: string,
}

interface IProps {
  isSecFiling: boolean,
  isCreateNewDeal: boolean,
  options: any,
  formValues: any,
  setPrimaryRegion: (region: any) => void,
  setAdditionalRegions: (regions: any[]) => void,
  clearSectorSubsector: () => void,
  onIssuerSelectChange: (value: any) => void,
  tranches: ITranche[],
}

const DealDetails = (
  {
    isSecFiling,
    isCreateNewDeal,
    options,
    setPrimaryRegion,
    setAdditionalRegions,
    clearSectorSubsector,
    tranches,
    formValues: {
      primaryRegion,
      product,
      additionalRegions,
      structuringLeads,
      jointLeads,
    },
    onIssuerSelectChange,
  }: IProps,
) => {
  const tranchesSize = tranches.filter(
    (tranche) => !tranche.droppedTranche && !tranche.nonCountable && !tranche.isExlcuded,
  ).length;

  const requiredFieldCellCls = cn(styles.smallInputsContainer, styles.requiredField);
  const isProductAbs = Product.isAbsProductId(mapOptionToValue(product));
  const isProductLM = Product.isLMProductId(mapOptionToValue(product));

  const onCountryChange = useCallback(
    (country) => {
      if (additionalRegions.find(({ value }) => value === country?.region?.value)) {
        setAdditionalRegions(additionalRegions.filter(({ value }) => value !== country?.region.value));
        AlertManager.success(getMessage(
          messageCodes.CONDOR_DEAL_PROFILE_DUPLICATE_REGION_REMOVED,
          {
            additionalRegionsLabel: DEAL_PROFILE_FIELDS.ADDITIONAL_REGIONS.label,
            newCountryRegion: country?.region.displayName,
          },
        ));
      }
      setPrimaryRegion(country?.region || null);
    },
    [additionalRegions.length],
  );

  const onProductChange = useCallback(
    (product) => {
      if (!Product.isAbsProductId(product?.value)) {
        clearSectorSubsector();
      }
    },
    [],
  );

  const getMultiValueRemoveRenderer = (props) => (
    <ReactSelectComponents.MultiValueRemove { ...props }>
      <IconCancel className={ styles.multiValueRemoveIcon } />
    </ReactSelectComponents.MultiValueRemove>
  );

  const abbreviationValueRenderer = ({
    data,
    ...otherProps
  }) => (
    // @ts-ignore
    <ReactSelectComponents.MultiValueLabel { ...otherProps }>
      <Tooltip content={ data.name }>
        { data.abbreviation || data.label }
      </Tooltip>
    </ReactSelectComponents.MultiValueLabel>
  );
  const issuerTickerValueRenderer = ({
    data,
    ...otherProps
  }) => (
    // @ts-ignore
    <ReactSelectComponents.MultiValueLabel { ...otherProps }>
      { data.issuerTicker || data.label };
    </ReactSelectComponents.MultiValueLabel>
  );
  const additionalRegionsOptions = useMemo(
    () => (
      options.regions
        ? options.regions.filter((region) => region.value !== mapOptionToValue(primaryRegion))
        : []
    ),
    [primaryRegion],
  );

  const onIssuerChange = useCallback((value) => {
    if (!isSecFiling || !isCreateNewDeal || !value) {
      return;
    }
    onIssuerSelectChange(value);
  }, []);

  const getTextToCopy = (fieldValues: IFieldValue[]) => fieldValues.map((val) => val.abbreviation)
    .join(', ');

  return (
    <div className={ styles.panelContent }>
      <div className={ styles.dealRow }>
        <Field
          { ...DEAL_PROFILE_FIELDS.PRIMARY_ISSUER }
          placeholder="Select Issuer"
          className={ cn(styles.requiredField, spacesStyles.mbn) }
          component={ IssuerSelectWithTooltipOnLabel }
          includedRole={ ISSUER }
          onChange={ onIssuerChange }
          dataTest="dealPrimaryIssuer"
        />
        <Field
          { ...DEAL_PROFILE_FIELDS.PARENT }
          placeholder="Select Parent / Sponsor"
          className={ cn(styles.requiredField, spacesStyles.mbn) }
          component={ CompanySelectWithTooltipOnLabel }
          includedRole={ PARENT }
          dataTest="dealParent"
        />
      </div>
      <TableRowFields
        fields={
          [
            {
              ...DEAL_PROFILE_FIELDS.SERIES,
              placeholder: '20XX-1',
              component: Input,
              cellClassName: requiredFieldCellCls,
              width: 218,
              maxWidth: 218,
              size: 'small',
              dataTest: 'series',
            },
            {
              ...DEAL_PROFILE_FIELDS.COUNTRY,
              placeholder: 'Select country',
              component: ReduxFormSelect,
              isSlim: true,
              options: options.countries,
              className: styles.requiredField,
              cellClassName: requiredFieldCellCls,
              onChange: onCountryChange,
              width: 218,
              maxWidth: 218,
              dataTest: 'country',
            },
            {
              ...DEAL_PROFILE_FIELDS.PRIMARY_REGION,
              placeholder: 'Select country',
              component: ReduxFormSelect,
              isSlim: true,
              options: options.regions,
              disabled: true,
              cellClassName: styles.smallInputsContainer,
              width: 130,
              maxWidth: 130,
              dataTest: 'primaryRegionCountry',
            },
            {
              ...DEAL_PROFILE_FIELDS.PRODUCT,
              placeholder: 'Select one',
              component: ReduxFormSelect,
              isSlim: true,
              options: options.products,
              width: 218,
              maxWidth: 218,
              cellClassName: requiredFieldCellCls,
              onChange: onProductChange,
              dataTest: 'product',
            },
            ...(isProductLM ? [{
              ...DEAL_PROFILE_FIELDS.LM_TYPE,
              placeholder: 'Select type',
              component: ReduxFormSelect,
              isSlim: true,
              isMulti: true,
              width: 218,
              maxWidth: 218,
              options: options.lmTypeList,
              dataTest: 'lmType',
            }] : []),
            ...(
              !isProductAbs
                ? []
                : [{
                  ...DEAL_PROFILE_FIELDS.ABS_SECTOR_SUBSECTOR,
                  placeholder: 'Select one',
                  component: ReduxFormSelect,
                  isSlim: true,
                  width: 218,
                  maxWidth: 218,
                  options: options.sectors,
                  cellClassName: requiredFieldCellCls,
                  dataTest: 'absSectorSubsector',
                }]
            ),
            {
              ...DEAL_PROFILE_FIELDS.STATUS,
              placeholder: 'Select status',
              component: ReduxFormSelect,
              isSlim: true,
              options: options.statusList,
              width: 218,
              maxWidth: 218,
              cellClassName: requiredFieldCellCls,
              dataTest: 'status',
            },
          ]
        }
      />
      <div className={ spacesStyles.mbl } />
      <TableRowFields
        fields={
          [
            {
              ...DEAL_PROFILE_FIELDS.ADDITIONAL_ISSUERS,
              placeholder: 'Select issuers',
              component: IssuerSelect,
              isSlim: true,
              isMulti: true,
              components: {
                MultiValueLabel: issuerTickerValueRenderer,
                MultiValueRemove: getMultiValueRemoveRenderer,
              },
              cellClassName: styles.smallInputsContainer,
              width: 279,
              maxWidth: 279,
              dataTest: 'additionalIssuers',
            },
            ...(isSecFiling ? [] : [
              {
                ...DEAL_PROFILE_FIELDS.ANNOUNCED_DATE,
                component: CondorDatePicker,
                cellClassName: styles.smallInputsContainer,
                width: 188,
                maxWidth: 188,
                dataTest: 'announcedDate',
              }]),
            {
              ...DEAL_PROFILE_FIELDS.LAST_UPDATED,
              component: CondorDatePicker,
              cellClassName: styles.smallInputsContainer,
              width: 188,
              maxWidth: 188,
              dataTest: 'lastUpdated',
            },
            {
              ...DEAL_PROFILE_FIELDS.TIMING,
              component: Input,
              cellClassName: styles.smallInputsContainerAligned,
              width: 188,
              maxWidth: 188,
              dataTest: 'timing',
            },
            ...(isSecFiling ? [] : [
              {
                ...DEAL_PROFILE_FIELDS.PRICING_DATE,
                component: CondorDatePicker,
                cellClassName: styles.smallInputsContainer,
                width: 188,
                maxWidth: 188,
                dataTest: 'pricingDate',
              },
              {
                ...DEAL_PROFILE_FIELDS.SETTLEMENT_DATE,
                component: CondorDatePicker,
                cellClassName: styles.smallInputsContainer,
                width: 188,
                maxWidth: 188,
                dataTest: 'settlementDate',
              },
            ]),
          ]
        }
      />
      <div className={ spacesStyles.mbl } />
      <TableRowFields
        fields={
          [
            {
              ...DEAL_PROFILE_FIELDS.ESTIMATED_DEAL_SIZE,
              component: Input,
              cellClassName: styles.smallInputsContainerAligned,
              width: 160,
              maxWidth: 160,
              dataTest: 'estimatedDealSize',
            },
            {
              ...DEAL_PROFILE_FIELDS.TRANCHES_NUMBER,
              component: Input,
              input: {
                value: tranchesSize,
                disabled: true,
              },
              cellClassName: cn(styles.smallInputsContainerAligned, styles.trancheAmountInput),
              width: 71,
              maxWidth: 71,
              dataTest: 'tranchesNumber',
            },
            {
              ...DEAL_PROFILE_FIELDS.ADDITIONAL_REGIONS,
              placeholder: 'Select regions',
              component: ReduxFormSelect,
              isSlim: true,
              options: additionalRegionsOptions,
              cellClassName: styles.smallInputsContainer,
              isMulti: true,
              width: 145,
              maxWidth: 145,
              dataTest: 'additionalRegions',
            },
            {
              ...DEAL_PROFILE_FIELDS.ROADSHOW_DATES,
              component: Input,
              cellClassName: styles.smallInputsContainerAligned,
              width: 185,
              maxWidth: 185,
              dataTest: 'roadshowDates',
            },
            {
              ...DEAL_PROFILE_FIELDS.STRUCTURING_LEADS,
              placeholder: 'Select leads',
              component: CompanySelect,
              isSlim: true,
              isMulti: true,
              components: {
                MultiValueLabel: abbreviationValueRenderer,
                MultiValueRemove: getMultiValueRemoveRenderer,
              },
              cellClassName: styles.smallInputsContainer,
              includedRole: UNDERWRITER,
              width: 185,
              minWidth: 185,
              labelSuffix: (
                <CopyToClipboardButton
                  content={ getTextToCopy(structuringLeads) }
                  dataTest="actionCopyButton"
                  customStyles={ styles.copyButton }
                />
              ),
            },
            {
              ...DEAL_PROFILE_FIELDS.JOINT_LEADS,
              placeholder: 'Select joint leads',
              component: CompanySelect,
              isSlim: true,
              isMulti: true,
              components: {
                MultiValueLabel: abbreviationValueRenderer,
                MultiValueRemove: getMultiValueRemoveRenderer,
              },
              cellClassName: styles.smallInputsContainer,
              includedRole: UNDERWRITER,
              width: 185,
              minWidth: 185,
              labelSuffix: (
                <CopyToClipboardButton
                  content={ getTextToCopy(jointLeads) }
                  dataTest="actionCopyButton"
                  customStyles={ styles.copyButton }
                />
              ),
            },
            ...(isSecFiling ? [] : [
              {
                ...DEAL_PROFILE_FIELDS.VERIFIED,
                cellClassName: alignStyles.alignCenter,
                component: CheckboxInput,
                type: 'checkbox',
                width: 60,
                maxWidth: 60,
                dataTest: 'verified',
              },
            ]),
            {
              ...DEAL_PROFILE_FIELDS.MARKETING,
              component: CheckboxInput,
              cellClassName: alignStyles.alignCenter,
              width: 60,
              maxWidth: 60,
              dataTest: 'marketing',
            },
            ...(isSecFiling ? [] : [
              {
                ...DEAL_PROFILE_FIELDS.INV_SET_AVAILABLE,
                cellClassName: alignStyles.alignCenter,
                component: CheckboxInput,
                type: 'checkbox',
                width: 60,
                maxWidth: 60,
                dataTest: 'invSetAvailable',
              },
            ]),
            ...(isProductAbs ? [] : [{
                ...DEAL_PROFILE_FIELDS.PAR,
                component: CheckboxInput,
                cellClassName: alignStyles.alignCenter,
                width: 60,
                maxWidth: 60,
                dataTest: 'par',
              }]
            ),
          ]
        }
      />
    </div>
  );
};

export default DealDetails;
