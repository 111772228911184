import { CONDOR_DRS_ROADSHOW_URL } from '@/condor/ui/common/constants';
import RoadshowType from '@/dealroadshow/domain/RoadshowType';
import getMessage from '@/Framework/Message/getMessage';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { removeBreadcrumb, setBreadcrumb } from '@/condor/ui/common/Breadcrumb/actions';
import * as actionTypes from './actionTypes';
import { messageCodes } from '@/Framework/Message/messages';
import createAction from '@/Framework/State/Redux/createAction';
import { getAuditTrailByFilter } from '@/dealroadshow/application/actions/auditTrail/collection/actions';
import auditTrailSelectors from '@/dealroadshow/application/actions/auditTrail/collection/selectors';
import { AUDIT_TRAIL_IS_UNAVAILABLE_TOOLTIP } from '@/dealroadshow/application/actions/auditTrail/collection/constants';
import { push } from 'connected-next-router';
import isEmpty from 'lodash/isEmpty';

export const roadshowTabEnabled = (payload: string) => createAction(actionTypes.ROADSHOW_TABS_ENABLED, payload);

export const roadshowTabDisabled = (link = '', disabledTooltip = '') => createAction(actionTypes.ROADSHOW_TABS_DISABLED, {
  link,
  disabledTooltip,
});

export function checkTabState(roadshowId: string, nextTabLink: string, prevTabLink?: string) {
  return async (dispatch, getState) => {
    let dealSettingsData = getState().dealRoadshows.roadshow.dealSettings.data;
    let disabledAudioVideo = false;
    let disabledLoginLimit = false;
    if (isEmpty(dealSettingsData)) {
      return;
    }

    let isEntryCodeLoginLimitSet = dealSettingsData.entryCodes.some(
      (entryCode) => entryCode.deletedAt === null && entryCode.loginsMaxNumber !== null,
    );

    if (
      RoadshowType.isSlidesAndAudio(dealSettingsData.type) || RoadshowType.isSlidesAndVideo(dealSettingsData.type)
    ) {
      dispatch(roadshowTabEnabled('deal-slides'));
      dispatch(roadshowTabEnabled('audio-video'));
    } else if (RoadshowType.isVideoOnly(dealSettingsData.type)) {
      dispatch(roadshowTabDisabled('deal-slides'));
      dispatch(roadshowTabEnabled('audio-video'));
    } else {
      disabledAudioVideo = true;
      dispatch(roadshowTabDisabled('audio-video'));
      dispatch(roadshowTabEnabled('deal-slides'));
    }

    if (dealSettingsData.loginsMaxNumber !== null || isEntryCodeLoginLimitSet) {
      dispatch(roadshowTabEnabled('login-limits'));
    } else {
      disabledLoginLimit = true;
      dispatch(roadshowTabDisabled('login-limits'));
    }

    if (prevTabLink) {
      dispatch(removeBreadcrumb(`${ CONDOR_DRS_ROADSHOW_URL }/${ roadshowId }/${ prevTabLink }`));
    }

    const isAdmin = true;
    await dispatch(getAuditTrailByFilter(roadshowId, isAdmin));
    const auditTrailReleaseDate = auditTrailSelectors(isAdmin).getReleaseDate(getState());

    if (auditTrailReleaseDate) {
      const disabledTooltip = AUDIT_TRAIL_IS_UNAVAILABLE_TOOLTIP(auditTrailReleaseDate);
      dispatch(roadshowTabDisabled('audit-trail', disabledTooltip));
    } else {
      dispatch(roadshowTabEnabled('audit-trail'));
    }

    let { tabs } = getState().dealRoadshows.roadshow;

    if (
      (nextTabLink === 'login-limits' && disabledLoginLimit) ||
      (nextTabLink === 'audio-video' && disabledAudioVideo)
    ) {
      let tabName = tabs.filter((tab) => tab.link === nextTabLink);
      AlertManager.warning(getMessage(
        messageCodes.CONDOR_TAB_DISABLED,
        { disabledTab: tabName[0].name, enableTab: tabs[0].name },
      ));

      dispatch(push(`${ CONDOR_DRS_ROADSHOW_URL }/${ roadshowId }/${ tabs[0].link }`));
      return;
    }

    dispatch(setBreadcrumb({
      name: dealSettingsData.name,
      url: `${ CONDOR_DRS_ROADSHOW_URL }/${ roadshowId }/${ nextTabLink }`,
    }));
  };
}
