import { getRoadshowSettingsById } from '@/dealroadshow/application/actions/data/actions';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getAuditTrailByFilter } from '@/dealroadshow/application/actions/auditTrail/collection/actions';
import AuditTrailRepository from '@/dealroadshow/infrastructure/repository/AuditTrailRepository';

/**
 * @param {String} roadshowId
 * @param {Boolean} isAdmin
 */
export function initAuditTrail(roadshowId, isAdmin) {
  return async (dispatch) => {
    try {
      dispatch(getRoadshowSettingsById(roadshowId));
      dispatch(getAuditTrailByFilter(roadshowId, isAdmin));
      return undefined;
    } catch (errorResponse) {
      AlertManager.error(getErrorMessage(errorResponse));
      return undefined;
    }
  };
}

/**
 * @param {String} id
 * @param {String} accessType
 * @param {String} roadshowId
 * @param {Boolean} isAdmin
 */

export function handleChangeAccessType(id, accessType, roadshowId, isAdmin) {
  return async (dispatch, getState) => {
    try {
      await getState().container.get(AuditTrailRepository).setAccessType(id, accessType);
      dispatch(getAuditTrailByFilter(roadshowId, isAdmin));
      return undefined;
    } catch (errorResponse) {
      AlertManager.error(getErrorMessage(errorResponse));
      return undefined;
    }
  };
}
