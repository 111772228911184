import { useState, useCallback } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import SectorRepository from '@/finsight/infrastructure/repository/SectorRepository';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import type { ISector } from '@/dealroadshow/domain/vo/sector/Sector';

export const useSectorsList = (initial: ISector[]) => {
  const [collection, setCollection] = useState<ISector[]>(initial || []);
  const [isFetching, setIsFetching] = useState(false);
  const { container } = useDIContext();

  const getSectorsList = useCallback(async () => {
    setIsFetching(true);
    try {
      const sectorRepository = container.get(SectorRepository);
      const sectors = await sectorRepository.getList();
      setCollection(sectors);
    } catch (error) {
        AlertManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  }, [container]);

  return {
    isFetching,
    collection,
    getSectorsList,
  };
};
