import cn from 'classnames';
import {
  StatusCellCallback,
  LogoCellCallback,
  DeleteAtCellCallback,
  ActionButtonsCellCallback,
} from './cellCallbacks';
import decodeSymbols from '@/Framework/dataHelpers/string/decodeSymbols';
import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';
import { dataTableStyles } from '@dealroadshow/uikit/core/components/Table/DataTable';
import styles from './styles.scss';

export const columns = [
  {
    name: 'name',
    title: 'Roadshow Name',
    className: cn(alignStyles.alignLeft, styles.nameCell),
    cellCallback: ({ row }) => decodeSymbols(row.name),
  },
  {
    name: 'status',
    width: '7%',
    title: 'Status',
    className: alignStyles.alignCenter,
    cellCallback: StatusCellCallback,
  },
  {
    name: 'logoUrl',
    width: '13%',
    title: 'Logo',
    cellCallback: LogoCellCallback,
  },
  {
    name: 'text',
    width: 277,
    title: 'Display Text',
  },
  {
    name: 'deletedAt',
    width: '15%',
    title: 'Deleted',
    cellCallback: DeleteAtCellCallback,
  },
  {
    name: 'action',
    width: 72,
    title: 'Actions',
    className: alignStyles.alignCenter,
    cellCallback: ActionButtonsCellCallback,
  },
  {
    name: 'dragAndDrop',
    className: dataTableStyles.draggableCol,
    isDraggable: true,
    title: '',
  },
];
