import { v4 as uuid } from 'uuid';
import { change } from 'redux-form';
import ErrorCodeHelper from '@finsight/error-codes';
import { format } from 'date-fns';
import isEmptyString from '@/Framework/dataHelpers/string/isEmptyString';
import { getErrorMessage, getMessage } from '@/Framework/Message/Mapper/getMessage';
import RoadshowRepository from '@/dealroadshow/infrastructure/repository/RoadshowRepository';
import { checkTabState } from '../../actions';
import {
  filterDealSettingsFormData,
  filterRoadshowMappingFormData,
  addUuidToLink,
} from './DealSettingsForm/helpers/filterDealSettingsFromData';
import Upload from '@/Framework/UI/Organisms/Upload';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import * as actionTypes from './actionTypes';
import { MONTH_DAY_YEAR_SLASH_FORMAT } from '@/Framework/DateTime/dateFormats';
import { messageCodes } from '@/Framework/Message/messages';
import * as dealSettingsFormConstants from './constants';
import EntryCodeType from '@/dealroadshow/domain/EntryCodeType';
import { defaultColorSettings } from '@/dealroadshow/ui/components/Viewer/config';
import createAction from '@/Framework/State/Redux/createAction';
import getDateFromUnixTime from '@/Framework/DateTime/getDateFromUnixTime';

const { initUploadCustomViewerDocuments } = Upload.actions;

/**
 * @param {Object} payload
 * @return {Object}
 */
function requestDealSettingsSuccess(payload) {
  return {
    type: actionTypes.REQUEST_DEAL_SETTINGS_SUCCESS,
    payload,
  };
}

function requestEvercallDealSettingsSuccess(payload) {
  return {
    type: actionTypes.REQUEST_EVERCALL_DEAL_SETTINGS_SUCCESS,
    payload,
  };
}

/**
 * @param {String} error
 * @return {Object}
 */
function requestDealSettingsError(error) {
  return {
    type: actionTypes.REQUEST_DEAL_SETTINGS_ERROR,
    error,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function requestRoadshowMappingSuccess(payload) {
  return {
    type: actionTypes.REQUEST_ROADSHOW_MAPPING_SUCCESS,
    payload,
  };
}

/**
 * @param {String} error
 * @return {Object}
 */
function requestRoadshowMappingError(error) {
  return {
    type: actionTypes.REQUEST_ROADSHOW_MAPPING_ERROR,
    error,
  };
}

export function getAdminEvercallSetting() {
  return async (dispatch, getState) => {
    const roadshowRepository = getState().container.get(RoadshowRepository);

    try {
      let response = await roadshowRepository.getEvercallTemplateSetting();
      dispatch(uploadClear());
      dispatch(requestEvercallDealSettingsSuccess(response));
      AlertManager.success(getMessage(messageCodes.CONDOR_APPLY_BRANDING));
    } catch (err) {
      dispatch(requestDealSettingsError(err));
    }
  };
}

/**
 * @param {String} roadshowId
 */
export function getDealSettings(roadshowId) {
  return async (dispatch, getState) => {
    const roadshowRepository = getState().container.get(RoadshowRepository);
    dispatch({ type: actionTypes.REQUEST_DEAL_SETTINGS });

    try {
      let response = await roadshowRepository.getAdminDealSettings(roadshowId);
      let viewerCustomLinks;
      if (response.viewerCustomLinks.length) {
        viewerCustomLinks = addUuidToLink(response.viewerCustomLinks);
      } else {
        viewerCustomLinks = [{ uuid: uuid() }];
      }
      let viewerCustomFiles = [];
      if (response.viewerCustomFiles && response.viewerCustomFiles.length) {
        viewerCustomFiles = response.viewerCustomFiles.map((file) => ({
          uuid: file.id,
          name: file.name,
          customDisclaimer: !isEmptyString(file.disclaimer) ? file.customDisclaimer : false,
          disclaimer: file.disclaimer,
          viewOnly: file.viewOnly,
        }));
        await dispatch(await initUploadCustomViewerDocuments(response.viewerCustomFiles));
      }
      const viewerSettings = {
        ...defaultColorSettings,
        topButtonType: dealSettingsFormConstants.DEAL_FILES,
        viewerDisclaimerCustomizationEnabled: false,
        createTopButton: false,
        ...response.viewerCustomSettings,
        ...response.viewerDisclaimerSettings,
        ...response.createTopButtonSettings,
      };
      const canBeChangedVideoType = await roadshowRepository.canBeChangedVideoType(roadshowId);
      dispatch(requestDealSettingsSuccess({
        initialValues: {
          ...response,
          ...viewerSettings,
          canBeChangedVideoType,
          dealSizeMillions: response.dealSizeMillions?.value,
          viewerCustomLinks,
          viewerCustomFiles,
          startedAt: format(
            getDateFromUnixTime(response.startedAt),
            MONTH_DAY_YEAR_SLASH_FORMAT,
          ).toString(),
          finishedAt: getDateFromUnixTime(response.finishedAt),
          expectedPricingDate: response.expectedPricingDate ? getDateFromUnixTime(response.expectedPricingDate) : null,
        },
        data: response,
      }));
    } catch (err) {
      dispatch(requestDealSettingsError(err));
    }
  };
}

/**
 * @param {String} roadshowId
 */
export function getRoadshowMapping(roadshowId) {
  return async (dispatch, getState) => {
    const roadshowRepository = getState().container.get(RoadshowRepository);
    dispatch({ type: actionTypes.REQUEST_ROADSHOW_MAPPING });
    try {
      let response = await roadshowRepository.getAdminRoadshowMapping(roadshowId);
      dispatch(requestRoadshowMappingSuccess({
        initialValues: {
          ...response,
        },
      }));
    } catch (err) {
      dispatch(requestRoadshowMappingError(err));
    }
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function requestDealSettingsUpdateSuccess(payload) {
  return {
    type: actionTypes.REQUEST_DEAL_SETTINGS_UPDATE_SUCCESS,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function requestRoadshowMappingUpdateSuccess(payload) {
  return {
    type: actionTypes.REQUEST_ROADSHOW_MAPPING_UPDATE_SUCCESS,
    payload,
  };
}

/**
 * @param {String} error
 * @return {Object}
 */
function requestDealSettingsUpdateError(error) {
  return {
    type: actionTypes.REQUEST_DEAL_SETTINGS_UPDATE_ERROR,
    error,
  };
}

/**
 * @param {Object} payload
 * @returns {Function}
 */
export function changeEntryCodeLoginLimit(payload) {
  return (dispatch, getState) => {
    let entryCodeRotatingMap = getState().form[dealSettingsFormConstants.DEAL_SETTINGS_FORM_NAME].values.entryCodes;
    let entryCodeToChangeLoginLimit = entryCodeRotatingMap.find((code) => code.id === payload.id);
    dispatch(
      change(
        dealSettingsFormConstants.DEAL_SETTINGS_FORM_NAME,
        'entryCodes',
        [
          ...entryCodeRotatingMap.filter((code) => code !== entryCodeToChangeLoginLimit),
          {
            ...entryCodeToChangeLoginLimit,
            loginsMaxNumber: payload.loginsMaxNumber,
            ...(payload.loginsMaxNumber === null && { loginsTimeLimit: null }),
          },
        ],
      ),
    );
  };
}

export function sortEntryCodes(payload) {
  return (dispatch) => {
    dispatch(
      change(dealSettingsFormConstants.DEAL_SETTINGS_FORM_NAME, 'entryCodes', payload),
    );
  };
}

export function onSubmit(formData) {
  return async (dispatch) => {
    try {
      const updatedDialSettings = await dispatch(updateDealSettings(formData));
      await dispatch(updateRoadshowMapping({
        ...formData,
        ...(updatedDialSettings && {
          variation: updatedDialSettings.variation,
        }),
      }));

      if (updatedDialSettings) {
        dispatch(requestDealSettingsUpdateSuccess(updatedDialSettings));
        dispatch(uploadClear());
        dispatch(getDealSettings(formData.id));
        dispatch(checkTabState(formData.id, 'deal-settings', 'deal-settings'));
      }
    } catch (errorResponse) {
      if (errorResponse.error?.code === ErrorCodeHelper.getCodeByName('EVERCALL_OACC_CONFERENCE_NOT_FOUND')) {
        dispatch(setEvercallError(true));
      } else if (errorResponse.error?.code === ErrorCodeHelper.getCodeByName('ROADSHOW_IS_ALREADY_UPDATED')) {
        dispatch(setSettingsNeedToRefreshModalVisible(true));
      } else {
        dispatch(requestDealSettingsUpdateError(errorResponse));
        AlertManager.error(getErrorMessage(errorResponse));
      }
    }
  };
}

export const setSettingsNeedToRefreshModalVisible = (isVisible) => (
  createAction(actionTypes.SET_DEAL_SETTINGS_NEED_TO_REFRESH_MODAL_VISIBLE, isVisible));

export const setMappingNeedToRefreshModalVisible = (isVisible) => (
  createAction(actionTypes.SET_DEAL_MAPPING_NEED_TO_REFRESH_MODAL_VISIBLE, isVisible));

export const setEvercallError = (hasError) => (
  createAction(actionTypes.SET_EVERCALL_CONFERENCE_ERROR, hasError));

/**
 * @param {Object} formData
 */
export function updateDealSettings(formData) {
  return async (dispatch, getState) => {
    const roadshowRepository = getState().container.get(RoadshowRepository);
    dispatch({ type: actionTypes.REQUEST_DEAL_SETTINGS_UPDATE });

    // eslint-disable-next-line no-useless-catch
    try {
      formData.upload = getState().upload;
      if (formData.inRetailTable && !(formData.upload.logo.uploaded && formData.upload.logo.canSave)) {
        dispatch(requestDealSettingsUpdateError(messageCodes.CONDOR_RETAIL_LOGO_REQUIRED));
        AlertManager.warning(getMessage(messageCodes.CONDOR_RETAIL_LOGO_REQUIRED));
      } else if (EntryCodeType.isSingle(formData.appliedEntryCodeType) && !formData.singleEntryCode) {
        dispatch(requestDealSettingsUpdateError(messageCodes.SINGLE_ENTRY_CODE_IS_REQUIRED));
        AlertManager.error(getMessage(messageCodes.SINGLE_ENTRY_CODE_IS_REQUIRED));
      } else if (
        formData.viewerCustomizationEnabled &&
        formData.createTopButton &&
        formData.topButtonType === dealSettingsFormConstants.DEAL_FILES &&
        !formData.viewerCustomFiles.length
      ) {
        dispatch(requestDealSettingsUpdateError(messageCodes.FILE_IS_REQUIRED));
        AlertManager.error(getMessage(messageCodes.FILE_IS_REQUIRED));
      } else if (
        formData.viewerCustomizationEnabled &&
        formData.createTopButton &&
        formData.topButtonType === dealSettingsFormConstants.EXTERNAL_LINKS &&
        !formData.viewerCustomLinks[0].url
      ) {
        dispatch(requestDealSettingsUpdateError(messageCodes.LINK_IS_REQUIRED));
        AlertManager.error(getMessage(messageCodes.LINK_IS_REQUIRED));
      } else {
        formData.startedAt = getState().dealRoadshows.roadshow.dealSettings.data.startedAt;
        const saveData = filterDealSettingsFormData(formData);
        return roadshowRepository.updateAdminDealSettings(saveData);
      }
      return null;
    } catch (errorResponse) {
      throw errorResponse;
    }
  };
}

/**
 * @param {Object} formData
 */
export function updateRoadshowMapping(formData) {
  return async (dispatch, getState) => {
    const roadshowRepository = getState().container.get(RoadshowRepository);
    dispatch({ type: actionTypes.REQUEST_ROADSHOW_MAPPING_UPDATE });

    // eslint-disable-next-line no-useless-catch
    try {
      let saveMapping = filterRoadshowMappingFormData(formData);
      let response = await roadshowRepository.updateRoadshowMapping(saveMapping);
      dispatch(requestRoadshowMappingUpdateSuccess(response));
      dispatch(getRoadshowMapping(formData.id));
      AlertManager.success(getMessage(messageCodes.CONDOR_SAVED));
    } catch (errorResponse) {
      throw errorResponse;
    }
  };
}

export function resetDealSettings() {
  return async (dispatch) => {
    dispatch(uploadClear());
    dispatch({ type: actionTypes.DEAL_SETTINGS_RESET });
    dispatch({ type: actionTypes.ROADSHOW_MAPPING_RESET });
  };
}

/**
 * @return {Object}
 */
function uploadClear() {
  return {
    type: Upload.actionTypes.UPLOAD_CLEAR,
  };
}
