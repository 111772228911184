import cn from 'classnames';
import { BodyCellPure } from '@dealroadshow/uikit/core/components/Table/DataTable/Body/BodyCell';
import PermissionCheckBox from './PermissionCheckBox';
import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';
import permissionStyles from './userPermissions.scss';

interface IPermissionRowProps {
  id: string,
  functionality: string,
  description: string,
  name: string,
  title: string,
  width: number,
  className: string,
  rowIndex: number,
}

const PermissionRow = (props: IPermissionRowProps) => {
  const {
    functionality,
    description,
    id,
    rowIndex,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    name,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    title,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    width,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    className,
  } = props;

  return (
    <tr>
      <BodyCellPure
        rowIndex={ rowIndex }
        rowData={ props }
        name="functionality"
        className={ alignStyles.alignLeft }
        cellCallback={ () => <>{ functionality }</> }
      />
      <BodyCellPure
        rowIndex={ rowIndex }
        rowData={ props }
        name="description"
        className={ cn(alignStyles.alignLeft, permissionStyles.descriptionRow) }
        cellCallback={ () => <>{ description }</> }
      />
      <BodyCellPure
        rowIndex={ rowIndex }
        rowData={ props }
        name="tree"
        className={ cn(alignStyles.alignRight, permissionStyles.checkBoxRow) }
        cellCallback={ () => (
          <PermissionCheckBox moduleKey={ id } />
        ) }
      />
    </tr>
  );
};

export default PermissionRow;
