import { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useContactProfileContext } from '../../ContactProfileContext';
import { useTwoFactor } from '@/users/application/useTwoFactor';
import { useAccount } from './useAccount';
import { IUserUpdatePayload, useUser } from '@/users/application/useUser';
import PhoneNumber from '@/users/domain/PhoneNumber';
import { IInitialValues, IProfileForm } from '../interfaces';
import { CONDOR_CONTACTS_LIST_BASE_URL } from '@/condor/ui/common/constants';

export const useProfileForm = () => {
  const { push } = useHistory();
  const { user } = useContactProfileContext();
  const { updateUser, isFetching } = useUser();
  const { twoFactorCode, generateTwoFactorCode } = useTwoFactor();
  const { companies, getCompanyByQuery } = useAccount();

  const initialValues = useMemo<IInitialValues>(() => ({
    id: user.id,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    title: user.titleId,
    officePhone: {
      value: PhoneNumber.fixUsPhoneNumber(user.workPhone),
      code: PhoneNumber.getPhoneCode(user.workPhone),
    },
    personalPhone: {
      value: PhoneNumber.fixUsPhoneNumber(user.personalPhone),
      code: PhoneNumber.getPhoneCode(user.personalPhone),
    },
    jurisdiction: user.jurisdictionCountryId,
    bloombergEmail: user.bloombergEmail,
    linkedinProfile: user.linkedinProfile,
    activated: user.activated,
    emailVerified: user.emailVerified,
    banned: user.banned,
    company: user.company && {
      value: user?.company?.id,
      label: user?.company?.name,
    },
    twoFactorPhone: {
      value: PhoneNumber.fixUsPhoneNumber(user.mobilePhone),
      code: PhoneNumber.getPhoneCode(user.mobilePhone),
    },
  }), [user]);

  const handleSubmit = useCallback(async (formData: IProfileForm) => {
    const payload: IUserUpdatePayload = {
      activated: formData.activated,
      banned: formData.banned,
      companyId: formData.company?.value,
      emailVerified: formData.emailVerified,
      firstName: formData.firstName,
      id: formData.id,
      lastName: formData.lastName,
      titleId: formData.title?.value,
      workPhone: formData.officePhone?.value || null,
      personalPhone: formData.personalPhone?.value || null,
      jurisdictionCountryId: formData.jurisdiction?.value || null,
      bloombergEmail: formData.bloombergEmail,
      linkedinProfile: formData.linkedinProfile,
    };

    try {
      await updateUser(payload);
      push(CONDOR_CONTACTS_LIST_BASE_URL);
    } catch (e) {
      // do nothing
    }
  }, []);

  return {
    generateTwoFactorCode: () => generateTwoFactorCode(user.id),
    handleSubmit,
    twoFactorCode,
    initialValues,
    isFetching,
    companies,
    getCompanyByQuery,
  };
};
