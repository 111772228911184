import { useCallback, useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import Dispatcher, { RpcError } from '@dealroadshow/json-rpc-dispatcher';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { SortOrder } from '@dealroadshow/uikit/core/components/Table/DataTable/interfaces/SortOrder';

interface IFetchCollectionInitialValues<T> {
  isInitialized?: boolean,
  isFetching?: boolean,
  isZeroCase?: boolean,
  collection?: T[],
  sortBy?: string,
  sortOrder?: SortOrder,
  page?: number,
  perPage?: number,
  debouncedSearchString?: string,
  totalCount?: number,
}

export interface IFetchCollectionResponse<T> {
  collection: T[],
  totalCount: number,
}

export interface IFetchCollectionPayload {
  [key: string]: {} | string | number | boolean | IFetchCollectionPayload | string[] | number[],
}

export interface IFetchCallback<T, K = IFetchCollectionPayload> {
  (payload: K): Promise<IFetchCollectionResponse<T>>,
}

export type CallbackCollectionType<C extends IFetchCallback<any>> = C extends IFetchCallback<infer T> ? T : unknown;

const useFetchCollection = <T, K = IFetchCollectionPayload>(
  fetch?: IFetchCallback<T, K>,
  payload: K = null,
  initialValues: IFetchCollectionInitialValues<T> = {},
  errorHandler: (error: RpcError) => void = (error) => {
    AlertManager.error(getErrorMessage(error));
  },
) => {
  const defaultInitialValues: IFetchCollectionInitialValues<T> = {
    isInitialized: false,
    isFetching: false,
    isZeroCase: false,
    collection: [],
    sortBy: 'name',
    sortOrder: 'asc',
    page: 1,
    perPage: 25,
    debouncedSearchString: '',
    totalCount: 0,
  };

  const initial: IFetchCollectionInitialValues<T> = {
    ...defaultInitialValues,
    ...initialValues,
  };
  const [isPristine, setIsPristine] = useState<boolean>(true);
  const [isInitialized, setIsInitialized] = useState<boolean>(initial.isInitialized);
  const [isFetching, setIsFetching] = useState<boolean>(initial.isFetching);
  const [isZeroCase, setIsZeroCase] = useState<boolean>(initial.isZeroCase);
  const [collection, setCollection] = useState<T[]>(initial.collection);
  const [totalCount, setTotalCount] = useState<number>(initial.totalCount);

  // This is made due to issue with batching update in react-hooks.
  // Keep different states leads to issue with multiple requests.
  // https://github.com/facebook/react/issues/14259

  const [params, setParams] = useState({
    sortBy: initial.sortBy,
    sortOrder: initial.sortOrder,
    page: initial.page,
    perPage: initial.perPage,
    debouncedSearchString: initial.debouncedSearchString,
  });

  const {
    sortBy,
    sortOrder,
    page,
    perPage,
    debouncedSearchString,
  } = params;

  const getCollection = async (): Promise<void> => {
    setIsFetching(true);

    try {
      const response = await fetch({
        sortBy,
        sortOrder,
        page,
        perPage,
        search: debouncedSearchString,
        ...payload,
      });

      if (!response.collection.length && page > 1) {
        paginate(initial.page);
      } else {
        setCollection(response.collection);
        setTotalCount(response.totalCount);
        setIsFetching(false);
        setIsInitialized(true);
        setIsZeroCase(isEmpty(response.collection) && !debouncedSearchString);
      }
    } catch (error) {
      !Dispatcher.isAbortError(error) && errorHandler(error);
    }
  };

  const reset = useCallback((): void => {
    setIsInitialized(initial.isInitialized);
    setIsFetching(initial.isFetching);
    setCollection(initial.collection);
    setIsZeroCase(initial.isZeroCase);
    softReset();
  }, [initial]);

  const softReset = useCallback((): void => {
    setParams({
      sortBy: initial.sortBy,
      sortOrder: initial.sortOrder,
      page: initial.page,
      perPage: initial.perPage,
      debouncedSearchString: initial.debouncedSearchString,
    });
    setTotalCount(initial.totalCount);
  }, [initial]);

  const sort = (sortBy: string, sortOrder: SortOrder): void => {
    setParams({
      ...params,
      page: 1,
      sortBy,
      sortOrder,
    });
  };

  const setItemsPerPage = (perPage: number): void => {
    setParams({
      ...params,
      page: 1,
      perPage,
    });
  };

  const paginate = (page: number): void => {
    setParams({
      ...params,
      page,
    });
  };

  const search = (debouncedSearchString: string): void => {
    setParams({
      ...params,
      debouncedSearchString,
    });
  };

  const debouncedSearch = useCallback(debounce((debouncedSearchString: string): void => {
    setParams({
      ...params,
      page: 1,
      debouncedSearchString,
    });
  }, 500), [setParams, params]);

  useEffect(() => {
    fetch && getCollection();
  }, [
    JSON.stringify(payload), // TODO replace JSON.stringify
    sortBy,
    sortOrder,
    page,
    perPage,
    debouncedSearchString,
    fetch,
  ]);

  useEffect(() => {
    setIsPristine(isEqual(params, {
      sortBy: initial.sortBy,
      sortOrder: initial.sortOrder,
      page: initial.page,
      perPage: initial.perPage,
      debouncedSearchString: initial.debouncedSearchString,
    }));
  }, [params]);

  return {
    isPristine,
    isInitialized,
    isFetching,
    isZeroCase,
    collection,
    sortBy,
    sortOrder,
    page,
    perPage,
    totalCount,
    debouncedSearchString,
    sort,
    setItemsPerPage,
    paginate,
    search,
    debouncedSearch,
    reset,
    softReset,
    getCollection,
    setIsFetching,
    modifyCollection: setCollection,
  };
};

export default useFetchCollection;
