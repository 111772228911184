import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as processingTypes from '@/Framework/UI/Organisms/Upload/processingTypes';
import PresentationMediaRepository from '@/dealroadshow/infrastructure/repository/PresentationMediaRepository';
import RoadshowRepository from '@/dealroadshow/infrastructure/repository/RoadshowRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { messageCodes } from '@/Framework/Message/messages';
import { getErrorMessage, getMessage } from '@/Framework/Message/Mapper/getMessage';
import * as uploadActionTypes from '@/Framework/UI/Organisms/Upload/actionTypes';
import Upload from '@/Framework/UI/Organisms/Upload';
import { RoadshowTypes } from '@/dealroadshow/domain/RoadshowType';
import { ICuePoint } from '@/dealroadshow/domain/vo/roadshow/ICuePoint';
import {
  IAudioVideoFormData,
  IAudioVideoFormDataExt,
  IAudioVideoFormDataPayload,
  IInitialFormValues,
  IStateUpload,
} from '../interfaces';
import {
  isCondorFilesUploading,
  needsAudioForSlidesAndAudio,
  needsVideoForSlidesAndVideo,
} from '../helpers/updateAudioVideo';
import filterAudioVideoFormData from '../helpers/filterAudioVideoFormData';
import reindexCuePoints from '../helpers/reindexCuePoints';

const defaultInitialValues: IInitialFormValues = {
  cuePoints: [],
  type: null,
  videoOnlyCuePoints: [],
  presentationMedia: null,
  audioConferenceCode: '',
};

export default function useAudioVideoForm() {
  const [initialValues, setInitialValues] = useState<IInitialFormValues>({ ...defaultInitialValues });
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [canBeChangedVideoType, setCanBeChangedVideoType] = useState<boolean>();
  const [isModerated, setIsModerated] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { roadshowId }: { roadshowId: string } = useParams();

  const { presentationMediaAudio, presentationMediaVideo, presentationCuePoints } = useSelector(
    ({ upload }: { upload: IStateUpload }) => upload,
  );

  const uploadedMedia = { presentationMediaAudio, presentationMediaVideo };
  const uploadedCuePoints: ICuePoint[] = presentationCuePoints.cuePointsList;
  const uploadedCuePointsFileName: string = presentationCuePoints.name;

  const presentationMediaRepository =
    useDIContext().container.get<PresentationMediaRepository>(PresentationMediaRepository);
  const roadshowRepository = useDIContext().container.get<RoadshowRepository>(RoadshowRepository);

  const resetAudioVideo = () => {
    uploadClearForForm();
    setInitialValues(defaultInitialValues);
  };

  const getAudioVideo = async () => {
    setInitialValues(defaultInitialValues);
    setIsFetching(true);
    try {
      const [response, canBeChangedVideoType] = await Promise.all([
        roadshowRepository.getAdminAudioVideo(roadshowId),
        roadshowRepository.canBeChangedVideoType(roadshowId),
      ]);
      const { mediaVideo } = response.presentationMedia;

      if (
        mediaVideo &&
        (mediaVideo.processingStatus === processingTypes.COMPLETED ||
          mediaVideo.processingStatus === processingTypes.ERROR)
      ) {
        dispatch({
          type: uploadActionTypes.UPLOAD_PRESENTATION_MEDIA_VIDEO_PROCESSING_STATUS_UPDATE,
          payload: mediaVideo.processingStatus,
        });
      } else if (mediaVideo && mediaVideo.processingStatus === processingTypes.PROCESSING) {
        presentationMediaRepository.subscribeOnProcessingStatusChange(mediaVideo.id, (processingState) => dispatch({
            type: uploadActionTypes.UPLOAD_PRESENTATION_MEDIA_VIDEO_PROCESSING_STATUS_UPDATE,
            payload: processingState.status,
          }),
        );
      }

      setInitialValues({
        cuePoints: response.cuePoints,
        type: response.type,
        videoOnlyCuePoints: response.videoOnlyCuePoints,
        presentationMedia: response.presentationMedia,
        audioConferenceCode: response.audioConferenceCode,
      });

      setCanBeChangedVideoType(canBeChangedVideoType);
      setIsModerated(response.moderated);
    } catch (errorResponse: unknown) {
      AlertManager.error(getErrorMessage(errorResponse));
    } finally {
      setIsFetching(false);
    }
  };

  const updateAudioVideo = async (formData: IAudioVideoFormDataExt) => {
    setIsFetching(true);
    try {
      const formDataTypeString: RoadshowTypes = typeof formData.type === 'object' ? formData.type.value : formData.type;
      const formDataPatched: IAudioVideoFormData = { ...formData, type: formDataTypeString };

      if (isCondorFilesUploading(presentationMediaVideo, presentationMediaAudio)) {
        AlertManager.warning(getMessage(messageCodes.CONDOR_FILES_UPLOADING));
      } else if (needsAudioForSlidesAndAudio(formDataPatched, presentationMediaAudio)) {
        AlertManager.warning(getMessage(messageCodes.CONDOR_NEED_ADD_AUDIO_FOR_SLIDES_AUDIO));
      } else if (needsVideoForSlidesAndVideo(formDataPatched, presentationMediaVideo)) {
        AlertManager.warning(getMessage(messageCodes.CONDOR_NEED_ADD_VIDEO_FOR_SLIDES_VIDEO));
      } else {
        const filteredData = filterAudioVideoFormData(
          formDataPatched,
          roadshowId,
          uploadedMedia,
        );
        const { videoOnlyCuePoints, cuePoints } = reindexCuePoints(filteredData);
        const dataToSave: IAudioVideoFormDataPayload = {
          ...filteredData,
          videoOnlyCuePoints,
          cuePoints,
        };
        await roadshowRepository.updateAdminAudioVideo(dataToSave);

        uploadClearForForm();
        AlertManager.success(getMessage(messageCodes.CONDOR_SAVED));

        await getAudioVideo();
      }
    } catch (errorResponse) {
      AlertManager.error(getErrorMessage(errorResponse));
    } finally {
      setIsFetching(false);
    }
  };

  const uploadClearForForm = () => {
    dispatch({
      type: Upload.actionTypes.UPLOAD_CLEAR,
    });
  };

  return {
    canBeChangedVideoType,
    getAudioVideo,
    initialValues,
    isFetching,
    isModerated,
    resetAudioVideo,
    uploadClearForForm,
    uploadedCuePoints,
    uploadedCuePointsFileName,
    uploadedMedia,
    onSubmit: updateAudioVideo,
  };
}
