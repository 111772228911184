import React, { createContext, useContext, useState, useCallback } from 'react';

import { useDIContext } from '@/Framework/DI/DIContext';
import { IArticleToDisplay } from '@/condor/domain/vo/Finsight/CreditFlowResearch/IArticle';
import { useArticlesListContext } from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticlesList';
import CreditFlowResearchRepository
  from '@/condor/infrastructure/repository/creditFlowResearch/CreditFlowResearchRepository';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { ActionModalType } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ActionModalType';

interface IModalsState {
  isModalOpen: boolean,
  article: IArticleToDisplay | null,
  modalType: ActionModalType | '',
}

const initialModalsState: IModalsState = {
  isModalOpen: false,
  article: null,
  modalType: '',
};

const useArticlesModals = () => {
  const { getCollection } = useArticlesListContext();
  const [modalData, setModalData] = useState<IModalsState>(initialModalsState);
  const [isRequestPerforming, setIsRequestPerforming] = useState(false);

  const { container } = useDIContext();
  const creditFlowResearchRepository = container.get<CreditFlowResearchRepository>(CreditFlowResearchRepository);

  const openModal = useCallback((article: IArticleToDisplay, modalType: ActionModalType) => {
    setModalData({
      isModalOpen: true,
      article,
      modalType,
    });
  }, []);

  const handleAction = async (action: () => Promise<void>, successMessage: string) => {
    setIsRequestPerforming(true);
    try {
      await action();
      AlertManager.success(successMessage);
      closeModal();
      getCollection();
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
    } finally {
      setIsRequestPerforming(false);
    }
  };

  const handleDeleteArticle = useCallback(() => {
    handleAction(
      () => creditFlowResearchRepository.deleteArticleById(modalData.article.id),
      'Post has been successfully deleted',
    );
  }, [modalData.article]);

  const handleSendEmail = useCallback(() => {
    handleAction(
      () => creditFlowResearchRepository.sendEmailNotification(modalData.article.id),
      'Email has been successfully sent',
    );
  }, [modalData.article]);

  const closeModal = useCallback(() => {
    setIsRequestPerforming(false);
    setModalData(initialModalsState);
  }, []);

  return {
    ...modalData,
    openModal,
    closeModal,
    handleDeleteArticle,
    handleSendEmail,
    isRequestPerforming,
  };
};

const ArticlesActionsContext = createContext<ReturnType<typeof useArticlesModals>>(null);

export const useArticlesActionsContext = () => {
  const context = useContext(ArticlesActionsContext);
  if (!context) {
    throw new Error('useArticlesActionsContext must be used within a ArticlesActionsContext');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const ArticlesActionsContextProvider = ({ children }: IProps) => (
  <ArticlesActionsContext.Provider value={ useArticlesModals() }>
    { children }
  </ArticlesActionsContext.Provider>
);

export default ArticlesActionsContextProvider;
