import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import {
  initUploadPresentationMediaAudio,
  initUploadPresentationMediaVideo,
} from '@/Framework/UI/Organisms/Upload/actions';
import { FieldArray, Field, useForm, useField } from '@/Framework/UI/Organisms/FinalForm';

import UploadPresentationMediaAudioContainer from '../UploadPresentationMediaAudioContainer';
import UploadPresentationMediaVideoContainer from '../UploadPresentationMediaVideoContainer';
import { Select } from '@/Framework/UI/Organisms/FinalForm/Fields/Select';
import Button, { buttonStyles, ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import Input from '@dealroadshow/uikit/core/components/Input';
import FormField from '@dealroadshow/uikit/core/components/Form/FormField';
import IconShare from '@dealroadshow/uikit/core/components/Icon/IconShare';
import CondorDropzoneInternalField from '../CondorDropzoneInternalField/CondorDropzoneInternalField';
import { useRoadshowVideoGeneration } from '../useRoadshowVideoGeneration';
import { useAudioVideoFormContext } from '../AudioVideoFormContext';
import CuePoints from '../CuePoints';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import AudioVideoFormSubmit from './AudioVideoFormSubmit';

import { roadshowTypesOptions } from '@/condor/ui/components/DealRoadshows/Roadshow/roadshowTypesOptions';
import RoadshowType from '@/dealroadshow/domain/RoadshowType';
import { IInitialFormValues } from '../interfaces';

import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';
import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';
import styles from '../audioVideoForm.scss';

const AudioVideoFormFields = () => {
  const {
    canBeChangedVideoType,
    getAudioVideo,
    initialValues,
    isFetching,
    isModerated,
    resetAudioVideo,
    uploadedCuePoints,
    uploadedCuePointsFileName,
  } = useAudioVideoFormContext();

  const {
    change,
    reset,
    mutators: { push, remove },
  } = useForm();

  const {
    input: { value: cuePoints },
  } = useField('cuePoints', {
    subscription: { value: true },
  });

  const {
    input: { value: videoOnlyCuePoints },
  } = useField('videoOnlyCuePoints', {
    subscription: { value: true },
  });

  const {
    input: { value: type },
  } = useField('type', {
    subscription: { value: true },
  });

  const [audioConferenceCode, setAudioConferenceCode] = useState<string>('');
  const { generateVideoReplay } = useRoadshowVideoGeneration();
  const dispatch = useDispatch();
  const { roadshowId }: { roadshowId: string } = useParams();

  const isVideoOnly = RoadshowType.isVideoOnly(type);
  const isVideo = RoadshowType.isSlidesAndVideo(type) || isVideoOnly;
  const isAudio = RoadshowType.isSlidesAndAudio(type);
  const isSlides = RoadshowType.isSlides(type);
  const isLiveVideo = RoadshowType.isLiveVideo(type);

  const isCuePointsDisabled = (isVideo || isAudio) && isModerated;
  const exportButtonIsVisible = !isVideoOnly && !isSlides && !isLiveVideo;
  const canExport = !(initialValues?.presentationMedia?.mediaVideo || initialValues?.presentationMedia?.mediaAudio);

  useEffect(() => {
    getAudioVideo();

    return resetAudioVideo;
  }, []);

  useEffect(() => {
    initData(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (isVideoOnly) {
      const nextVideoOnlyCuePoints = uploadedCuePoints.map((cuePoint, index) => {
        if (
          index < videoOnlyCuePoints.length &&
          Object.prototype.hasOwnProperty.call(videoOnlyCuePoints[index], 'sectionName')
        ) {
          return {
            number: cuePoint.slideNumber,
            time: cuePoint.time,
            sectionName: videoOnlyCuePoints[index].sectionName,
          };
        }
        return {
          number: cuePoint.slideNumber,
          time: cuePoint.time,
          sectionName: '',
        };
      });
      change('videoOnlyCuePoints', nextVideoOnlyCuePoints);
    } else {
      change('cuePoints', uploadedCuePoints);
    }
  }, [uploadedCuePoints.length]);

  useEffect(() => {
    if (uploadedCuePointsFileName) {
      change('cuePoints', []);
    }
  }, [uploadedCuePointsFileName]);

  const getInitialAudioConferenceCode = (initialValues: IInitialFormValues) => {
    const { type, audioConferenceCode } = initialValues;

    if (RoadshowType.isSlidesAndVideo(type) || RoadshowType.isSlidesAndAudio(type) || RoadshowType.isVideoOnly(type)) {
      return audioConferenceCode;
    }

    return undefined;
  };

  const initData = (initialValues: IInitialFormValues) => {
    const audioConferenceCode = getInitialAudioConferenceCode(initialValues);
    if (audioConferenceCode) {
      setAudioConferenceCode(audioConferenceCode);
    }

    if (initialValues.presentationMedia?.mediaAudio) {
      dispatch(initUploadPresentationMediaAudio(initialValues.presentationMedia.mediaAudio));
    }
    if (initialValues.presentationMedia?.mediaVideo) {
      dispatch(initUploadPresentationMediaVideo(initialValues.presentationMedia.mediaVideo));
    }
  };

  const resetForm = () => {
    initData(initialValues);
    reset();
  };

  return (
    <>
      <div className={ cn(cardStyles.cardContainer, styles.formCard) }>
        <div className={ styles.formHeader }>
          <h3>Audio/Video</h3>
          { exportButtonIsVisible && (
            <Button
              variant={ ButtonVariantType.outline }
              dataTest="exportToMp4Button"
              className={ cn(buttonStyles.btnIcon, buttonStyles.btnSquare, spacesStyles.mrn, styles.exportBtn) }
              onClick={ () => generateVideoReplay(roadshowId) }
              disabled={ canExport }
            >
              <IconShare />
              Export to MP4
            </Button>
          ) }
        </div>

        <Field
          name="type"
          label="Roadshow Type:"
          component={ Select }
          options={ roadshowTypesOptions }
          isDisabled={ isVideoOnly && !canBeChangedVideoType }
          placeholder="Select"
          isSimpleValue
          dataTest="audioVideoSelect"
          className={ cn(styles.formField, styles.firstFormField) }
        />
        { (isAudio || isVideo) && (
          <>
            <FormField dataTest={ `condorRoadshow${ isVideo ? 'Video' : 'Audio' }UploadField` }>
              <div className={ formStyles.formLabel2 }>Synced { isVideo ? 'Video' : 'Audio' }</div>
              { isVideo ? (
                <UploadPresentationMediaVideoContainer
                  dropzoneInternalFieldComponent={ CondorDropzoneInternalField }
                  dropzoneHelperClassName={ styles.dropzone }
                />
              ) : (
                <UploadPresentationMediaAudioContainer
                  dropzoneInternalFieldComponent={ CondorDropzoneInternalField }
                  dropzoneHelperClassName={ styles.dropzone }
                />
              ) }
              <div className={ styles.maxSizeTip }>The max file size supported 2 gigabytes.</div>
            </FormField>

            <Field
              name="audioConferenceCode"
              label="Conference Code:"
              component={ Input }
              dataTest="audioVideoConferenceCodeInput"
              disabled
              input={ { value: audioConferenceCode } }
              formFieldClassName={ styles.formField }
            />

            <>
              <div className={ formStyles.formLabel }>Cues:</div>
              <FieldArray name={ isVideoOnly ? 'videoOnlyCuePoints' : 'cuePoints' }>
                { ({ fields }) => (
                  <CuePoints
                    fields={ fields }
                    type={ type }
                    disabled={ isCuePointsDisabled }
                    cuePoints={ isVideoOnly ? videoOnlyCuePoints : cuePoints }
                    addCuePoint={ push }
                    removeCuePoint={ remove }
                  />
                ) }
              </FieldArray>
            </>
          </>
        ) }
        <Spinner isVisible={ isFetching } overlay />
      </div>

      <AudioVideoFormSubmit type={ type } resetForm={ resetForm } />
    </>
  );
};

export default AudioVideoFormFields;
