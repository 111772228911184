import React, { ChangeEvent, useState } from 'react';
import cn from 'classnames';
import { format as formatDate } from 'date-fns';
import { Field } from '@/Framework/UI/Organisms/FinalForm';
import CopyToClipboard from '@/Framework/UI/Molecules/CopyToClipboard';
import DataTable, { dataTableStyles, ICellProps } from '@dealroadshow/uikit/core/components/Table/DataTable';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import Input from '@dealroadshow/uikit/core/components/Input';
import FormField from '@dealroadshow/uikit/core/components/Form/FormField';
import RichTextInput from '@/Framework/UI/Molecules/Form/RichTextInput';
import UploadPresentationCuePointsListContainer from './UploadPresentationCuePointsListContainer';
import RoadshowType, { RoadshowTypes } from '@/dealroadshow/domain/RoadshowType';
import { ICuePoint, IVideoOnlyCuePoint } from '@/dealroadshow/domain/vo/roadshow/ICuePoint';
import { IFinalFormFieldArrayInputComponentProps } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import { cellTimeCheck } from '@/condor/ui/components/DealRoadshows/Roadshow/modules/AudioVideo/AudioVideoForm/helpers/cellTimeCheck';

import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';
import styles from './cuePoints.scss';
import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';
import IconTrash from '@dealroadshow/uikit/core/components/Icon/IconTrash';
import IconPlus from '@dealroadshow/uikit/core/components/Icon/IconPlus';
import IconCopy from '@dealroadshow/uikit/core/components/Icon/IconCopy';

interface ICuePointsProps {
  fields: IFinalFormFieldArrayInputComponentProps['fields'],
  cuePoints: ICuePoint[] | IVideoOnlyCuePoint[],
  type: RoadshowTypes,
  disabled: boolean,
  addCuePoint: (name: string, value: object) => void,
  removeCuePoint: (name: string, index: number) => void,
}

const CuePoints = ({
  fields,
  cuePoints,
  type,
  disabled,
  addCuePoint,
  removeCuePoint,
}: ICuePointsProps) => {
  const [localCuePoints, setLocalCuePoints] = useState<string>('');
  const typeName = RoadshowType.isVideoOnly(type) ? 'number' : 'slideNumber';
  const fieldsName = fields.name;

  const handleAddCuePoints = (event: ChangeEvent<HTMLInputElement>) => setLocalCuePoints(event.target.value);

  const addCuePointsLine = () => {
    const localCuePointsInt = parseInt(localCuePoints);
    if (localCuePoints && localCuePointsInt > 0) {
      for (let i = 0; i < localCuePointsInt; i++) {
        addCuePoint(fieldsName, { [typeName]: fields.length + i + 1 });
      }
      setLocalCuePoints('');
    }
  };

  const removeCuePointsLine = (index: number) => removeCuePoint(fieldsName, index);

  const getCellActionsComponent = ({ rowIndex }: ICellProps) => (
    <IconTrash
      onClick={ () => removeCuePointsLine(rowIndex) }
      className={ styles.actionsIcon }
    />
  );

  const cellSlideNumberCallback = ({ rowIndex }: ICellProps) => (
    <Field
      name={ `${ fieldsName }[${ rowIndex }].${ typeName }` }
      dataTest={ `${ fieldsName }[${ rowIndex }].numberInput` }
      parse={ (value) => +value }
      disabled
      formFieldClassName={ styles.cuePointsTimeField }
    >
      { ({ input, ...props }) => (
        /* @ts-ignore */
        <Input
          { ...props }
          input={ {
            ...input,
            value: rowIndex + 1,
          } }
        />
      ) }
    </Field>
  );

  const cellSlideTimeCallback = ({ rowIndex }: ICellProps) => (
    <Field
      name={ `${ fieldsName }[${ rowIndex }].time` }
      component={ Input }
      dataTest={ `${ fieldsName }[${ rowIndex }].timeInput` }
      parse={ (value) => (Number.isInteger(+value) ? +value : '') }
      formFieldClassName={ styles.cuePointsTimeField }
      isClearable={ false }
    />
  );

  const cellSectionNameCallback = ({ rowIndex }: ICellProps) => (
    <Field
      name={ `${ fieldsName }[${ rowIndex }].sectionName` }
      component={ Input }
      dataTest={ `${ fieldsName }[${ rowIndex }].sectionNameInput` }
      maxLength={ 200 }
      formFieldClassName={ styles.cuePointsTimeField }
    />
  );

  const cellSlideTimeStringCallback = ({ rowIndex }: ICellProps) => {
    const fieldValue: ICuePoint | IVideoOnlyCuePoint = fields.value?.[rowIndex];

    if (!fieldValue) {
      return null;
    }

    const [time, format] = cellTimeCheck(fieldValue.time);

    if (time >= 0) {
      return (
        <span>{ formatDate(new Date(Number(time) * 1000), String(format)) }</span>
      );
    }

    return null;
  };

  const cellDisplayTextCallback = ({
    rowIndex,
    cellData,
  }: { rowIndex: number, cellData: string }) => (
    <Field
      name={ `${ fields.name }[${ rowIndex }].displayName` }
      render={ (props) => <RichTextInput { ...{ ...props, initialValue: cellData ?? '' } } /> }
      dataTest={ `${ fields.name }[${ rowIndex }].displayName` }
      formFieldCls={ styles.displayTextField }
      maxLength={ 1000 }
      toolbarPosition="popover"
      editableWrpCls={ styles.displayTextEditableWrp }
    />
  );

  const tableColumns = [
    {
      name: typeName,
      title: 'Slide #',
      cellCallback: cellSlideNumberCallback,
      width: 112,
    },
    {
      name: 'time',
      title: (
        <div className={ styles.timeCellHeader }>
          Start Time (Seconds)
          <CopyToClipboard
            content={
              cuePoints && cuePoints.map((cuePoint: ICuePoint | IVideoOnlyCuePoint) => cuePoint.time).join(',\n')
            }
          >
            <IconCopy />
          </CopyToClipboard>
        </div>
      ),
      className: alignStyles.alignCenter,
      cellCallback: cellSlideTimeCallback,
      width: 180,
    },
    (!RoadshowType.isVideoOnly(type) && {
      name: 'displayName',
      title: 'Display Text (Optional)',
      className: alignStyles.alignLeft,
      cellCallback: cellDisplayTextCallback,
      width: 810,
    }),
    (RoadshowType.isVideoOnly(type) && {
      name: 'sectionName',
      title: 'Section Name',
      className: alignStyles.alignCenter,
      cellCallback: cellSectionNameCallback,
    }),
    {
      name: 'timeMoment',
      title: 'Start Time (MM:SS)',
      className: alignStyles.alignCenter,
      cellCallback: cellSlideTimeStringCallback,
    },
    {
      name: 'actions',
      title: 'Actions',
      className: alignStyles.alignCenter,
      cellCallback: getCellActionsComponent,
    },
  ];

  const isTooltipHidden = !disabled;

  return (
    <>
      <FormField className={ styles.formField }>
        { /* @ts-ignore */ }
        <Input
          type="text"
          name="addCuePoints"
          formFieldClassName={ cn(formStyles.formInput, styles.addCuePoints) }
          inputClassName={ styles.addCuePointsInput }
          onChange={ handleAddCuePoints }
          value={ localCuePoints }
          isNarrow
          dataTest="addCuePointsInput"
          disabled={ disabled }
        />
        <Tooltip
          disabled={ isTooltipHidden }
          content="Audio/video cues cannot be applied to a Roadshow with Moderation activated."
        >
          <Button
            variant={ ButtonVariantType.action }
            onClick={ addCuePointsLine }
            dataTest="addCuePointsButton"
            className={ styles.addCuePointsButton }
            disabled={ disabled }
          >
            <IconPlus />
          </Button>
        </Tooltip>
      </FormField>
      <FormField>
        <div className={ formStyles.formLabel }>Drag & Drop Cue Points or</div>
        <UploadPresentationCuePointsListContainer
          disabled={ disabled }
          disabledTooltip="Audio/video cues cannot be applied to a Roadshow with Moderation activated."
        />
      </FormField>
      <DataTable
        containerClassName={ styles.tableContainer }
        className={ cn(dataTableStyles.isHoverable, styles.tableRow) }
        columns={ tableColumns }
        dataTest="addVideoOnlyCuePointsDataTable"
        data={ cuePoints }
      />
    </>
  );
};

export default CuePoints;
