import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import CurrencyRepository from '@/dealroadshow/infrastructure/repository/currencies/CurrencyRepository';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { ICurrency } from '@/dealroadshow/domain/vo/ICurrency';

export default function useCurrencies() {
  const { container } = useDIContext();
  const [isFetching, setIsFetching] = useState(false);
  const [collection, setCollection] = useState<ICurrency[]>([]);

  async function getCurrenciesList() {
    setIsFetching(true);

    try {
      const currencyRepository = container.get<CurrencyRepository>(CurrencyRepository);
      const response = await currencyRepository.getCurrencies();
      setCollection(response);
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  }

  return {
    isFetching,
    collection,
    getCurrenciesList,
  };
}
