import { useEffect, useState } from 'react';
import { useContactProfileContext } from '../ContactProfileContext';
import { useApiAccessKey } from '@/users/application/useApiAccessKey';

export const useAdvanced = () => {
  const { user } = useContactProfileContext();
  const {
    apiAccessKey,
    isLoading,
    getApiAccessKey,
    setApiAccessKey: setApiAccessKeyMethod,
  } = useApiAccessKey();

  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);

  useEffect(() => {
    getApiAccessKey(user.id);
  }, [user.id]);

  const setApiAccessKey = async () => {
    await setApiAccessKeyMethod(user.id);
    setIsVisibleModal(false);
  };

  return {
    isVisibleModal,
    setIsVisibleModal,
    apiAccessKey,
    isLoading,
    getApiAccessKey,
    setApiAccessKey,
  };
};
