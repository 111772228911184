import React, { createContext, useContext, useEffect, useState } from 'react';
import { useStatsCategories } from '@/condor/application/Finsight/CreditFlowResearch/Stats/StatsCategories';
import { ABS_PRODUCT_ID } from '@/finsight/domain/Product';
import { USOA_REGION_ID } from '@/finsight/domain/Region';
import { ICategory } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ICategory';
import { useDIContext } from '@/Framework/DI/DIContext';
import CreditFlowResearchRepository
  from '@/condor/infrastructure/repository/creditFlowResearch/CreditFlowResearchRepository';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';

const useManageCategories = () => {
  const { container } = useDIContext();
  const [productId, setProductId] = useState<string>(ABS_PRODUCT_ID);
  const [regionId, setRegionId] = useState<string>(USOA_REGION_ID);
  const { categories, getCategories, isInitialized: isCategoriesInitialized } = useStatsCategories<ICategory>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    getCategories();
  }, []);

  const submitForm = async (payload):Promise<boolean> => {
    setIsSubmitting(true);
    const CFRRepository = container.get<CreditFlowResearchRepository>(CreditFlowResearchRepository);
    try {
      await CFRRepository.saveCategories(payload);
      setIsSubmitting(false);
      AlertManager.success('Changes successfully saved!');
      return true;
    } catch (e) {
      AlertManager.error(getErrorMessage(e));
      setIsSubmitting(false);
      return false;
    }
  };

  return {
    isCategoriesInitialized,
    isSubmitting,

    categories,

    productId,
    regionId,

    setProductId,
    setRegionId,
    submitForm,
  };
};

const ManageStatsCategoriesContext = createContext<ReturnType<typeof useManageCategories>>(null);

export const useManageCategoriesContext = () => {
  const context = useContext(ManageStatsCategoriesContext);
  if (!context) {
    throw new Error('useManageCategoriesContext must be used within a ManageStatsCategoriesContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const ManageStatsCategoriesContextProvider = ({ children }: IProps) => (
  <ManageStatsCategoriesContext.Provider value={ useManageCategories() }>
    { children }
  </ManageStatsCategoriesContext.Provider>
);

export default ManageStatsCategoriesContextProvider;
