import { createContext, useContext, useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import { IUser } from '@/users/domain/vo/User';
import UserRepository from '@/dmPortal/infrastructure/repository/UserRepository';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';

const useUsers = () => {
  const { container } = useDIContext();

  const [isFetching, setIsFetching] = useState(false);
  const [usersCache, setUsersCache] = useState(new Map<string, IUser | null>());

  const userRepository = container.get<UserRepository>(UserRepository);

  const getUsersByEmails = async (emails: string[]) => {
    const nonCachedUsersEmails = emails.filter((email) => !usersCache.has(email));

    if (nonCachedUsersEmails.length === 0) {
      return;
    }

    setIsFetching(true);

    try {
      // TODO: Should be removed the connection to dmportal
      const { data } = await userRepository.getEmailsFullInfo({ emails: nonCachedUsersEmails });

      setUsersCache((cache) => {
        nonCachedUsersEmails.forEach((email) => {
          const userInfo = data.find((user) => user.email === email) ?? null;

          cache.set(email, userInfo);
        });

        return cache;
      });
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  };

  const getUserInfo = (email: string): IUser | null | undefined => {
    if (!email) {
      return null;
    }

    return usersCache.get(email);
  };

  return {
    isFetching,
    getUsersByEmails,
    getUserInfo,
  };
};

export const UsersContext = createContext<ReturnType<typeof useUsers>>(null);

export const useUsersContext = () => {
  const context = useContext(UsersContext);
  if (!context) {
    throw new Error('useUsersContext must be used within a UsersContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const UsersContextProvider = ({ children }: IProps) => (
  <UsersContext.Provider value={ useUsers() }>{ children }</UsersContext.Provider>
);

export default UsersContextProvider;
