import Modal from '@dealroadshow/uikit/core/components/Modal';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { useRelatedRoadshowsContext } from './RelatedRoadshowsContext';
import { Field } from '@/Framework/UI/Organisms/FinalForm';
import Textarea from '@dealroadshow/uikit/core/components/Textarea';
import { UploadRelatedRoadshowLogoContainer } from '@/Framework/UI/Organisms/Upload/containers';
import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';

export const EditModal = () => {
  const {
    editModalRow,
    hideEditModal,
    applyEditModal,
  } = useRelatedRoadshowsContext();

  return (
    <Modal
      onCloseClicked={ hideEditModal }
      isVisible
      showCloseButton={ false }
      closeOnEsc={ false }
      title="Update Roadshow Details"
      dataTest="updateRoadshowDetailsModal"
      footer={ (
        <>
          <Button
            variant={ ButtonVariantType.success }
            onClick={ applyEditModal }
            dataTest="applyRoadshowDetailModalButton"
            title="Apply"
          />
          <Button
            variant={ ButtonVariantType.secondary }
            onClick={ hideEditModal }
            dataTest="cancelRoadshowDetailModalButton"
            title="Cancel"
          />
        </>
      ) }
    >
      <Field
        label="Display Text"
        name={ `relatedRoadshows[${ editModalRow?.rowIndex }]text` }
        component={ Textarea }
        dataTest={ `relatedRoadshows[${ editModalRow?.rowIndex }]Textarea` }
      />
      <div>
        <div className={ formStyles.formLabel }>Logo</div>
        <UploadRelatedRoadshowLogoContainer />
      </div>
    </Modal>
  );
};
