import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { useFormState, useForm } from '@/Framework/UI/Organisms/FinalForm';
import { useDIContext } from '@/Framework/DI/DIContext';
import CreditFlowResearchRepository
  from '@/condor/infrastructure/repository/creditFlowResearch/CreditFlowResearchRepository';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { ArticleFormData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/ArticleFormData';
import { getArticleDataForPreview, getPreviewPayload, IMappedPreviewData } from './helpers';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import { useArticleProfileContext } from '../ArticleProfileContext';
import SectorRepository from '@/finsight/infrastructure/repository/SectorRepository';
import IndustryRepository from '@/finsight/infrastructure/repository/IndustryRepository';

const useArticlePreview = (
  setModalOpen: (isOpen: boolean) => void,
  ) => {
  const [isFetching, setIsFetching] = useState(false);
  const [previewData, setPreviewData] = useState<IMappedPreviewData>(null);
  const { currentUser } = useSessionContext();

  const { values } = useFormState<ArticleFormData>();
  const { change, submit } = useForm();
  const { container } = useDIContext();
  const creditFlowResearchRepository = container.get<CreditFlowResearchRepository>(CreditFlowResearchRepository);
  const { initialArticle, isEdit } = useArticleProfileContext();

  const closePreviewModal = () => {
    setModalOpen(false);
  };

  const getPreviewData = async () => {
    setIsFetching(true);
    try {
      const sectorRepository = container.get<SectorRepository>(SectorRepository);
      const industryRepository = container.get<IndustryRepository>(IndustryRepository);
      const previewPayload = getPreviewPayload(values);
      const [sectors, industries, previewData] = await Promise.all([
        sectorRepository.getList(),
        industryRepository.getList(),
        creditFlowResearchRepository.getArticleCategories(previewPayload),
      ]);
      const mappedPreviewData = getArticleDataForPreview(previewData, values, industries, sectors);

      mappedPreviewData.author = isEdit
        ? initialArticle.author.name
        : `${ currentUser.firstName } ${ currentUser.lastName }`;

      mappedPreviewData.date = isEdit
        ? initialArticle.createdAt
        : '';

      setPreviewData(mappedPreviewData);
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  };

  const publishArticle = async () => {
    try {
      setIsFetching(true);
      await submit();
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    change('isActive', true);
    change('sendEmail', isEdit ? !initialArticle?.mailingRequests.length : true);
    getPreviewData();
    return () => {
      change('isActive', false);
      change('sendEmail', false);
    };
  }, []);

  const onWithEmailChange = (e) => {
    change('sendEmail', e.target.checked);
  };

  return {
    isFetching,
    previewData,
    onWithEmailChange,
    publishArticle,
    closePreviewModal,
  };
};

type ArticlePreviewContextType = ReturnType<typeof useArticlePreview>;

const ArticlePreviewContext = createContext<ArticlePreviewContextType>(null);

export const useArticlePreviewContext = () => {
  const context = useContext(ArticlePreviewContext);

  if (!context) {
    throw new Error('useArticlePreviewContext must be used within the ArticlePreviewContext');
  }

  return context;
};

interface IProps {
  children: ReactNode,
  setIsPreviewOpen: (isOpen: boolean) => void,
}

const ArticlePreviewContextProvider = ({ children, setIsPreviewOpen }: IProps) => (
  <ArticlePreviewContext.Provider value={ useArticlePreview(setIsPreviewOpen) }>
    { children }
  </ArticlePreviewContext.Provider>
);

export default ArticlePreviewContextProvider;
