import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import CompaniesRepository from '@/users/infrastructure/repository/CompaniesRepository';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';

export default function useSelectedCompany() {
  const { container } = useDIContext();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [companyName, setCompanyName] = useState<string>(null);

  async function getCompanyNameById(id: number) {
    setIsFetching(true);

    try {
      const companiesRepository = container.get<CompaniesRepository>(CompaniesRepository);
      const response = await companiesRepository.list({ id: [id] });

      setCompanyName(response?.collection.find((item) => item.id === id)?.name);
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  }

  return {
    isFetching,
    companyName,
    getCompanyNameById,
  };
}
