import Modal from '@dealroadshow/uikit/core/components/Modal';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { useRelatedRoadshowsContext } from './RelatedRoadshowsContext';

export const DeleteModal = () => {
  const {
    deleteModalRow,
    hideDeleteModal,
    applyDeleteModal,
  } = useRelatedRoadshowsContext();

  const title = deleteModalRow?.deletedAt ? 'Restore' : 'Delete';
  const modalTitle = `${ title } ${ deleteModalRow?.id } Related Roadshow?`;

  return (
    <Modal
      onCloseClicked={ hideDeleteModal }
      isVisible
      title={ modalTitle }
      dataTest="deleteRestoreModal"
      footer={ (
        <div>
          <Button
            variant={ ButtonVariantType.success }
            onClick={ applyDeleteModal }
            title={ title }
            dataTest={ `${ deleteModalRow?.deletedAt ? 'restore' : 'delete' }Button` }
          />
          <Button
            variant={ ButtonVariantType.secondary }
            onClick={ hideDeleteModal }
            dataTest="cancelRestoreModalButton"
            title="Cancel"
          />
        </div>
      ) }
    />
  );
};
