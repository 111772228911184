import { useState } from 'react';
import UserRepository from '@/users/infrastructure/repository/UserRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { IUser } from '@/users/domain/vo/User';

export interface IUserUpdatePayload extends Omit<IUser, 'email' | 'password' | 'registered' | 'createdAt'> {}

export const useUser = () => {
  const { container } = useDIContext();
  const userRepository = container.get<UserRepository>(UserRepository);

  const [user, setUser] = useState<IUser>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const getUserInfo = async (contactId: number | string) => {
    setIsFetching(true);
    try {
      const user = await userRepository.getUserById(contactId, true);
      setUser(user);
      setIsFetching(false);
    } catch (e) {
      AlertManager.error(getErrorMessage(e));
    }
  };

  const updateUser = async (userUpdatePayload: IUserUpdatePayload) => {
    setIsFetching(true);
    try {
      await userRepository.edit(userUpdatePayload);
    } catch (e) {
      AlertManager.error(getErrorMessage(e));
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    user,
    getUserInfo,
    updateUser,
  };
};
