import cn from 'classnames';
import noop from 'lodash/noop';

import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import IconEmail from '@dealroadshow/uikit/core/components/Icon/IconEmail';
import TitleField from '@/condor/ui/components/AccountsAndContacts/Contacts/TitleField';
import PhoneInput from '@dealroadshow/uikit/core/components/Input/PhoneInput';
import { Select } from '@dealroadshow/uikit/core/components/Select';
import {
  SelectOptionWithCountryFlag,
  SelectSingleValueWithCountryFlag,
} from '@dealroadshow/uikit/core/components/Select/components';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import { InputLabel as Label } from '@dealroadshow/uikit/core/components/Input/Label';
import Input from '@dealroadshow/uikit/core/components/Input';
import IconRefresh from '@dealroadshow/uikit/core/components/Icon/IconRefresh';
import { CheckboxInput } from '@dealroadshow/uikit/core/components/Checkbox';
import { useCommonProfileContext } from '@/users/ui/components/Profile/Common/CommonProfileContext';
import { useProfileForm } from './hooks/useProfileForm';
import CompanyField from '@/condor/ui/components/AccountsAndContacts/Contacts/CompanyField';
import ConsentCheckbox from '@/dataroom/ui/components/ConsentCheckbox';
import SubscriptionField from '@/users/ui/components/Profile/UserProfile/SubscriptionField';
import { cutProtocolAndWWW } from '@/Framework/url/helpers';
import {
  getLabelWithOptionalMark,
  mapCountriesToOptions,
} from '@/users/ui/components/Profile/Common/helpers';
import { Field } from '@/Framework/UI/Organisms/FinalForm';

import headerStyles from '@dealroadshow/uikit/core/styles/headers.scss';
import layoutStyles from '@/condor/ui/common/Layout/layout.scss';
import styles from './profileForm.scss';

const commonInputProps = {
  component: Input,
  className: layoutStyles.inputField,
};

const commonCheckboxProps = {
  component: CheckboxInput,
  type: 'checkbox',
  className: styles.checkbox,
};

const onLinkedinBlur = (value) => cutProtocolAndWWW(value?.trim());

const ProfileFields = ({ companies, getCompanyByQuery }) => {
  const {
    countries,
  } = useCommonProfileContext();

  const {
    generateTwoFactorCode,
    initialValues,
    twoFactorCode,
    isFetching,
  } = useProfileForm();

  return (
    <>
      <Spinner
        isVisible={ isFetching }
        overlay
      />
      <Field
        name="id"
        label="Unique Id"
        disabled
        dataTest="idFormField"
        { ...commonInputProps }
      />
      <Field
        name="email"
        label="Email"
        disabled
        dataTest="emailFormField"
        icon={ IconEmail }
        { ...commonInputProps }
      />
      <Field
        name="firstName"
        label="First Name"
        dataTest="firstNameFormField"
        { ...commonInputProps }
      />
      <Field
        name="lastName"
        label="Last Name"
        dataTest="lastNameFormField"
        { ...commonInputProps }
      />
      <TitleField label="Title" isProfile />
      <div className={ layoutStyles.inputField }>
        <Field
          name="officePhone"
          label="Office Phone"
          component={ PhoneInput }
          isFinalForm
          dataTest="officePhoneFormField"
        />
        <Field
          name="personalPhone"
          label={ getLabelWithOptionalMark('Mobile Phone') }
          component={ PhoneInput }
          isFinalForm
          dataTest="mobilePhoneFormField"
        />
        <Field
          name="jurisdiction"
          render={ ({ input, meta }) => (
            <Select
              placeholder="Jurisdiction"
              label="Jurisdiction"
              dataTest="profileFormJurisdictionSelectFormField"
              options={ mapCountriesToOptions(countries) }
              components={ {
                Option: SelectOptionWithCountryFlag,
                SingleValue: SelectSingleValueWithCountryFlag,
              } }
              { ...input }
              { ...meta }
            />
          ) }
        />
        <CompanyField
          label="Account"
          dataTest="accountFormField"
          collection={ companies }
          getCompanyByQuery={ getCompanyByQuery }
        />
        <Field
          name="linkedinProfile"
          label={ getLabelWithOptionalMark('Linkedin profile URL') }
          dataTest="profileFormLinkedingProfile"
          normalize={ onLinkedinBlur }
          { ...commonInputProps }
          placeholder="linkedin.com/id/username"
        />
        <Field
          name="bloombergEmail"
          label={ getLabelWithOptionalMark('Bloomberg Email') }
          dataTest="profileFormBloombergEmailInput"
          { ...commonInputProps }
        />
      </div>
      <Field
        component={ ConsentCheckbox }
        type="checkbox"
        name="consentEnabled"
        change={ noop }
        disabled
        contactId={ initialValues?.id }
      />
      <Label className={ styles.contactStatesLabel }>CONTACT STATES</Label>
      <Field
        name="activated"
        dataTest="activatedContact"
        label="Activated (for Finsight registered users)"
        { ...commonCheckboxProps }
      />
      <Field
        name="emailVerified"
        dataTest="emailVerifiedContact"
        label="Verified (for Deal Roadshow investors)"
        { ...commonCheckboxProps }
      />
      <Field
        name="banned"
        dataTest="bannedContact"
        label="Banned (to revoke access to all Finsight platforms)"
        { ...commonCheckboxProps }
      />
      <div className={ layoutStyles.inputField }>
        <div className={ cn(
          headerStyles.isH3,
          styles.title,
        ) }
        >
          Application Settings
        </div>
        <SubscriptionField contactId={ initialValues?.id } />
      </div>
      <div className={ layoutStyles.inputField }>
        <div className={ cn(
          headerStyles.isH3,
          styles.title,
          styles.titleSmallMT,
        ) }
        >
          Two-Factor Authentication (2FA)
        </div>
        <div>
          <Label>live verification code</Label>
          <div className={ styles.twoFactorBlock }>
            <div className={ styles.twoFactorInput }>
              <div className={ styles.twoFactorCode } data-test="twoFactorCode">
                { twoFactorCode || '' }
              </div>
              <div
                className={ styles.twoFactorButton }
                onClick={ generateTwoFactorCode }
                data-test="twoFactorCodeGenerateNew"
              >
                <Tooltip content="Generate new code">
                  <IconRefresh
                    className={ styles.refreshIcon }
                  />
                </Tooltip>
              </div>
            </div>
            <div className={ styles.twoFactorDescription }>
              Verification codes must only be sent by email.
              Do NOT provide codes over the phone.
            </div>
          </div>
        </div>
        <Field
          name="twoFactorPhone"
          label="2FA Mobile Number (verification code to be sent via text message)"
          disabled
          isNarrow
          isFinalForm
          dataTest="twoFactorPhoneFormField"
          component={ PhoneInput }
        />
      </div>
    </>
  );
};

export default ProfileFields;
