import { createContext, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '@/users/application/useUser';

const useContactProfile = () => {
  const { contactId } = useParams();
  const {
    user,
    isFetching,
    getUserInfo,
  } = useUser();

  useEffect(() => {
    if (contactId) {
      getUserInfo(contactId);
    }
  }, [contactId]);

  return {
    isFetching,
    user,
  };
};

type ContactProfileContextType = ReturnType<typeof useContactProfile>;

const ContactProfileContext = createContext<ContactProfileContextType>(null);

export const useContactProfileContext = () => {
  const context = useContext(ContactProfileContext);
  if (!context) {
    throw new Error('useContactProfileContext must be used within a ContactProfileContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const ContactProfileContextProvider = ({ children }: IProps) => (
  <ContactProfileContext.Provider value={ useContactProfile() }>
    { children }
  </ContactProfileContext.Provider>
);

export default ContactProfileContextProvider;
