import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getMessage, getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import SSORepository from '@/users/infrastructure/repository/SSORepository';
import { useSelector } from 'react-redux';
import selectors from '@/condor/application/actions/accounts/item/selectors';
import { IEmailDomain, IGetValuesResponse, ISSOFormValues, ISSOPayload } from '@/users/domain/vo/sso/SSOForm';
import { messageCodes } from '@/Framework/Message/messages';
import { useEnableSSOContext } from '@/condor/application/Accounts/AccountProfile/AccountSSO/EnableSSO';

const initialVals = {
  subdomain: 'api',
  shortname: '',
  loginUrl: '',
  emailJsonPath: '',
  enabled: false,
  ignoreSignature: false,
  allowUnencryptedAssertion: false,
  emailDomains: [],
};

const formatInitialValues = (initialValues: IGetValuesResponse): ISSOFormValues => {
  const { emailDomains } = initialValues;
  return {
    ...initialValues,
    emailDomains: emailDomains.length
      ? emailDomains.map((domain: string) => ({ value: domain, isValid: true }))
      : [],
    enabled: initialValues.enabled || false,
  };
};

const useSSO = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [initialValues, setInitialValues] = useState<ISSOFormValues>(initialVals);
  const [isSubmitting, setIsSubmitting] = useState<boolean>();
  const [selectedDomains, setSelectedDomains] = useState(initialValues.emailDomains);
  const [isChecking, setIsChecking] = useState<boolean>(false);
  const [checkedDomains, setCheckedDomains] = useState([]);
  const { container } = useDIContext();
  const { id: accountId } = useSelector((state) => selectors.getItemData(state));
  const { isCertificatesExist } = useEnableSSOContext();

  useEffect(() => {
    if (!isCertificatesExist && initialValues.enabled) {
      setInitialValues({ ...initialValues, enabled: false });
    }
  }, [isCertificatesExist, initialValues]);

  const resetData = () => {
    setCheckedDomains([]);
    setSelectedDomains([]);
  };

  const checkNewDomains = async (newDomains: IEmailDomain[]) => {
    if (!newDomains) return;
    const filteredNewDomains = newDomains.reduce((domainsToCheck: IEmailDomain[], domain) => {
      let isExistingInitialValues = false;
      initialValues.emailDomains.forEach(({ value }) => {
        if (value === domain.value) {
          isExistingInitialValues = true;
        }
      });
      if (isExistingInitialValues) return domainsToCheck;
      domainsToCheck.push(domain);
      return domainsToCheck;
    }, []);
    if (!filteredNewDomains.length) return;
    const domains = [];
    newDomains.filter((newDomain) => newDomain.isValid).forEach((validDomain) => {
      domains.push(validDomain.value);
    });
    if (!domains.length) return;
    try {
      setIsChecking(true);
      const response = await container.get(SSORepository).checkDomains({ domains });
      setCheckedDomains(response);
    } catch (e) {
      AlertManager.error(getErrorMessage(e));
    } finally {
      setIsChecking(false);
    }
  };

  const getInitialValues = async () => {
    setIsFetching(true);
    try {
      const values = await container.get(SSORepository).getValues({ accountId });
      setInitialValues(formatInitialValues(values || initialValues));
    } catch (err) {
      AlertManager.error(getErrorMessage(err));
    }
    setIsFetching(false);
  };

  useEffect(() => {
    getInitialValues();
  }, []);

  const validateShortname = async (shortname: string) => {
    if (!shortname) return null;
    if (shortname === initialValues.shortname) return null;
    try {
      const response = await container.get(SSORepository).checkShortname({ shortname });
      if (!response) return null;
      return 'Shortname already taken';
    } catch (e) {
      AlertManager.error(getErrorMessage(e));
    }
    return null;
  };

  const onSubmit = async (values: ISSOFormValues) => {
    const formattedValuesForSubmit: ISSOPayload = {
      ...values,
      accountId,
      emailDomains: (values.emailDomains || initialValues.emailDomains).map((domain) => domain.value),
    };
    setIsSubmitting(true);
    try {
      await container.get(SSORepository).submitSSOForm(formattedValuesForSubmit);
      AlertManager.success(getMessage(messageCodes.CONDOR_SAVED));
      setInitialValues(formatInitialValues(formattedValuesForSubmit));
    } catch (e) {
      AlertManager.error(getErrorMessage(e));
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    isSubmitting,
    isFetching,
    selectedDomains,
    initialValues,
    checkedDomains,
    isChecking,

    setSelectedDomains,
    checkNewDomains,
    resetData,
    onSubmit,
    validateShortname,
  };
};

const SSOContext = createContext<ReturnType<typeof useSSO>>(null);

export const useSSOContext = () => {
  const context = useContext(SSOContext);
  if (!context) {
    throw new Error('useSSOContext must be used within a SSOContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const SSOContextProvider = ({ children }: IProps) => (
  <SSOContext.Provider value={ useSSO() }>
    { children }
  </SSOContext.Provider>
);

export default SSOContextProvider;
