import createAsyncRequest from '@/Framework/State/Redux/createAsyncRequest';
import * as actionTypes from '@/users/application/actions/permissions/actionTypes';
import UserRepository from '@/condor/infrastructure/repository/UserRepository';

const getUserRepository = (getState: () => any): UserRepository => getState().container.get(UserRepository);

export const getCurrentUserPermissions = () => (dispatch, getState) => dispatch(createAsyncRequest(
  // @ts-ignore
  {
    method: getUserRepository(getState).getCurrentUserPermissions,
    types: [
      actionTypes.GET_CURRENT_USER_PERMISSIONS,
      actionTypes.GET_CURRENT_USER_PERMISSIONS_SUCCESS,
      actionTypes.GET_CURRENT_USER_PERMISSIONS_FAILURE,
    ],
  },
));
