import React from 'react';
import cn from 'classnames';

import Textarea from '@dealroadshow/uikit/core/components/Textarea';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import Label from '@dealroadshow/uikit/core/components/Input/Label';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { Select } from '@dealroadshow/uikit/core/components/Select';
import CopyToClipboardButton from '@/Framework/UI/Atoms/Button/CopyToClipboardButton';
import InvalidatePasswordModal from './InvalidatePasswordModal';
import { options } from './options';

import headerStyles from '@dealroadshow/uikit/core/styles/headers.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';
import styles from './section.scss';

interface IProps {
  label: string,
  linkLabel: string,
  link: string,
  selectProps: any,
  isGenerating: boolean,
  onGenerateClick: () => void,
  isVisibleInvalidateModal?: boolean,
  setIsVisibleInvalidateModal?: (value: boolean) => void,
  dataTest: string,
}

const Section = ({
  label,
  linkLabel,
  link,
  selectProps,
  isGenerating,
  onGenerateClick,
  isVisibleInvalidateModal,
  setIsVisibleInvalidateModal,
  dataTest,
}: IProps) => (
  <div className={ styles.section }>
    <Spinner
      isVisible={ isGenerating }
      overlay
    />
    <div className={ cn(headerStyles.isH3, spacesStyles.mbl) }>{ label }</div>
    <Select
      options={ options }
      isNarrow
      className={ styles.select }
      dataTest={ `${ dataTest }Select` }
      { ...selectProps }
    />
    <Button
      variant={ ButtonVariantType.action }
      className={ styles.generateBtn }
      onClick={ onGenerateClick }
      title="Generate"
      dataTest={ `${ dataTest }GenerateButton` }
    />
    <Label>{ linkLabel }</Label>
    <Textarea
      input={ { value: link } }
      disabled
      className={ cn(styles.textArea, { [styles.filledTextArea]: !!link }) }
      dataTest={ `${ dataTest }Textarea` }
    />
    <CopyToClipboardButton
      className={ styles.copyBtnContainer }
      copyElemClassName={ styles.copyBtn }
      copiedElemClassName={ styles.copied }
      content={ link }
      labelBeforeCopy="Copy Link"
      labelAfterCopy="Copied"
      isDisabled={ !link }
      dataTest={ `${ dataTest }CopyToClipboard` }
    />
    { setIsVisibleInvalidateModal && (
    <>
      <Button
        variant={ ButtonVariantType.action }
        className={ styles.invalidateBtn }
        onClick={ () => setIsVisibleInvalidateModal(true) }
        title="Invalidate Password"
        dataTest={ `${ dataTest }InvalidatePassButton` }
      />
      { isVisibleInvalidateModal && (
        <InvalidatePasswordModal onCloseClicked={ () => setIsVisibleInvalidateModal(false) } />
      ) }
    </>
    ) }
  </div>
);

export default Section;
