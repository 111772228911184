import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { useUserPermissionsContext } from './UserPermissionsContext';
import permissionStyles from './userPermissions.scss';
import { useCheckBoxArrayContext } from '@/Framework/UI/Molecules/CheckBoxArray/CheckboxArrayContext';

const PermissionsActions = () => {
  const { onSave, hasPermissionToUpdate } = useUserPermissionsContext();
  const { reset, values } = useCheckBoxArrayContext();

  if (!hasPermissionToUpdate) return null;

  return (
    <div className={ permissionStyles.actionsContainer }>
      <Button
        variant={ ButtonVariantType.action }
        title="Save"
        dataTest="userPermissionsSaveButton"
        onClick={ () => onSave(values) }
      />
      <Button
        variant={ ButtonVariantType.text }
        title="Cancel"
        dataTest="userPermissionsCancelButton"
        onClick={ reset }
      />
    </div>
  );
};

export default PermissionsActions;
