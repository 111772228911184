import actionTypes from './actionTypes';
import selectors from './selectors';
import { extractDomainsFromStringWithValidation, getBlacklistActions } from '../helpers';
import createAsyncRequest from '@/Framework/State/Redux/createAsyncRequest';
import { getErrorMessage, getMessage } from '@/Framework/Message/Mapper/getMessage';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import BlacklistRepository from '@/dealroadshow/infrastructure/repository/BlacklistRepository';
import { messageCodes } from '@/Framework/Message/messages';

export const {
  sort,
  itemsPerPage,
  paginate,
  getCollection: getApplicationBlacklist,
  setSearchQuery: setApplicationBlacklistSearchQuery,
  resetCollection: resetApplicationBlacklistCollection,

  setCurrentItem: setCurrentApplicationBlacklistItem,
  removeItem: deleteApplicationBlacklistItem,
  resetItem: resetApplicationBlacklistItem,
  setIsDeleteModalVisible: setApplicationBlacklistIsDeleteModalVisible,

  setAddItemsInputState: setApplicationBlacklistAddItemsInputState,
  resetAddItemsInputState: resetApplicationBlacklistAddItemsInputState,
} = getBlacklistActions(
  actionTypes,
  (payload, dispatch, getState) => {
    const applicationBlacklistRepository = getState().container.get(BlacklistRepository);
    return applicationBlacklistRepository.getAdminApplicationBlacklist(payload);
  },
  {
    method: (payload, dispatch, getState) => {
      const applicationBlacklistRepository = getState().container.get(BlacklistRepository);

      return applicationBlacklistRepository.toggleActionAdminApplicationBlacklist({
        id: payload.id,
        type: 'delete',
        value: !payload.deletedAt,
      });
    },
    callbacks: {
      onSuccess: ({ payload }) => AlertManager.success(
        getMessage(messageCodes.DELETE_APPLICATION_BLACKLIST_ITEM, { domain: payload.domain }),
      ),
    },
  },
);

export const getApplicationBlacklistByFilter = () => (dispatch, getState) => {
  const state = getState();

  dispatch(getApplicationBlacklist({
    perPage: selectors.getPerPage(state),
    page: selectors.getPage(state),
    sortBy: selectors.getSortBy(state),
    sortOrder: selectors.getSortOrder(state),
    search: selectors.getSearchQuery(state),
    filter: 'active',
  }));
};

/**
 * @param {Array} payload
 */
export const addApplicationBlacklistItems = (payload) => (dispatch, getState) => {
  const applicationBlacklistRepository = getState().container.get(BlacklistRepository);

  return dispatch(createAsyncRequest(
    {
      payload,
      method: applicationBlacklistRepository.addAdminApplicationBlacklist,
      types: [actionTypes.ADD_ITEMS, actionTypes.ADD_ITEMS_SUCCESS, actionTypes.ADD_ITEMS_ERROR],
      callbacks: {
        onSuccess: ({ dispatch }) => {
          dispatch(getApplicationBlacklistByFilter());
          dispatch(resetApplicationBlacklistAddItemsInputState());

          AlertManager.success(getMessage(
            messageCodes.ADD_DOMAINS_TO_APPLICATION_BLACKLIST_SUCCESS,
            { domains: payload.map((item) => item.domain).join(', ') },
          ));
        },
        onError: ({ error, dispatch }) => {
          if (error?.getCode() === 2701) {
            dispatch(setApplicationBlacklistAddItemsInputState(
              { errors: error.getData().map((item) => `${ item } is already in blacklist`) },
            ));
          } else {
            AlertManager.error(getErrorMessage(error));
          }
        },
      },
    },
  ));
};

export const addApplicationBlacklistItemsWithValidation = () => async (dispatch, getState) => {
  const state = getState();
  const domains = extractDomainsFromStringWithValidation(
    selectors.getAddInputValue(state),
    selectors.getCollectionData(state),
  );

  if (domains.errors.length) {
    dispatch(setApplicationBlacklistAddItemsInputState({ errors: domains.errors }));
    return;
  }

  await dispatch(addApplicationBlacklistItems(domains.items));
};

export const reset = () => (dispatch) => {
  dispatch(resetApplicationBlacklistItem());
  dispatch(resetApplicationBlacklistCollection());
  dispatch(resetApplicationBlacklistAddItemsInputState());
};
