import { useState } from 'react';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { useDIContext } from '@/Framework/DI/DIContext';
import UserRepository from '@/users/infrastructure/repository/UserRepository';

export const useGenerateLinks = () => {
  const { container } = useDIContext();
  const userRepository = container.get<UserRepository>(UserRepository);

  const [isFetchingPassword, setIsFetchingPassword] = useState<boolean>(false);
  const [passwordResetLink, setPasswordResetLink] = useState<string>('');

  const [isFetchingActivation, setIsFetchingActivation] = useState<boolean>(false);
  const [activationLink, setActivationLink] = useState<string>('');

  const generateUserPasswordResetLink = async (userId: number, tenant: string, callbackUrl: string) => {
    setIsFetchingPassword(true);
    try {
      const link = await userRepository.regenerateForgotPasswordUrl(
        userId,
        tenant,
        callbackUrl,
      );
      setPasswordResetLink(link);
    } catch (e) {
      AlertManager.error(getErrorMessage(e));
    } finally {
      setIsFetchingPassword(false);
    }
  };

  const generateUserActivationLink = async (email: string, tenant: string, callbackUrl: string) => {
    setIsFetchingActivation(true);
    try {
      const link = await userRepository.getActivationUrl(
        email,
        tenant,
        callbackUrl,
      );
      setActivationLink(link);
    } catch (e) {
      AlertManager.error(getErrorMessage(e));
    } finally {
      setIsFetchingActivation(false);
    }
  };

  return {
    isFetchingPassword,
    passwordResetLink,
    generateUserPasswordResetLink,
    isFetchingActivation,
    activationLink,
    generateUserActivationLink,
  };
};
