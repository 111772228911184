import { isEqual } from 'lodash';
import { useMemo } from 'react';
import { FormSpy, useField } from '@/Framework/UI/Organisms/FinalForm';
import { IInitialMedia, IPresentationMediaAudio, IPresentationMediaVideo, IUploadedMedia } from '../interfaces';
import RoadshowType, { RoadshowTypes } from '@/dealroadshow/domain/RoadshowType';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { useAudioVideoFormContext } from '../AudioVideoFormContext';

interface IAudioVideoFormSubmitProps {
  type: RoadshowTypes,
  resetForm: () => void,
}

const AudioVideoFormSubmit = ({ type, resetForm }: IAudioVideoFormSubmitProps) => {
  const { initialValues, isFetching, uploadedMedia } = useAudioVideoFormContext();

  const {
    input: { value: cuePoints },
  } = useField('cuePoints', {
    subscription: { value: true },
  });

  const {
    input: { value: videoOnlyCuePoints },
  } = useField('videoOnlyCuePoints', {
    subscription: { value: true },
  });

  const isVideoOnly = RoadshowType.isVideoOnly(type);
  const isVideo = RoadshowType.isSlidesAndVideo(type) || isVideoOnly;
  const isAudio = RoadshowType.isSlidesAndAudio(type);

  const canSaveAudioVideo = (
    {
      presentationMediaVideo,
      presentationMediaAudio,
    }: { presentationMediaVideo: IPresentationMediaVideo, presentationMediaAudio: IPresentationMediaAudio },
    type: RoadshowTypes,
  ) => {
    if (RoadshowType.isSlidesAndAudio(type) && !presentationMediaAudio.canSave) {
      return false;
    }
    if ((RoadshowType.isSlidesAndVideo(type) || RoadshowType.isVideoOnly(type)) && !presentationMediaVideo.canSave) {
      return false;
    }
    return true;
  };

  const isMediaReadyToSubmit = useMemo(() => {
    return canSaveAudioVideo(uploadedMedia, type);
  }, [uploadedMedia, type]);

  const hasMediaChanges = (initialMedia: IInitialMedia, uploadedMedia: IUploadedMedia) => {
    if (!initialMedia || !uploadedMedia) {
      return false;
    }

    if (isVideo) {
      if (!initialMedia.mediaVideo && uploadedMedia.presentationMediaVideo.video) {
        return true;
      }

      return (
        initialMedia.mediaVideo &&
        uploadedMedia.presentationMediaVideo.video &&
        initialMedia.mediaVideo.id !== uploadedMedia.presentationMediaVideo.video.id &&
        uploadedMedia.presentationMediaVideo.canSave
      );
    }

    if (isAudio) {
      if (!initialMedia.mediaAudio && uploadedMedia.presentationMediaAudio.audio) {
        return true;
      }

      return (
        initialMedia.mediaAudio &&
        uploadedMedia.presentationMediaAudio.audio &&
        initialMedia.mediaAudio.id !== uploadedMedia.presentationMediaAudio.audio.id &&
        uploadedMedia.presentationMediaAudio.canSave
      );
    }

    return false;
  };

  return (
    <FormSpy subscription={ { pristine: true } }>
      { ({ pristine }) => {
        const hasChanges =
          !pristine ||
          initialValues.type !== type ||
          !isEqual(initialValues.cuePoints, cuePoints) ||
          !isEqual(initialValues.videoOnlyCuePoints, videoOnlyCuePoints) ||
          hasMediaChanges(initialValues.presentationMedia, uploadedMedia);

        return (
          <>
            <Button
              variant={ ButtonVariantType.action }
              type="submit"
              disabled={ isFetching || !isMediaReadyToSubmit || !hasChanges }
              title="Save"
              dataTest="condorAudioVideoFormSaveButton"
            >
              Save
            </Button>
            <Button
              variant={ ButtonVariantType.text }
              disabled={ isFetching }
              onClick={ resetForm }
              dataTest="condorAudioVideoFormCancelButton"
            >
              Cancel
            </Button>
          </>
        );
      } }
    </FormSpy>
  );
};

export default AudioVideoFormSubmit;
