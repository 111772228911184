import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import CreditFlowResearchRepository
  from '@/condor/infrastructure/repository/creditFlowResearch/CreditFlowResearchRepository';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';

export const useStatsCategories = <T>() => {
  const { container } = useDIContext();

  const creditFlowResearchRepository = container.get<CreditFlowResearchRepository>(CreditFlowResearchRepository);
  const [isInitialized, setIsInitialized] = useState(false);
  const [categories, setCategories] = useState<T[]>([]);

  const getCategories = async (isGrouped: boolean = false) => {
    setIsInitialized(false);
    try {
      const categories = await creditFlowResearchRepository.getStatsCategories<T[]>({ isGrouped });
      setIsInitialized(true);
      setCategories(categories);
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
    }
  };

  return {
    categories,
    isInitialized,
    getCategories,
  };
};
