import { createContext, useContext, useEffect, useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import CreditFlowResearchRepository
  from '@/condor/infrastructure/repository/creditFlowResearch/CreditFlowResearchRepository';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { ITheme } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ITheme';

const useArticlesThemes = () => {
  const { container } = useDIContext();

  const creditFlowResearchRepository = container.get<CreditFlowResearchRepository>(CreditFlowResearchRepository);
  const [isInitialized, setIsInitialized] = useState(false);
  const [themes, setThemes] = useState<ITheme[]>([]);

  const loadThemes = async () => {
    try {
      const themes = await creditFlowResearchRepository.getArticlesThemes();
      setIsInitialized(true);
      setThemes(themes);
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
    }
  };

  useEffect(() => {
    loadThemes();
  }, []);

  return { themes, isInitialized };
};

type ArticlesThemesContextType = ReturnType<typeof useArticlesThemes>;

const ArticlesThemesContext = createContext<ArticlesThemesContextType>(null);

export const useArticlesThemesContext = () => {
  const context = useContext(ArticlesThemesContext);

  if (!context) {
    throw new Error('useArticlesThemesContext must be used within a ArticlesThemesContextProvider');
  }

  return context;
};

const ArticlesThemesContextProvider = ({ children }) => (
  <ArticlesThemesContext.Provider value={ useArticlesThemes() }>
    { children }
  </ArticlesThemesContext.Provider>
);

export default ArticlesThemesContextProvider;
