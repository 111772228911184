import { createContext, useContext, PropsWithChildren } from 'react';
import { useUserPermissions } from './useUserPermissions';

type UserPermissionsContextType = ReturnType<typeof useUserPermissions>;

const UserPermissionsContext = createContext<UserPermissionsContextType>(null);

export const useUserPermissionsContext = () => {
  const context = useContext(UserPermissionsContext);
  if (!context) {
    throw new Error('useUserPermissionsContext must be used within a UserPermissionsContextProvider');
  }
  return context;
};

const UserPermissionsContextProvider = ({ children }: PropsWithChildren) => (
  <UserPermissionsContext.Provider value={ useUserPermissions() }>
    { children }
  </UserPermissionsContext.Provider>
);

export default UserPermissionsContextProvider;
