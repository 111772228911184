import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Select } from '@dealroadshow/uikit/core/components/Select';
import { useCommonProfileContext } from '@/users/ui/components/Profile/Common/CommonProfileContext';
import { Field } from '@/Framework/UI/Organisms/FinalForm';
import layoutStyles from '@/condor/ui/common/Layout/layout.scss';
import * as userTitleSelectors from '@/users/application/actions/title/selectors';

interface IProps {
  isProfile: boolean,
  label?: string,
}

const TitleField = ({ isProfile, label }: IProps) => {
  const userTitlesOptions = useSelector(userTitleSelectors.getTitles); // TODO: get rid of it in FIN-27647
  const { titles } = useCommonProfileContext();

  const options = useMemo(() => titles.map(({ id, name }) => ({
    value: id,
    label: name,
  })), [titles]);

  return (
    <Field
      name="title"
      backspaceRemovesValue={ false }
      render={ ({ input, meta }) => (
        <Select
          dataTest="titleId"
          placeholder="Select Title"
          className={ layoutStyles.inputField }
          options={ !isProfile ? userTitlesOptions : options }
          label={ label }
          { ...input }
          { ...meta }
        />
      ) }
    />
  );
};

export default TitleField;
