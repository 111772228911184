import * as actionTypes from './actionTypes';
import BannersRepository from '@/condor/infrastructure/repository/banners/BannersRepository';
import createAsyncRequest from '@/Framework/State/Redux/createAsyncRequest';
import createAction from '@/Framework/State/Redux/createAction';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import { messageCodes } from '@/Framework/Message/messages';
import { getBannersData } from '@/condor/application/actions/banners/selectors';

/**
 * Get all banners
 * @param {Object} payload
 *
 * @returns {Function}
 *
 * */
export const getBannersList = (payload = {}) => (dispatch, getState) => {
  const bannersRepository = getState().container.get(BannersRepository);

  return dispatch(createAsyncRequest(
    {
      payload,
      method: bannersRepository.list,
      types: [
        actionTypes.GET_BANNERS_LIST,
        actionTypes.GET_BANNERS_LIST_SUCCESS,
        actionTypes.GET_BANNERS_LIST_ERROR,
      ],
    },
  ));
};

/**
 * Get all positions for banners
 * @param {Object} payload
 *
 * @returns {Function}
 *
 * */
export const getBannersPositionsList = (payload = {}) => (dispatch, getState) => {
  const bannersRepository = getState().container.get(BannersRepository);

  return dispatch(createAsyncRequest(
    {
      payload,
      method: bannersRepository.positionsList,
      types: [
        actionTypes.GET_BANNERS_POSITIONS_LIST,
        actionTypes.GET_BANNERS_POSITIONS_LIST_SUCCESS,
        actionTypes.GET_BANNERS_POSITIONS_LIST_ERROR,
      ],
      callbacks: {
        onSuccess: ({ dispatch }) => {
          dispatch(getBannersList());
        },
      },
    },
  ));
};

/**
 * Submit factory for banner
 * @param {Object} formData
 * @param {String} formData.bannerId
 * @param {String} formData.color
 * @param {String} formData.content
 * @param {String} formData.name
 * @param {Array.<String>} formData.positionIds
 * @param {String} formData.status
 * @param {Function} callback
 *
 * @returns {Function}
 *
 * */
export const submitBannerForm = (formData, callback) => async (dispatch, getState) => {
  const isBannerExisting = !!getBannersData(getState())
    .find((banner) => banner.bannerId === formData?.bannerId);

  const normalizeBannerFormData = (data) => ({ ...data });
  const data = normalizeBannerFormData(formData);
  return isBannerExisting
    ? dispatch(updateBanner(data, callback))
    : dispatch(createBanner(data, callback));
};

/**
 * Create banner
 * @param {Object} formData
 * @param {String} formData.bannerId
 * @param {String} formData.color
 * @param {String} formData.content
 * @param {String} formData.name
 * @param {Array.<String>} formData.positionIds
 * @param {String} formData.status
 * @param {Function} callback
 *
 * @returns {Function}
 *
 * */
export const createBanner = (formData, callback) => (dispatch, getState) => {
  const bannersRepository = getState().container.get(BannersRepository);

  return dispatch(createAsyncRequest(
    {
      method: bannersRepository.create,
      types: [
        actionTypes.SUBMIT_CREATE_BANNER_FORM,
        actionTypes.SUBMIT_CREATE_BANNER_SUCCESS,
        actionTypes.SUBMIT_CREATE_BANNER_ERROR,
      ],
      payload: formData,
      callbacks: {
        onSuccess: ({ response, dispatch }) => {
          AlertManager.success(getMessage(messageCodes.CONDOR_SAVED));
          callback(formData, response);
          dispatch(getBannersPositionsList());
        },
        onError: ({ error }) => {
          AlertManager.error(error.getMessage());
        },
      },
    },
  ));
};

/**
 * Update banner
 * @param {Object} formData
 * @param {String} formData.bannerId
 * @param {String} formData.color
 * @param {String} formData.content
 * @param {String} formData.name
 * @param {Array.<String>} formData.positionIds
 * @param {String} formData.status
 * @param {Function} callback
 *
 * @returns {Function}
 *
 * */
export const updateBanner = (formData, callback) => async (dispatch, getState) => {
  const {
    bannerId: id,
    name,
    color,
    content,
    positionIds,
    status,
  } = formData;

  const bannersRepository = getState().container.get(BannersRepository);

  dispatch(createAsyncRequest(
    {
      method: bannersRepository.update,
      types: [
        actionTypes.SUBMIT_UPDATE_BANNER_FORM,
        actionTypes.SUBMIT_UPDATE_BANNER_SUCCESS,
        actionTypes.SUBMIT_UPDATE_BANNER_ERROR,
      ],
      payload: {
        id,
        name,
        color,
        content,
        positionIds,
        status,
      },
      callbacks: {
        onSuccess: ({ response, dispatch }) => {
          AlertManager.success(getMessage(messageCodes.CONDOR_SAVED));
          callback(formData, response);
          dispatch(getBannersPositionsList());
        },
        onError: ({ error }) => {
          AlertManager.error(error.getMessage());
        },
      },
    },
  ));
};

/**
 * Delete banner by bannerId
 * @param {String} bannerId
 *
 * @returns {Function}
 *
 * */
export const deleteBanner = (bannerId) => (dispatch, getState) => {
  const bannersRepository = getState().container.get(BannersRepository);

  return dispatch(createAsyncRequest(
    {
      method: bannersRepository.delete,
      types: [
        actionTypes.SUBMIT_DELETE_BANNER_FORM,
        actionTypes.SUBMIT_DELETE_BANNER_SUCCESS,
        actionTypes.SUBMIT_DELETE_BANNER_ERROR,
      ],
      payload: {
        id: bannerId,
      },
      callbacks: {
        onSuccess: ({ dispatch }) => {
          AlertManager.success(getMessage(messageCodes.CONDOR_SAVED));
          dispatch(createAction(actionTypes.DELETE_BANNER_FROM_STORE, {
            id: bannerId,
          }));
          dispatch(getBannersPositionsList());
        },
      },
    },
  ));
};
