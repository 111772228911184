import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDIContext } from '@/Framework/DI/DIContext';
import { useCustomModalContext } from '@/condor/application/DealRoadshow/CustomModalContext';
import RoadshowRepository from '@/dealroadshow/infrastructure/repository/RoadshowRepository';
import { useFieldArray } from '@/Framework/UI/Organisms/FinalForm';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { RELATED_ROADSHOWS_FIELD_NAME } from '../constants';
import { IRelatedRoadshow } from '../../interfaces';

export const useDelete = () => {
  const {
    getCustomModal,
  } = useCustomModalContext();
  const [deleteModalRow, setDeleteModalRow] = useState<IRelatedRoadshow | null>(null);
  const { fields } = useFieldArray<IRelatedRoadshow>(RELATED_ROADSHOWS_FIELD_NAME);
  const { roadshowId } = useParams();
  const { container } = useDIContext();
  const roadshowRepository = container.get<RoadshowRepository>(RoadshowRepository);

  const showDeleteModal = useCallback((row: IRelatedRoadshow) => {
    setDeleteModalRow(row);
  }, []);

  const hideDeleteModal = useCallback(() => {
    setDeleteModalRow(null);
  }, []);

  const applyDeleteModal = useCallback(async () => {
    if (deleteModalRow?.isNew) {
      fields.remove(deleteModalRow.rowIndex);
    } else {
      try {
        await roadshowRepository.toggleDeleteRelatedRoadshow({
          parentId: roadshowId,
          childId: deleteModalRow.id,
          type: 'delete',
          value: !deleteModalRow.deletedAt,
        });
        getCustomModal();
      } catch (err) {
        AlertManager.error(getErrorMessage(err));
      }
    }
    hideDeleteModal();
  }, [deleteModalRow]);

  return {
    deleteModalRow,
    showDeleteModal,
    hideDeleteModal,
    applyDeleteModal,
  };
};
