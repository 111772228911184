import { useMemo, useState } from 'react';

import { IStatActionModal } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/IStat';
import { useDIContext } from '@/Framework/DI/DIContext';
import CreditFlowResearchRepository
  from '@/condor/infrastructure/repository/creditFlowResearch/CreditFlowResearchRepository';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import {
  useStatProfileContext,
} from '@/condor/application/Finsight/CreditFlowResearch/Stats/StatProfile/StatProfileContext';

const useUnpublishModal = () => {
  const { container } = useDIContext();
  const creditFlowResearchRepository = container.get<CreditFlowResearchRepository>(CreditFlowResearchRepository);
  const { initialFormData, initialStat } = useStatProfileContext();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isUnpublishing, setIsUnpublishing] = useState(false);
  const stat: IStatActionModal = useMemo(() => ({
    name: initialStat.name,
    author: initialStat?.author ?? null,
    categories: initialFormData.categories.map((category) => `${ category.regionName } ${ category.label }`),
  }), [initialFormData]);

  const onUnpublish = async (): Promise<Boolean> => {
    try {
      setIsUnpublishing(true);
      await creditFlowResearchRepository.unpublishStatById(initialStat.id);
      AlertManager.success('Stat has been successfully unpublished');
      setIsUnpublishing(false);
      return true;
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
      setIsUnpublishing(false);
      return false;
    }
  };

  return { isOpenModal, setIsOpenModal, stat, onUnpublish, isUnpublishing };
};

export default useUnpublishModal;
