import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import { useDIContext } from '@/Framework/DI/DIContext';
import ImportRepository from '@/finsight/infrastructure/repository/ImportRepository';
import { useEffect, useRef, useState } from 'react';
import { IFile, IFiles, TUpload } from '../Articles/ArticleProfile/interfaces';
import getFileExtension from '@/Framework/dataHelpers/string/getFileExtension';
import * as uploadTypes from '@/Framework/UI/Organisms/Upload/uploadTypes';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { IUploadProcessing, ProcessingEvent, UploadEvent } from '@dealroadshow/file-uploader';
import { getMessageByErrorCode } from '@/Framework/Message/Mapper/getMessage';
import moveElementInArray from '@/Framework/dataHelpers/array/moveElementInArray';
import { useField } from '@/Framework/UI/Organisms/FinalForm';
import { IAttachment } from '@/condor/domain/vo/Finsight/CreditFlowResearch/IAttachment';
import { useUploadInputsStatusContext } from '@/Framework/UI/Organisms/Upload/components/UploadInputsStatusContext';

const FIELD_ATTACHMENTS_NAME = 'attachments';

const useUploadAttachments = (onUploadStart: () => void = noop) => {
  const { container } = useDIContext();
  const importRepository = container.get<ImportRepository>(ImportRepository);
  const { input: { value: attachments } } = useField<IAttachment[]>(FIELD_ATTACHMENTS_NAME);
  const { setIsUploaded } = useUploadInputsStatusContext();

  const [, updateState] = useState({});
  const uploadState = useRef<IFiles>({
    files: {},
    sort: [],
  });

  const isAllAttachmentsUploaded = Object.values(uploadState.current.files).every((file) => file.uploaded);

  const getCurrentFile = (uuid: string): IFile => uploadState.current.files[uuid];

  const setUploadState = (state: IFiles): void => {
    uploadState.current = state;
    updateState({});
  };

  useEffect(() => {
    setIsUploaded('attachments', isAllAttachmentsUploaded);
  }, [isAllAttachmentsUploaded]);

  const initDocumentsInEditForm = (documents: IAttachment[]): void => {
    const state: IFiles = {
      files: {},
      sort: [],
    };

    documents.forEach((document) => {
      state.files[document.uuid] = {
        initialFile: document,
        process: {
          progress: 100,
          status: uploadTypes.UPLOAD_DONE,
          uuid: document.uuid,
        },
        uploadFile: null,
        callback: null,
        name: document.name,
        originalName: document.name,
        extension: `.${ getFileExtension(document.name) }`,
        url: document.url,
        uploaded: true,
      };

      state.sort.push({ uuid: document.uuid });
    });

    setUploadState(state);
  };

  const uploadStart = (payload: {
    uploadFile: File,
    callback: TUpload,
    uuid: string,
  }): void => {
    setUploadState({
      files: {
        ...uploadState.current.files,
        [payload.uuid]: {
          initialFile: null,
          process: {
            progress: 0,
            status: uploadTypes.UPLOAD_START,
            uuid: payload.uuid,
          },
          uploadFile: payload.uploadFile,
          callback: payload.callback,
          name: payload.uploadFile.name,
          originalName: payload.uploadFile.name,
          extension: `.${ getFileExtension(payload.uploadFile.name) }`,
          url: '',
          uploaded: false,
        },
      },
      sort: [
        ...uploadState.current.sort,
        { uuid: payload.uuid },
      ],
    });
  };

  const uploadChunkSuccess = (payload: {
    uuid: string,
    progress: string,
  }): void => {
    const currentFile = getCurrentFile(payload.uuid);

    if (currentFile) {
      setUploadState({
        ...uploadState.current,
        files: {
          ...uploadState.current.files,
          [payload.uuid]: {
            ...currentFile,
            process: {
              ...currentFile.process,
              progress: parseInt(payload.progress),
              status: uploadTypes.UPLOAD_CHUNK_SUCCESS,
            },
          },
        },
      });
    }
  };

  const uploadError = (
    payload: {
      uuid: string,
      error: {
        message: string,
      },
    },
    errorCallback: (uuid: string, index: number) => void,
  ): void => {
    const currentFile = getCurrentFile(payload.uuid);

    if (currentFile) {
      setUploadState({
        ...uploadState.current,
        files: {
          ...uploadState.current.files,
          [payload.uuid]: {
            ...currentFile,
            process: {
              ...currentFile.process,
              status: uploadTypes.UPLOAD_ERROR,
            },
            uploaded: false,
          },
        },
      });
    }

    const index = uploadState.current.sort.findIndex((item) => (item.uuid === payload.uuid));
    if (index > -1 && payload.error.message && errorCallback) {
      AlertManager.error(payload.error.message);
      errorCallback(payload.uuid, index);
    }
  };

  const uploadSuccess = (payload: {
    uuid: string,
  }): void => {
    const currentFile = getCurrentFile(payload.uuid);

    if (currentFile && currentFile.process.status !== uploadTypes.UPLOAD_DONE) {
      setUploadState({
        ...uploadState.current,
        files: {
          ...uploadState.current.files,
          [payload.uuid]: {
            ...currentFile,
            process: {
              ...currentFile.process,
              status: uploadTypes.UPLOAD_SUCCESS,
            },
            uploaded: true,
          },
        },
      });
    }
  };

  const uploadProcessing = (payload: {
    uuid: string,
  }): void => {
    const currentFile = getCurrentFile(payload.uuid);

    if (currentFile && currentFile.process.status !== uploadTypes.UPLOAD_DONE) {
      setUploadState({
        ...uploadState.current,
        files: {
          ...uploadState.current.files,
          [payload.uuid]: {
            ...currentFile,
            process: {
              ...currentFile.process,
              progress: 0,
              status: uploadTypes.UPLOAD_PROCESSING_START,
            },
          },
        },
      });
    }
  };

  const uploadDone = (payload: {
    uuid: string,
    data: string[],
  }): void => {
    const currentFile = getCurrentFile(payload.uuid);

    if (currentFile) {
      setUploadState({
        ...uploadState.current,
        files: {
          ...uploadState.current.files,
          [payload.uuid]: {
            ...currentFile,
            process: {
              ...currentFile.process,
              progress: 100,
              status: uploadTypes.UPLOAD_DONE,
            },
            url: `/${ payload.data[0] }`,
            uploaded: true,
          },
        },
      });
    }
  };

  const uploadCancelSuccess = (payload: {
    uuid: string,
  }): void => {
    if (getCurrentFile(payload.uuid)) {
      const files = { ...uploadState.current.files };
      delete files[payload.uuid];

      setUploadState({
        files,
        sort: uploadState.current.sort.filter((item) => item.uuid !== payload.uuid),
      });
    }
  };

  const uploadDocuments = async (
    files: File[],
    errorCallback: (uuid: string, index: number) => void,
  ): Promise<void> => {
    onUploadStart();
    await Promise.all(files.sort((prev, next) => prev.size - next.size).map(async (file) => {
      try {
        const upload: IUploadProcessing = await importRepository.attachmentUpload(file);

        uploadStart({
          uploadFile: file,
          callback: upload,
          uuid: upload.getUuid(),
        });

        upload
          .on(UploadEvent.uploadChunkDone, (data) => uploadChunkSuccess(data))
          .on(UploadEvent.error, (data) => uploadError(data, errorCallback))
          .on(UploadEvent.uploadDone, (data) => uploadSuccess(data))
          .on(ProcessingEvent.processing, (data) => uploadProcessing(data))
          .on(ProcessingEvent.processingDone, (data) => uploadDone(data))
          .on(UploadEvent.cancel, (data) => uploadCancelSuccess(data));
      } catch (e) {
        AlertManager.error(getMessageByErrorCode(e.code));
      }
    }));
  };

  const cancelDocumentUpload = (uuid: string): void => {
    const currentFile = getCurrentFile(uuid);

    if (currentFile && currentFile.callback && typeof currentFile.callback.cancel === 'function') {
      currentFile.callback.cancel();
    } else if (currentFile) {
      uploadCancelSuccess({
        uuid: currentFile.process.uuid,
      });
    }

    uploadCancelSuccess({ uuid });
  };

  const changeDocumentsOrder = (oldIndex: number, newIndex: number): void => setUploadState({
    ...uploadState.current,
    sort: moveElementInArray(uploadState.current.sort.slice(), oldIndex, newIndex),
  });

  useEffect(() => {
    const attachmentsUuids = (attachments || []).map((attachment) => attachment.uuid);
    const uploadStateUuids = uploadState.current.sort.map((item) => item.uuid);

    if (!isEqual(attachmentsUuids, uploadStateUuids)) {
      initDocumentsInEditForm(attachments || []);
    }
  }, [attachments]);

  return {
    files: uploadState.current,
    initDocumentsInEditForm,
    uploadDocuments,
    cancelDocumentUpload,
    changeDocumentsOrder,
  };
};

export default useUploadAttachments;
