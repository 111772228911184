import { useEffect, useMemo, useState } from 'react';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import { useContactProfileContext } from '../../ContactProfileContext';
import { usePermissions } from '@/users/application/usePermissions';
import { PermissionType } from '../constants';
import { formatPermissions } from '../helpers';
import { IValues } from '@/condor/ui/components/AccountsAndContacts/Contacts/ContactProfile/UserPermissions/interfaces';

export const useUserPermissions = () => {
  const { user: { id: contactId } } = useContactProfileContext();
  const { currentUser: { id: currentUserId } } = useSessionContext();
  const {
    currentUserPermissions,
    contactPermissions,
    modules,
    group,
    isFetching,

    getCurrentUserPermissions,
    getPermissions,
    savePermissions,
  } = usePermissions();

  const [activeTab, setActiveTab] = useState<string>(null);

  const updateOwnContact = contactId === currentUserId;
  const hasPermissionToUpdate = useMemo(() => (
      currentUserPermissions?.find((permission) => permission.moduleKey === PermissionType.UserPermissions)
    ), [currentUserPermissions],
  );

  useEffect(() => {
    getCurrentUserPermissions();
    getPermissions(contactId);
  }, []);

  useEffect(() => {
    if (modules) {
      setActiveTab(Object.keys(modules)[0]);
    }
  }, [modules]);

  const onSave = async (values: IValues) => {
    await savePermissions(contactId, formatPermissions(values));
  };

  return {
    currentUserPermissions,
    isFetching,
    contactPermissions,
    modules,
    group,

    updateOwnContact,
    hasPermissionToUpdate,

    activeTab,
    setActiveTab,
    onSave,
  };
};
