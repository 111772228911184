import { useState } from 'react';
import TwoFactorRepository from '@/users/infrastructure/repository/TwoFactorRepository';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { useDIContext } from '@/Framework/DI/DIContext';

export const useTwoFactor = () => {
  const { container } = useDIContext();
  const twoFactorRepository = container.get<TwoFactorRepository>(TwoFactorRepository);

  const [twoFactorCode, setTwoFactorCode] = useState<number>(null);

  const generateTwoFactorCode = async (userId: number) => {
    try {
      const res = await twoFactorRepository.generateTwoFactorCode(userId);
      setTwoFactorCode(res.code);
    } catch (e) {
      AlertManager.error(getErrorMessage(e));
    }
  };

  return {
    twoFactorCode,
    generateTwoFactorCode,
  };
};
