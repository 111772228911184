import Dispatcher from '@dealroadshow/json-rpc-dispatcher';
import createAction from '@/Framework/State/Redux/createAction';
import BondsRepository from '@/finsight/infrastructure/repository/BondsRepository';
import * as actionTypes from './actionTypes';
import inventorySelectors from './selectors';
import metadataSelectors from '@/finsight/application/actions/bonds/metadata/selectors';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';

/**
 * @param {String} sortBy
 * @param {String} sortOrder
 * @return {Object}
 */
export const sort = (sortBy, sortOrder) => createAction(
  actionTypes.CONDOR_INVENTORY_SORT_CHANGE, { sortBy, sortOrder },
);

/**
 * @param {Number} page
 * @return {Object}
 */
export const paginate = (page) => createAction(actionTypes.CONDOR_INVENTORY_PAGE_CHANGE, page);

/**
 * @param {Number} perPage
 * @return {Object}
 */
export const itemsPerPage = (perPage) => createAction(actionTypes.CONDOR_INVENTORY_PER_PAGE_CHANGE, perPage);

/**
 * Reset state except some fields (perPage) when asset class has changed
 * @return {{type: string}}
 */
export const partialStateReset = () => createAction(actionTypes.CONDOR_INVENTORY_RESET_PARTIAL);

/**
 * @return {Object}
 */
export const resetState = () => createAction(actionTypes.CONDOR_INVENTORY_RESET);

/**
 * @return {Function}
 */
export const fetch = () => async (dispatch, getState) => {
  const state = getState();
  let payload = {
    filter: {
      includedAssetClassId: metadataSelectors.getActiveAssetClassId(state),
    },
    sortBy: inventorySelectors.getSortBy(state),
    sortOrder: inventorySelectors.getSortOrder(state),
    page: inventorySelectors.getPage(state),
    perPage: inventorySelectors.getPerPage(state),
    search: metadataSelectors.getSearch(state),
  };

  dispatch(createAction(actionTypes.GET_CONDOR_INVENTORY_LIST));
  try {
    const bondsRepository = getState().container.get(BondsRepository);
    const response = await bondsRepository.getCondorInventoryList(payload);
    dispatch(createAction(actionTypes.GET_CONDOR_INVENTORY_LIST_SUCCESS, response));
  } catch (e) {
    dispatch(createAction(actionTypes.GET_CONDOR_INVENTORY_LIST_FAILURE, e));
    if (Dispatcher.isAbortError(e)) {
      return;
    }
    AlertManager.error(getErrorMessage(e));
    throw e;
  }
};
