import cn from 'classnames';

import Input from '@dealroadshow/uikit/core/components/Input';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import Modal from '@dealroadshow/uikit/core/components/Modal';
import IconRefresh from '@dealroadshow/uikit/core/components/Icon/IconRefresh';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { useAdvanced } from './useAdvanced';

import headerStyles from '@dealroadshow/uikit/core/styles/headers.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';
import styles from './advanced.scss';

const Advanced = () => {
  const {
    isVisibleModal,
    setIsVisibleModal,
    apiAccessKey,
    isLoading,
    setApiAccessKey,
  } = useAdvanced();

  return (
    <>
      <div className={ cn(headerStyles.isH3, spacesStyles.mbl) }>API Controls</div>
      <div>
        <Spinner overlay isVisible={ isLoading } />
        <Input
          isNarrow
          isReadOnly
          label="Api access key"
          // @ts-ignore
          input={ {
            name: 'apiAccessKeyInput',
          } }
          type="text"
          name="apiAccessKeyInput"
          dataTest="apiAccessKeyInput"
          placeholder="No api access key assigned to user"
          value={ apiAccessKey }
          // @ts-ignore
          isClearable="false"
          className={ styles.apiKeyInput }
          appendComponent={ (
            <Tooltip
              content="Generate new key"
              containerClassName={ styles.tooltipContainer }
            >
              <div
                className={ styles.refreshButton }
                onClick={ () => setIsVisibleModal(true) }
              >
                <IconRefresh className={ styles.refreshIcon } />
              </div>
            </Tooltip>
          ) }
        />
      </div>
      <Modal
        isVisible={ isVisibleModal }
        title="Are you sure?"
        dataTest="getKeyApiModal"
        onCloseClicked={ () => setIsVisibleModal(false) }
        footer={ (
          <>
            <Button
              onClick={ setApiAccessKey }
              variant={ ButtonVariantType.action }
              title="Generate"
              dataTest="getKeyApiModalSubmitButton"
              className={ styles.submitButton }
            />
            <Button
              onClick={ () => setIsVisibleModal(false) }
              variant={ ButtonVariantType.link }
              title="Cancel"
              dataTest="getKeyApiModalCancelButton"
            />
          </>
        ) }
      >
        Are you sure you want to generate a new API Access Key?
        Once the new key is generated, the prior key will no longer work.
      </Modal>
    </>
  );
};

export default Advanced;
