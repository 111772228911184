import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { CheckboxInput } from '@dealroadshow/uikit/core/components/Checkbox';
import { Field, FormSpy } from '@/Framework/UI/Organisms/FinalForm';
import Input from '@dealroadshow/uikit/core/components/Input';
import Textarea from '@dealroadshow/uikit/core/components/Textarea';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { useCustomModalContext } from '@/condor/application/DealRoadshow/CustomModalContext';
import RelatedRoadshows from '@/condor/ui/components/DealRoadshows/Roadshow/modules/CustomModal/RelatedRoadshows';
import { ADD_ROASDSHOW_FIELD_NAME } from './RelatedRoadshows/constants';

import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';
import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';
import styles from '@/condor/ui/components/DealRoadshows/Roadshow/modules/CustomModal/styles.scss';

const CustomModalFields = () => {
  const { isFetching } = useCustomModalContext();

  return (
    <>
      <div className={ cn(cardStyles.cardContainer, styles.formCard) }>
        <h3>Custom Modal</h3>
        <Field
          name="enableCustomModal"
          type="checkbox"
          render={ (props) => {
            const {
              input: { checked: enableCustomModal },
            } = props;

            return (
              <>
                <CheckboxInput
                  dataTest="enableCustomModal"
                  label="Enable Custom Modal"
                  className={ cn(styles.field, styles.enableModalCheckbox) }
                  { ...props }
                />
                { enableCustomModal && (
                  <>
                    <Field
                      label="Header Text"
                      name="title"
                      component={ Input }
                      className={ styles.fieldCustomWidth }
                      asterisk
                      dataTest="customModalHeaderTextInput"
                    />
                    <Field
                      label="Body Text"
                      name="text"
                      parse={ (value: string) => (value === '' ? '' : value) }
                      isNarrow
                      component={ Textarea }
                      dataTest="condorCustomModalTextarea"
                      className={ cn(styles.field, styles.fieldCustomWidth) }
                      textareaClassName={ styles.bodyText }
                    />
                    <RelatedRoadshows />
                    <div className={ styles.checkboxGroup }>
                      <div className={ formStyles.formLabel }>Enable Footer Buttons</div>
                      <Field
                        name="enableIAgreeButton"
                        dataTest="enableIAgreeButton"
                        label="I agree"
                        component={ CheckboxInput }
                        type="checkbox"
                      />
                      <Field
                        name="enableContinueButton"
                        dataTest="enableContinueButton"
                        label="Continue"
                        component={ CheckboxInput }
                        type="checkbox"
                      />
                      <Field
                        name="enableCancelButton"
                        dataTest="enableCancelButton"
                        label="Cancel"
                        component={ CheckboxInput }
                        type="checkbox"
                        className={ styles.field }
                      />
                      <div className={ formStyles.formLabel }>
                        Note: If a footer button is not selected the user cannot move past the modal without selecting
                        a linked roadshow.
                      </div>
                    </div>
                  </>
                ) }
              </>
            );
          } }
        />
        <Spinner isVisible={ isFetching } overlay />
      </div>
      <FormSpy subscription={ { errors: true, pristine: true } }>
        { ({ errors, form }) => {
          const formState = form.getState();
          const formValues = { ...formState.values };
          const initialValues = { ...formState.initialValues };

          delete formValues[ADD_ROASDSHOW_FIELD_NAME];
          delete initialValues?.[ADD_ROASDSHOW_FIELD_NAME];

          const relevantPristine = JSON.stringify(formValues) === JSON.stringify(initialValues);
          const isDisable = isFetching || relevantPristine || !isEmpty(errors);

          return (
            <>
              <Button
                variant={ ButtonVariantType.action }
                type="submit"
                disabled={ isDisable }
                title="Save"
                dataTest="saveButton"
              />
              <Button
                variant={ ButtonVariantType.text }
                disabled={ isDisable }
                onClick={ form.reset }
                title="Cancel"
                dataTest="cancelButton"
              />
            </>
          );
        } }
      </FormSpy>
    </>
  );
};

export default CustomModalFields;
