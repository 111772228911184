import { useDIContext } from '@/Framework/DI/DIContext';
import UserRepository from '@/users/infrastructure/repository/UserRepository';
import { getErrorMessage, getMessage } from '@/Framework/Message/Mapper/getMessage';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { messageCodes } from '@/Framework/Message/messages';
import { IUser } from '@/users/domain/vo/User';

export const useRegeneratePassword = () => {
  const { container } = useDIContext();
  const userRepository = container.get<UserRepository>(UserRepository);

  const regeneratePassword = async (user: IUser) => {
    try {
      const {
        id,
        email,
        firstName = '',
        lastName = '',
      } = user;

      await userRepository.regeneratePassword(id);

      const userNameWithEmail = (firstName || lastName) ? `${ firstName } ${ lastName } (${ email })` : email;
      AlertManager.success(getMessage(messageCodes.USER_INVALIDATE_PASSWORD_SUCCESS, { userNameWithEmail }));
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
    }
  };

  return {
    regeneratePassword,
  };
};
