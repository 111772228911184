import { useEffect, useState } from 'react';
import { useCheckBoxArrayContext } from '@/Framework/UI/Molecules/CheckBoxArray/CheckboxArrayContext';
import { useUserPermissionsContext } from './UserPermissionsContext';
import { PermissionType } from './constants';

interface IPermissionCheckBoxProps {
  moduleKey: string,
}

const PermissionCheckBox = ({ moduleKey }: IPermissionCheckBoxProps) => {
  const { CheckboxArray, values, setValues } = useCheckBoxArrayContext();
  const {
    hasPermissionToUpdate,
    updateOwnContact,
    activeTab,
  } = useUserPermissionsContext();

  const [isAccountDisabled, setAccountDisabled] = useState(false);

  const isDisabled = !hasPermissionToUpdate
    || (updateOwnContact && moduleKey === PermissionType.UserPermissions)
    || (isAccountDisabled && moduleKey === PermissionType.Accounts);

  useEffect(() => {
    const currentValues = values[activeTab];
    const isSelectedContact = currentValues.includes(PermissionType.Contacts);
    const isSelectedAccount = currentValues.includes(PermissionType.Accounts);

    if (moduleKey === PermissionType.Accounts) {
      setAccountDisabled(isSelectedContact);
    } else if (moduleKey === PermissionType.Contacts && isSelectedContact && !isSelectedAccount) {
      setValues((prev) => ({
        ...prev,
        [activeTab]: [...currentValues, PermissionType.Accounts],
      }));
    }
  }, [values, activeTab]);

  const change = (value) => {
    setValues((prev) => ({
      ...prev,
      [activeTab]: value[activeTab],
    }));
  };

  return (
    <CheckboxArray
      key={ moduleKey }
      dataTest={ moduleKey }
      checkboxGroupName={ activeTab }
      disabled={ isDisabled }
      allValues={ values }
      value={ moduleKey }
      change={ change }
    />
  );
};

export default PermissionCheckBox;
