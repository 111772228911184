import { DataTable } from '@dealroadshow/uikit/core/components/Table/DataTable';
import Input from '@dealroadshow/uikit/core/components/Input';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import RelatedRoadshowsContextProvider, { useRelatedRoadshowsContext } from './RelatedRoadshowsContext';
import { Field, useFieldArray, useFieldValue, useField } from '@/Framework/UI/Organisms/FinalForm';
import { EditModal } from './EditModal';
import { DeleteModal } from './DeleteModal';
import { columns } from './columns';
import { isEnterKey } from '@/Framework/browser/checkPressedKey';
import { ADD_ROASDSHOW_FIELD_NAME, RELATED_ROADSHOWS_FIELD_NAME } from './constants';
import { IRelatedRoadshow } from '../interfaces';
import styles from './styles.scss';

const RelatedRoadshows = () => {
  const { getRoadshowForCustomModal, editModalRow, deleteModalRow } = useRelatedRoadshowsContext();
  const {
    input: {
      value: addRoadshowInputValue,
    },
    meta: {
      valid,
      error,
    },
  } = useField<string>(ADD_ROASDSHOW_FIELD_NAME);
  const relatedRoadshows = useFieldValue<IRelatedRoadshow[]>(RELATED_ROADSHOWS_FIELD_NAME);
  const { fields } = useFieldArray<IRelatedRoadshow>(RELATED_ROADSHOWS_FIELD_NAME);

  const uploadOrder = (oldIndex: number, newIndex: number) => fields.move(oldIndex, newIndex);

  const disableAddButton = (
    !addRoadshowInputValue ||
    (!valid && addRoadshowInputValue) ||
    error
  );

  return (
    <div>
      <h3>Roadshows</h3>
      <DataTable
        isDraggable
        dataTest="relatedRoadshowsDataTable"
        columns={ columns }
        data={ relatedRoadshows }
        orderCallback={ uploadOrder }
        containerClassName={ styles.table }
        rowKey={ (row) => row.id }
      />
      <div className={ styles.roadshowInputWrapper }>
        <Field
          type="text"
          name={ ADD_ROASDSHOW_FIELD_NAME }
          placeholder="Enter Roadshow ID"
          dataTest="relatedRoadshowsInput"
          component={ Input }
          onKeyPress={ (event) => {
            if (!disableAddButton && isEnterKey(event)) {
              getRoadshowForCustomModal();
            }
          } }
          isNarrow
          className={ styles.roadshowInput }
        />
        <Button
          variant={ ButtonVariantType.action }
          onClick={ getRoadshowForCustomModal }
          title="Add Roadshow"
          dataTest="addRoadshowButton"
          className={ styles.roadshowSubmit }
          disabled={ disableAddButton }
        />
      </div>

      { !!editModalRow && <EditModal /> }
      { !!deleteModalRow && <DeleteModal /> }
    </div>
  );
};

export default () => (
  <RelatedRoadshowsContextProvider>
    <RelatedRoadshows />
  </RelatedRoadshowsContextProvider>
);
