import CustomModalContextProvider, { useCustomModalContext } from '@/condor/application/DealRoadshow/CustomModalContext';
import CustomModalFields from './CustomModalFields';
import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import validate from './validator';

const CustomModal = () => {
  const {
    saveCustomModal,
    customModalData: initialValues,
  } = useCustomModalContext();

  return (
    <FinalForm
      name="customModalForm"
      onSubmit={ saveCustomModal }
      initialValues={ initialValues }
      dataTest="customModalForm"
      render={ CustomModalFields }
      validate={ validate }
      subscription={ {} }
    />
  );
};

export default () => (
  <CustomModalContextProvider>
    <CustomModal />
  </CustomModalContextProvider>
);
