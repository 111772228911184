import RoadshowType from '@/dealroadshow/domain/RoadshowType';
import { IAudioVideoFormData, IPresentationMediaAudio, IPresentationMediaVideo } from '../interfaces';

const isCondorFilesUploading = (
  presentationMediaVideo: IPresentationMediaVideo,
  presentationMediaAudio: IPresentationMediaAudio,
) => {
  if (
    (!presentationMediaVideo.canSave && presentationMediaVideo.process.uuid !== '') ||
    (!presentationMediaAudio.canSave && presentationMediaAudio.process.uuid !== '')
  ) {
    return true;
  }
  return false;
};

const needsAudioForSlidesAndAudio = (
  formData: IAudioVideoFormData,
  presentationMediaAudio: IPresentationMediaAudio,
) => {
  if (RoadshowType.isSlidesAndAudio(formData.type) && !presentationMediaAudio.canSave) {
    return true;
  }
  return false;
};

const needsVideoForSlidesAndVideo = (
  formData: IAudioVideoFormData,
  presentationMediaVideo: IPresentationMediaVideo,
) => {
  if (
    (RoadshowType.isSlidesAndVideo(formData.type) || RoadshowType.isVideoOnly(formData.type)) &&
    !presentationMediaVideo.canSave
  ) {
    return true;
  }
  return false;
};

export { isCondorFilesUploading, needsAudioForSlidesAndAudio, needsVideoForSlidesAndVideo };
