export const modulesNameMapping = {
  condor: 'Condor',
  dealRoadshow: 'Deal Roadshow, Allocate & OrderBook',
};

export enum PermissionType {
  UserPermissions = 'user_permissions',
  Accounts = 'accounts',
  Contacts = 'contacts',
}
