import { ICuePoint, IVideoOnlyCuePoint } from '@/dealroadshow/domain/vo/roadshow/ICuePoint';

const reindexCuePoints = ({
  cuePoints,
  videoOnlyCuePoints,
}: {
  cuePoints: ICuePoint[],
  videoOnlyCuePoints: IVideoOnlyCuePoint[],
}) => {
  const videoOnlyCuePointsUpdated = videoOnlyCuePoints.map((cuePoint, index) => ({
    ...cuePoint,
    number: index + 1,
  }));

  const cuePointsUpdated = cuePoints.map((cuePoint, index) => ({
    ...cuePoint,
    slideNumber: index + 1,
  }));
  return { videoOnlyCuePoints: videoOnlyCuePointsUpdated, cuePoints: cuePointsUpdated };
};

export default reindexCuePoints;
