import UpdatedAtCell from './UpdatedAtCell';
import SectorIndustryLabel from '@/finsight/ui/common/components/CreditFlowResearch/SectorIndustryLabel';
import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';
import finsightUrl from '@/finsight/Router/urlConstants';
import { Link } from '@/Framework/Router/Next/Link';

import styles from './styles.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';

const getArticleUrl = (viewId: string) => (finsightWebUrl.getUrl(`${ finsightUrl.CREDIT_FLOW_ARTICLE }-${ viewId }`));

export const sectorIndustryCellCallback = ({ cellData, row }) => (
  <SectorIndustryLabel
    sectorOrIndustry={ cellData }
    subsectorOrSubindustry={ row.subsectorOrSubindustry }
    className={ spacesStyles.mrn }
  />
);

export const headlineCellCallback = ({ cellData, row }) => {
  if (!row.isActive) {
    return (
      <span className={ styles.headline }>{ cellData || '-' }</span>
    );
  }
  return (
    <Link
      to={ getArticleUrl(row.viewId) }
      className={ styles.headline }
      target="_blank"
    >
      { cellData || '-' }
    </Link>
  );
};

export const updatedAtCellCallback = (props) => (
  <UpdatedAtCell { ...props } />
);
