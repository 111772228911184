import config from '@/Framework/config';

export const options = [
  {
    label: config.tenant.tenant17g5.name,
    value: config.tenant.tenant17g5.code,
    callbackUrl: `${ config.protocol }//${ config.tenant.dmPortal.hostname }/17g5`,
  },
  {
    label: config.tenant.tenantDealvdr.name,
    value: config.tenant.tenantDealvdr.code,
    callbackUrl: `${ config.protocol }//${ config.tenant.dmPortal.hostname }/dealvdr`,
  },
  {
    label: config.tenant.tenantInvestorSet.name,
    value: config.tenant.tenantInvestorSet.code,
    callbackUrl: `${ config.protocol }//${ config.tenant.dmPortal.hostname }/investorset`,
  },
  {
    label: config.tenant.tenantVeriSend.name,
    value: config.tenant.tenantVeriSend.code,
    callbackUrl: `${ config.protocol }//${ config.tenant.dmPortal.hostname }/verisend`,
  },
  {
    label: config.tenant.dealroadshow.name,
    value: config.tenant.dealroadshow.code,
    callbackUrl: `${ config.protocol }//${ config.tenant.dmPortal.hostname }/roadshow`,
  },
  {
    label: config.tenant.finsight.name,
    value: config.tenant.finsight.code,
    callbackUrl: `${ config.protocol }//${ config.tenant.finsight.hostname }`,
  },
];
