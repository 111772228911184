import validate from './validators/dealAudioVideoValidators';
import AudioVideoFormContextProvider, { useAudioVideoFormContext } from './AudioVideoFormContext';
import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import AudioVideoFormFields from './AudioVideoFormFields';

const AudioVideoForm = () => {
  const { onSubmit, initialValues } = useAudioVideoFormContext();

  return (
    <FinalForm
      name="audioVideoForm"
      dataTest="audioVideoForm"
      onSubmit={ onSubmit }
      render={ () => <AudioVideoFormFields /> }
      validate={ validate }
      initialValues={ initialValues }
      subscription={ {} }
    />
  );
};

export default () => (
  <AudioVideoFormContextProvider>
    <AudioVideoForm />
  </AudioVideoFormContextProvider>
);
