import { useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';
import useCompany from '@/users/application/useCompany';

const COMPANIES_LIMIT = 50;
const payload = { limit: COMPANIES_LIMIT, verified: true };

export const useAccount = () => {
  const {
    getCompanies,
    collection: companies,
  } = useCompany();

  useEffect(() => {
    getCompanies(payload);
  }, []);

  const getCompanyByQuery = useCallback(debounce((query) => {
    getCompanies({ ...payload, query });
  }, 400), []);

  return {
    getCompanyByQuery,
    companies,
  };
};
