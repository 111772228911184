import uuidValidate from 'uuid-validate';
import { groupValidators } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { validateMaxLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMaxLength';
import { validateMinLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMinLength';
import { validateRequiredFields } from '@/Framework/UI/Organisms/FinalForm/validators/validateRequiredFields';
import { ADD_ROASDSHOW_FIELD_NAME, RELATED_ROADSHOWS_FIELD_NAME } from './RelatedRoadshows/constants';
import { ICustomModal, IRelatedRoadshow } from './interfaces';
import { IParams } from '@/Framework/UI/Organisms/FinalForm/validators/validateRequiredField';
import { IValidationError } from '@/Framework/UI/Organisms/FinalForm/validators/interfaces/ValidationError';

const validateUUID = (params: IParams): IValidationError => {
  if (params.value && !uuidValidate((params.value as string).trim())) {
    return { [params.fieldName]: `${ params.value } is invalid roadshow id` };
  }
  return null;
};

const validateDuplicate = (params: IParams, array: IRelatedRoadshow[]): IValidationError => {
  if (!params.value || !array?.length) return null;

  const exist = array.filter((field) => field.id === (params.value as string).trim());
  if (exist.length > 0) {
    return { [params.fieldName]: `${ params.value } is already in Related Roadshow table` };
  }
  return null;
};

export default (values: ICustomModal) => groupValidators(
  validateRequiredFields([{ fieldName: 'title', fieldCaption: 'Header Text' }], { title: values.title }),
  validateMinLength({ fieldName: 'title', fieldCaption: 'Deal Name:', value: values.title, length: 3 }),
  validateMaxLength({ fieldName: 'title', fieldCaption: 'Deal Name:', value: values.title, length: 255 }),
  validateUUID({ fieldName: ADD_ROASDSHOW_FIELD_NAME, value: values[ADD_ROASDSHOW_FIELD_NAME] }),
  validateDuplicate(
    { fieldName: ADD_ROASDSHOW_FIELD_NAME, value: values[ADD_ROASDSHOW_FIELD_NAME] },
    values[RELATED_ROADSHOWS_FIELD_NAME],
  ),
);
