import React, { createContext, useContext } from 'react';
import useAudioVideoForm from './useAudioVideoForm';

type TAudioVideoForm = ReturnType<typeof useAudioVideoForm>;

export const AudioVideoFormContext = createContext<TAudioVideoForm>(null);

export const useAudioVideoFormContext = () => {
  const context = useContext(AudioVideoFormContext);
  if (!context) {
    throw new Error('useAudioVideoFormContext must be used within a AudioVideoFormContextProvider');
  }
  return context;
};

const AudioVideoFormContextProvider = ({ children }: React.PropsWithChildren) => (
  <AudioVideoFormContext.Provider value={ useAudioVideoForm() }>{ children }</AudioVideoFormContext.Provider>
);

export default AudioVideoFormContextProvider;
