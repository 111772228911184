import { format } from 'date-fns';
import { useCustomModalContext } from '@/condor/application/DealRoadshow/CustomModalContext';
import RoadshowRepository from '@/dealroadshow/infrastructure/repository/RoadshowRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import { useForm, useFieldArray, useFieldValue } from '@/Framework/UI/Organisms/FinalForm';
import { useEdit } from './useEdit';
import { useDelete } from './useDelete';
import getDateFromUnixTime from '@/Framework/DateTime/getDateFromUnixTime';
import { getErrorMessage, getMessage } from '@/Framework/Message/Mapper/getMessage';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import * as constants from '@/condor/ui/common/constants';
import { messageCodes } from '@/Framework/Message/messages';
import { ADD_ROASDSHOW_FIELD_NAME, RELATED_ROADSHOWS_FIELD_NAME } from '../constants';
import { IRelatedRoadshow } from '../../interfaces';

export const useRelatedRoadshows = () => {
  const { container } = useDIContext();
  const roadshowRepository = container.get<RoadshowRepository>(RoadshowRepository);

  const { setIsFetching } = useCustomModalContext();
  const { change } = useForm();
  const { fields } = useFieldArray<IRelatedRoadshow>(RELATED_ROADSHOWS_FIELD_NAME);
  const addRoadshowInput = useFieldValue<string>(ADD_ROASDSHOW_FIELD_NAME);

  const getRoadshowForCustomModal = async () => {
    setIsFetching(true);

    try {
      const response = await roadshowRepository.getAdminDealSettings(addRoadshowInput.trim());
      if (response) {
        fields.push({
          id: response.id,
          name: response.name,
          status: response.status,
          text: '',
          logoName: null,
          logoUrl: null,
          deletedAt: null,
          startedAt: format(
            getDateFromUnixTime(response.startedAt),
            constants.DATE_PICKER_CONDOR_DATE_FORMAT,
          ).toString(),
          finishedAt: getDateFromUnixTime(response.finishedAt),
          expectedPricingDate: response.expectedPricingDate ? getDateFromUnixTime(response.expectedPricingDate) : null,
          isNew: true,
        });
        change(ADD_ROASDSHOW_FIELD_NAME, '');
      } else {
        AlertManager.error(getMessage(messageCodes.ROADSHOW_NOT_FOUND));
      }
    } catch (err) {
      AlertManager.error(getErrorMessage(err));
    } finally {
      setIsFetching(false);
    }
  };

  return {
    ...useEdit(),
    ...useDelete(),
    getRoadshowForCustomModal,
  };
};
