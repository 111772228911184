import React from 'react';
import { useHistory } from 'react-router-dom';

import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import CommonProfileContextProvider, { useCommonProfileContext } from '@/users/ui/components/Profile/Common/CommonProfileContext';
import { useProfileForm } from './hooks/useProfileForm';
import FinalFormWrapper from '@/condor/ui/common/Layout/FormWrapper/FinalFormWrapper';
import ProfileFields from './ProfileFields';
import validator from './validator';
import {
  CONDOR_CONTACT_PROFILE_FORM_NAME,
  CONDOR_CONTACTS_LIST_BASE_URL,
} from '@/condor/ui/common/constants';
import { IProfileForm } from './interfaces';

const ProfileForm = () => {
  const { push } = useHistory();

  const {
    isCountriesFetching,
    isTitlesFetching,
  } = useCommonProfileContext();

  const {
    handleSubmit,
    initialValues,
    companies,
    getCompanyByQuery,
  } = useProfileForm();

  const onCancel = () => push(CONDOR_CONTACTS_LIST_BASE_URL);

  if (isCountriesFetching || isTitlesFetching) {
    return <Spinner overlay isSpinnerFixed centered />;
  }

  return (
    <FinalFormWrapper<IProfileForm>
      name={ CONDOR_CONTACT_PROFILE_FORM_NAME }
      dataTest={ CONDOR_CONTACT_PROFILE_FORM_NAME }
      headerText="Contact Details"
      initialValues={ initialValues }
      validate={ (values: IProfileForm) => validator(values, initialValues) }
      onSubmit={ handleSubmit }
      onCancelClick={ onCancel }
      render={ () => <ProfileFields companies={ companies } getCompanyByQuery={ getCompanyByQuery } /> }
      subscription={ {
        submitting: true,
        pristine: true,
        invalid: true,
        submitSucceeded: true,
      } }
    />
  );
};

export default () => (
  <CommonProfileContextProvider>
    <ProfileForm />
  </CommonProfileContextProvider>
);
