import { useState } from 'react';
import ExternalRepository from '@/users/infrastructure/repository/ExternalRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';

export const useApiAccessKey = () => {
  const { container } = useDIContext();
  const externalRepository = container.get<ExternalRepository>(ExternalRepository);

  const [apiAccessKey, setApiAccessKeyState] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getApiAccessKey = async (userId: number) => {
    setIsLoading(true);
    try {
      const response = await externalRepository.getApiAccessKey(userId);
      setApiAccessKeyState(response);
      setIsLoading(false);
    } catch (e) {
      AlertManager.error(getErrorMessage(e));
    }
  };

  const setApiAccessKey = async (userId: number) => {
    setIsLoading(true);
    try {
      const response = await externalRepository.setApiAccessKey(userId);
      setApiAccessKeyState(response);
      setIsLoading(false);
    } catch (e) {
      AlertManager.error(getErrorMessage(e));
    }
  };

  return {
    apiAccessKey,
    isLoading,
    getApiAccessKey,
    setApiAccessKey,
  };
};
