import { useState } from 'react';
import { parse } from 'date-fns';
import { useDIContext } from '@/Framework/DI/DIContext';
import EnhancedTaggingFiltersRepository from '@/condor/infrastructure/repository/EnhancedTagging/EnhancedTaggingFiltersRepository';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import getZonedUTCDate from '@/Framework/DateTime/getZonedUTCDate';
import getUnixTimestamp from '@/Framework/DateTime/getUnixTimestamp';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { CONDOR_TIME_FORMAT } from '@/Framework/DateTime/dateFormats';
import {
  IEnhancedTaggingFilters,
  IEnhancedTaggingFiltersDate,
} from '@/condor/domain/vo/EnhancedTagging/EnhancedTaggingFilters';

const parseDateToTimestamp = ({ dateTime }: IEnhancedTaggingFiltersDate): number => {
  const parsedDateUtc = parse(dateTime, CONDOR_TIME_FORMAT, new Date());
  const zonedDate = getZonedUTCDate('UTC', parsedDateUtc);

  return getUnixTimestamp(zonedDate);
};

const prepareData = (data: IEnhancedTaggingFilters) => ({
  ...data,
  dateMin: data.dateMin ? parseDateToTimestamp(data.dateMin) : null,
  dateMax: data.dateMax ? Math.max(parseDateToTimestamp(data.dateMax), getUnixTimestamp()) : getUnixTimestamp(),
  timeZone: (data.dateMin ?? data.dateMax)?.timezone.timeZone ?? 'America/New_York',
});

export type FiltersDataType = ReturnType<typeof prepareData>;

export const useEnhancedTaggingFiltersData = () => {
  const [data, setData] = useState<ReturnType<typeof prepareData>>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const { container } = useDIContext();
  const enhancedTaggingFiltersRepository = container.get<EnhancedTaggingFiltersRepository>(
    EnhancedTaggingFiltersRepository,
  );

  const fetchFilters = async () => {
    setIsLoading(true);

    try {
      const response = await enhancedTaggingFiltersRepository.getFilters();
      setData(prepareData(response));
      setIsLoaded(true);
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  return {
    fetchFilters,
    filtersData: data,
    isFiltersLoading: isLoading,
    isFiltersLoaded: isLoaded,
  };
};
