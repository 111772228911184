import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import SlideshowRepository from '@/dealroadshow/infrastructure/repository/SlideshowRepository';
import * as actionTypes from '@/Framework/UI/Organisms/Upload/actionTypes';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { messageCodes } from '@/Framework/Message/messages';
import { IUploadProcessing, ProcessingEvent, UploadEvent } from '@dealroadshow/file-uploader';

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadStart(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_ADD,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadChunkSuccess(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_CHUNK_SUCCESS,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadSuccess(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_SUCCESS,
    payload,
  };
}

/**
 * chunk or combination
 * @param {Object} payload
 * @return {Object}
 */
function uploadError(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_ERROR,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadProcessing(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_PROCESSING,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadDone(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_DONE,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadCancelSuccess(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_CANCEL_SUCCESS,
    payload,
  };
}

/**
 * @param {String} uuid
 */
export function uploadPresentationCancel(uuid) {
  return async (dispatch, getState) => {
    if (getState().upload.presentation.callback
        && typeof getState().upload.presentation.callback.cancel === 'function') {
      getState().upload.presentation.callback.cancel();
      if (('presentation' in getState().roadshow.data.item) && getState().roadshow.data.item.presentation) {
        dispatch(initUploadPresentation(getState().roadshow.data.item.presentation));
      }
    } else if (getState().upload.presentation.process.uuid) {
      dispatch(uploadCancelSuccess({
        uuid: getState().upload.presentation.process.uuid,
      }));
    }

    dispatch(uploadCancelSuccess({
      uuid,
    }));
  };
}

/**
 * @param {Array} payload
 */
export function initUploadPresentation(payload) {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPLOAD_PRESENTATION_INIT,
      payload,
    });
  };
}

/**
 * @param {Array.<File>} files
 * @param {Function} onProcessingDoneCallback
 */
export function uploadPresentation(files, onProcessingDoneCallback) {
  return async (dispatch, getState) => {
    if (getState().upload.presentation.process.uuid) {
      uploadPresentationCancel(getState().upload.presentation.process.uuid);
    }

    const slideshowRepository = getState().container.get(SlideshowRepository);
    const upload: IUploadProcessing = await slideshowRepository.upload(files[0]);

    dispatch(uploadStart({
      uploadFile: files[0],
      callback: upload,
      uuid: upload.getUuid(),
    }));
    upload
      .on(UploadEvent.uploadChunkDone, (data) => dispatch(uploadChunkSuccess(data)))
      .on(UploadEvent.error, (data) => dispatch(uploadError(data)))
      .on(UploadEvent.uploadDone, (data) => dispatch(uploadSuccess(data)))
      .on(ProcessingEvent.processing, (data) => dispatch(uploadProcessing(data)))
      .on(ProcessingEvent.processingDone, (data) => {
        dispatch(uploadDone(data));
        if (onProcessingDoneCallback) {
          onProcessingDoneCallback();
        } else {
          AlertManager.success(getMessage(messageCodes.PRESENTATION_UPLOADED));
        }
      })
      .on(UploadEvent.cancel, (data) => dispatch(uploadCancelSuccess(data)));
  };
}
