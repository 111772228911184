import { History } from '@/Framework/DI/Providers/History';
import { RpcError } from '@dealroadshow/json-rpc-dispatcher';
import FinsightCompanyRepository from '@/finsight/infrastructure/repository/CompanyRepository';
import { getItemActions } from '@/Framework/State/Redux/itemFactory';
import { resetCondorFinsightCompanyDeals } from '@/condor/application/actions/finsight/company/deals/collection/actions';
import actionTypes from './actionTypes';
import createAction from '@/Framework/State/Redux/createAction';
import selectors from './selectors';
import { getCondorFinsightCompaniesByFilter } from '@/condor/application/actions/finsight/company/collection/actions';

import {
  CONDOR_FINSIGHT_COMPANIES_LIST_BASE_URL,
  CONDOR_FINSIGHT_CREATE_DEAL_BASE_URL,
} from '@/condor/ui/common/constants';
import createAsyncRequest from '@/Framework/State/Redux/createAsyncRequest';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';

/**
 * @param {Boolean} payload
 */
export const setCondorFinsightCompanyIsInlineEditing = (payload) => createAction(
  actionTypes.SET_CONDOR_FINSIGHT_COMPANY_IS_INLINE_EDITING,
  payload,
);

/**
 * @param {Boolean} payload
 */
export const setCondorFinsightCompanyIsDeleteModalVisible = (payload) => createAction(
  actionTypes.SET_CONDOR_FINSIGHT_COMPANY_IS_DELETE_MODAL_VISIBLE,
  payload,
);

/**
 * @param {Boolean} payload
 */
export const setCondorFinsightCompanyIsMigrateModalVisible = (payload) => createAction(
  actionTypes.SET_CONDOR_FINSIGHT_COMPANY_IS_MIGRATE_MODAL_VISIBLE,
  payload,
);

/**
 * @param {Object} payload
 */
export const getCompanyInformationForMigration = ({ id, pathname }) => (dispatch, getState) => {
  const finsightCompanyRepository = getState().container.get(FinsightCompanyRepository);

  return dispatch(createAsyncRequest({
    payload: {
      id,
    },
    method: finsightCompanyRepository.getCompanyInformationForMigration,
    types: [
      actionTypes.GET_COMPANY_INFORMATION_FOR_MIGRATION,
      actionTypes.GET_COMPANY_INFORMATION_FOR_MIGRATION_SUCCESS,
      actionTypes.GET_COMPANY_INFORMATION_FOR_MIGRATION_ERROR,
    ],
    callbacks: {
      onSuccess: ({ dispatch, response }) => {
        const isDealsExist = (
          response.deal.issuer > 0
          || response.deal.parent > 0
          || response.deal.underwriter > 0
          || response.deal.dealer > 0
          || response.research.issuer > 0
          || response.research.parent > 0
        );

        if (pathname === CONDOR_FINSIGHT_COMPANIES_LIST_BASE_URL) {
          if (isDealsExist) {
            dispatch(setCondorFinsightCompanyIsMigrateModalVisible(true));
          } else {
            dispatch(setCondorFinsightCompanyIsDeleteModalVisible(true));
          }
        }
      },
    },
  }));
};

/**
 * @param {Object} formData
 */
export const migrateCompanyDeals = (formData) => async (dispatch, getState) => {
  const state = getState();
  const { id } = selectors.getItemData(state);
  const { pathname } = state.router.location;

  const finsightCompanyRepository = getState().container.get(FinsightCompanyRepository);

  dispatch(createAsyncRequest({
    payload: {
      id,
      deal: {
        issuer: formData.issuer?.value || null,
        parent: formData.parent?.value || null,
        underwriter: formData.underwriter?.value || null,
        dealer: formData.dealer?.value || null,
      },
      research: {
        issuer: formData.researchIssuer?.value || null,
        parent: formData.researchParent?.value || null,
      },
    },
    method: finsightCompanyRepository.migrateCompanyDeals,
    types: [
      actionTypes.MIGRATE_CONDOR_FINSIGHT_COMPANY_DEALS,
      actionTypes.MIGRATE_CONDOR_FINSIGHT_COMPANY_DEALS_SUCCESS,
      actionTypes.MIGRATE_CONDOR_FINSIGHT_COMPANY_DEALS_ERROR,
    ],
    callbacks: {
      onSuccess: ({ dispatch }) => {
        dispatch(setCondorFinsightCompanyIsMigrateModalVisible(false));
        if (pathname === CONDOR_FINSIGHT_COMPANIES_LIST_BASE_URL) {
          dispatch(setCondorFinsightCompanyIsDeleteModalVisible(true));
        } else {
          dispatch(getCompanyInformationForMigration({ id }));
          dispatch(resetCondorFinsightCompanyDeals());
        }
      },
    },
  }));
};

export const openCompaniesListPage = (issuerId) => (dispatch, getState) => {
  const history = getState().container.get(History);
  const url = issuerId
    ? `${ CONDOR_FINSIGHT_CREATE_DEAL_BASE_URL }?issuerId=${ issuerId }`
    : CONDOR_FINSIGHT_COMPANIES_LIST_BASE_URL;

  history.push(url);
};

export const {
  setCurrentItem: setCondorFinsightCurrentCompany,
  reset: resetCondorFinsightCompanyItem,
  getItem: getCondorFinsightCompany,
  createItem: createCondorFinsightCompany,
  updateItem: updateCondorFinsightCompany,
  removeItem: removeCondorFinsightCompany,
} = getItemActions(
  actionTypes,
  {
    get: {
      method: (payload, dispatch, getState) => {
        const finsightCompanyRepository = getState().container.get(FinsightCompanyRepository);
        return finsightCompanyRepository.getCompanyById(payload);
      },
    },
    create: {
      method: (payload, dispatch, getState) => {
        const finsightCompanyRepository = getState().container.get(FinsightCompanyRepository);
        return finsightCompanyRepository.createCompany(payload);
      },
      callbacks: {
        onSuccess: ({ dispatch, payload, response }) => {
          dispatch(openCompaniesListPage(payload.withCreateDeal ? response.id : null));
        },
        onError: (data) => {
          // TODO: When BE will send all data for error, we can move this huck to FE error text
          if ((data.error instanceof RpcError) && data.error.getCode() >= 70501 && data.error.getCode() <= 70515) {
            AlertManager.error(data.error.getMessage());
          } else {
            AlertManager.error(getErrorMessage(data.error));
          }
        },
      },
    },
    update: {
      method: (payload, dispatch, getState) => {
        const finsightCompanyRepository = getState().container.get(FinsightCompanyRepository);
        return finsightCompanyRepository.updateCompany(payload);
      },
      callbacks: {
        onSuccess: ({ dispatch }) => dispatch(openCompaniesListPage()),
        onError: (data) => {
          // TODO: When BE will send all data for error, we can move this huck to FE error text
          if ((data.error instanceof RpcError) && data.error.getCode() >= 70501 && data.error.getCode() <= 70515) {
            AlertManager.error(data.error.getMessage());
          } else {
            AlertManager.error(getErrorMessage(data.error));
          }
        },
      },
    },
    remove: {
      method: (payload, dispatch, getState) => {
        const finsightCompanyRepository = getState().container.get(FinsightCompanyRepository);
        return finsightCompanyRepository.removeCompany(payload);
      },
      callbacks: {
        onSuccess: ({ dispatch }) => {
          dispatch(setCondorFinsightCompanyIsInlineEditing(false));
          dispatch(setCondorFinsightCompanyIsDeleteModalVisible(false));
          dispatch(getCondorFinsightCompaniesByFilter());
        },
        onError: (data) => {
          // TODO: When BE will send all data for error, we can move this huck to FE error text
          if ((data.error instanceof RpcError) && data.error.getCode() >= 70501 && data.error.getCode() <= 70515) {
            AlertManager.error(data.error.getMessage());
          } else {
            AlertManager.error(getErrorMessage(data.error));
          }
        },
      },
    },
  },
);

/**
 * @param {Object} formData
 * @param {Boolean} withCreateDeal
 */
export const submitCondorFinsightCompanyForm = (formData, withCreateDeal = false) => async (dispatch) => {
  const payload = {
    ...formData,
    abbreviation: formData.abbreviation || null,
    industryId: formData.industrySubindustry?.value?.industryId || null,
    subindustryId: formData.industrySubindustry?.value?.subindustryId || null,
  };

  if (formData.id) {
    await dispatch(updateCondorFinsightCompany(payload));
  } else {
    await dispatch(createCondorFinsightCompany({ ...payload, withCreateDeal }));
  }
};

/**
 * @param {Object} formData
 * @param {Function} onSelect
 */
export const submitCondorFinsightCompanyFormModal = (formData, onSelect) => (dispatch, getState) => {
  const finsightCompanyRepository = getState().container.get(FinsightCompanyRepository);

  return dispatch(createAsyncRequest(
    {
      payload: {
        ...formData,
        industryId: formData.industrySubindustry?.value?.industryId || null,
        subindustryId: formData.industrySubindustry?.value?.subindustryId || null,
      },
      method: finsightCompanyRepository.createCompany,
      types: [
        actionTypes.CREATE_CONDOR_FINSIGHT_COMPANY_ITEM_MODAL,
        actionTypes.CREATE_CONDOR_FINSIGHT_COMPANY_ITEM_MODAL_SUCCESS,
        actionTypes.CREATE_CONDOR_FINSIGHT_COMPANY_ITEM_MODAL_ERROR,
      ],
      callbacks: {
        onSuccess: ({ response }) => onSelect(response),
      },
    },
  ));
};
