import createAction from '@/Framework/State/Redux/createAction';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import * as actionTypes from './actionTypes';
import * as selectors from './selectors';
import BondsRepository from '@/finsight/infrastructure/repository/BondsRepository';
import downloadFile from '@/Framework/api/downloadFile';
import { BI_MODULE, TRACE_MODULE } from '@/condor/ui/components/FinsightManager/constants';

/**
 * @param {Object} payload
 * @param {string} module
 */
export const getManagerAnalyticsContacts = (payload, module) => async (dispatch, getState) => {
  dispatch(createAction(actionTypes.GET_MANAGER_ANALYTICS_CONTACTS));
  try {
    const bondsRepository = getState().container.get(BondsRepository);

    const getAnalyticsCollection = {
      [BI_MODULE]: bondsRepository.getBondAnalyticsContactsList,
      [TRACE_MODULE]: bondsRepository.getTraceAnalyticsContactsList,
    };

    const response = await getAnalyticsCollection[module](payload);
    dispatch(createAction(actionTypes.GET_MANAGER_ANALYTICS_CONTACTS_SUCCESS, response));
  } catch (e) {
    dispatch(createAction(actionTypes.GET_MANAGER_ANALYTICS_CONTACTS_ERROR, e));
    AlertManager.error(getErrorMessage(e));
  }
};

export const getManagerAnalyticsContactsByFilter = (module) => (dispatch, getState) => {
  const state = getState();

  dispatch(getManagerAnalyticsContacts({
    perPage: selectors.getPerPage(state),
    page: selectors.getPage(state),
    sortBy: selectors.getSortBy(state),
    sortOrder: selectors.getSortOrder(state),
  }, module));
};

/**
 * @param {Object} payload
 */
export const sort = (payload) => createAction(actionTypes.SET_MANAGER_ANALYTICS_CONTACTS_TABLE_SORT, payload);

/**
 * @param {Object} payload
 */
export const itemsPerPage = (payload) => createAction(
  actionTypes.SET_MANAGER_ANALYTICS_CONTACTS_TABLE_ITEMS_PER_PAGE,
  payload,
);

/**
 * @param {Object} payload
 */
export const paginate = (payload) => createAction(actionTypes.SET_MANAGER_ANALYTICS_CONTACTS_TABLE_PAGE, payload);

export const resetManagerAnalyticsContacts = () => createAction(actionTypes.RESET_MANAGER_ANALYTICS_CONTACTS);

export const exportToExcelManagerAnalyticsContacts = (module) => async (dispatch, getState) => {
  dispatch(createAction(actionTypes.EXPORT_TO_EXCEL_MANAGER_ANALYTICS_CONTACTS));
  try {
    const bondsRepository = getState().container.get(BondsRepository);

    const getAnalyticsExportUrl = {
      [BI_MODULE]: bondsRepository.getExcelExportUrlBondAnalyticsContacts,
      [TRACE_MODULE]: bondsRepository.getExcelExportUrlTraceAnalyticsContacts,
    };
    const exportUrl = await getAnalyticsExportUrl[module]();

    downloadFile(exportUrl);

    dispatch(createAction(actionTypes.EXPORT_TO_EXCEL_MANAGER_ANALYTICS_CONTACTS_SUCCESS));
  } catch (e) {
    dispatch(createAction(actionTypes.EXPORT_TO_EXCEL_MANAGER_ANALYTICS_CONTACTS_ERROR, e));
    AlertManager.error(getErrorMessage(e));
  }
};
