import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import TransactionTypeRepository from '@/dmPortal/infrastructure/repository/TransactionTypeRepository';
import { useTenantContext } from '@/Framework/Tenant/TenantContext';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';

export default function useTransactionTypes() {
  const { container } = useDIContext();
  const { tenant } = useTenantContext();
  const [isFetching, setIsFetching] = useState(false);
  const [collection, setCollection] = useState([]);

  async function getTransactionTypes() {
    setIsFetching(true);

    try {
      const transactionTypeRepository = container.get<TransactionTypeRepository>(TransactionTypeRepository);
      const response = await transactionTypeRepository.getTransactionTypeList(tenant);
      setCollection(response);
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  }

  return {
    isFetching,
    collection,
    getTransactionTypes,
  };
}
