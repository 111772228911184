import React, { useEffect, Fragment } from 'react';
import debounce from 'lodash/debounce';
import cn from 'classnames';

import { useCallProfileContext } from '@/evercall/application/condor/call/profile/CallProfileContext';
import { Field, useForm } from '@/Framework/UI/Organisms/FinalForm';
import useFormReinitializeAfterSubmit from '../useFormReinitializeAfterSubmit';

import IconQuestion from '@dealroadshow/uikit/core/components/Icon/IconQuestion';
import IconLink from '@dealroadshow/uikit/core/components/Icon/IconLink';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import Input from '@dealroadshow/uikit/core/components/Input';
import { AsyncSelect } from '@dealroadshow/uikit/core/components/Select';
import Radio, { RadioGroup } from '@dealroadshow/uikit/core/components/Radio';

import { IAdminCall } from '@/evercall/domain/vo/call/admin/AdminCall';
import { TGroupSuggestion } from '@/evercall/domain/vo/call/admin/Group';
import { ISettingsFormValues } from './interfaces';
import { IGroupEventOption } from '@/evercall/ui/components/condor/GroupProfile/interfaces';
import CallEvent from '@/evercall/domain/CallEvent';

import { PARTICIPANT_CONFERENCE_ID_LABEL } from '@/evercall/ui/components/condor/CallsList/constants';
import { CALL_REPLAY_METHOD_RADIO_OPTIONS, PARTICIPANT_CONFERENCE_ID_FIELD_TOOLTIP } from './constants';
import { CALL_REPLAY } from '@/condor/application/config/featureToggle';
import FeatureToggle from '@/Framework/UI/Organisms/FeatureToggle/Components';
import evercallUrl from '@/evercall/infrastructure/evercallUrl';

import styles from './settings.scss';
import groupProfileStyles from '@/evercall/ui/components/condor/GroupProfile/groupProfile.scss';
import layoutStyles from '@/condor/ui/common/Layout/layout.scss';
import headerStyles from '@dealroadshow/uikit/core/styles/headers.scss';

interface IProps {
  isUpdating: boolean,
  isUpdateSucceeded: boolean,
  fetchGroupsSuggestions: (search: string) => Promise<TGroupSuggestion[]>,
}

const SettingsFormFields = ({
  isUpdating,
  isUpdateSucceeded,
  fetchGroupsSuggestions,
}: IProps) => {
  const {
    state: {
      item,
    },
  } = useCallProfileContext();

  const form = useForm<ISettingsFormValues>();
  const { values } = form.getState();

  const getInitialValues = (item: IAdminCall): ISettingsFormValues => ({
    ...item,
    groupId: item.groupId ? {
      label: item.groupName,
      value: item.groupId,
      path: item.groupPath,
    } : null,
  });

  useEffect(
    () => {
      if (item.name) {
        form.initialize(getInitialValues(item));
      }
    },
    [item.id],
  );

  const loadGroupOptions = debounce(
    (
      query: string,
      callback: (options: IGroupEventOption[]) => void,
    ) => {
      if (query?.length) {
        fetchGroupsSuggestions(query)
          .then((response) => {
            callback(response.map((d) => ({
              value: d.id,
              label: d.name,
              path: d.path,
            })));
          });
      }
    },
    500,
  );

  useFormReinitializeAfterSubmit(() => {
    if (isUpdateSucceeded) {
      form.restart(getInitialValues(item));
    }
  }, isUpdating);

  const getLabelWithExplainer = (label: string, tooltipContent: string) => (
    <>
      <span>{ label }</span>
      <span className={ styles.questionIconContainer }>
        <Tooltip content={ tooltipContent }>
          <IconQuestion />
        </Tooltip>
      </span>
    </>
  );

  return (
    <>
      <Field
        component={ Input }
        className={ layoutStyles.inputField }
        name="name"
        label="Call Name"
        placeholder="eg Acme Global Investor Call"
        dataTest="evercallProfileFormCallNameInput"
      />
      <div className={ styles.conferenceInputsContainer }>
        <Field
          name="participantConferenceId"
          component={ Input }
          isNarrow
          className={ styles.inputField }
          label={ getLabelWithExplainer(PARTICIPANT_CONFERENCE_ID_LABEL, PARTICIPANT_CONFERENCE_ID_FIELD_TOOLTIP) }
          placeholder="12345"
          dataTest="evercallProfileFormParticipantConferenceIdInput"
        />
        <div className={ styles.linkIconContainer }>
          <IconLink className={ styles.linkIcon } />
        </div>
        <Field
          name="groupId"
          render={ ({
            input,
            otherProps,
          }) => (
            <div className={ groupProfileStyles.groupNameInputContainer }>
              <AsyncSelect
                { ...otherProps }
                isNarrow
                formFieldClassName={ styles.inputField }
                name={ input.name }
                value={ input.value }
                onBlur={ input.onBlur }
                onChange={ input.onChange }
                label="Group"
                placeholder="Select Group"
                loadOptions={ loadGroupOptions }
                dataTest="groupSelect"
              />
              { values.groupId && (
                <div className={ groupProfileStyles.urlLabel }>
                  { evercallUrl.getUrl(`/events/${ values.groupId.path }`) }
                </div>
              ) }
            </div>
          ) }
        />
      </div>
      <div className={ styles.roadshowInputsContainer }>
        <Field
          component={ Input }
          formFieldClassName={ styles.inputField }
          isNarrow
          name="roadshowId"
          label={ getLabelWithExplainer('Roadshow ID', 'Link to display dial-ins in Roadshow') }
          placeholder="d38f43d38f43d38f43d38f"
          dataTest="roadshowId"
        />
        <Field
          component={ Input }
          formFieldClassName={ styles.inputField }
          name="roadshowEntryCode"
          label="Entry Code"
          placeholder="Entry Code linked in event registration form"
          dataTest="roadshowEntryCodeInput"
        />
      </div>
      <FeatureToggle featureName={ CALL_REPLAY }>
        <div className={ cn(headerStyles.isH3, styles.panelHeaderLabel) }>Call Replay</div>
        <Field
          name="replayMethod"
          render={ ({ input }) => (
            <RadioGroup
              { ...input }
              selectedValue={ input.value }
              isNarrow
              dataTest="replayMethodRadioGroup"
            >
              { CALL_REPLAY_METHOD_RADIO_OPTIONS.map((radio) => (
                <Fragment key={ radio.value }>
                  <div className={ styles.replayRadioBtn }>
                    <Radio
                      key={ radio.value }
                      label={ radio.label }
                      value={ radio.value }
                      dataTest={ `${ radio.value }ReplayMethod` }
                    />
                  </div>
                  { [radio.value, input.value].every(CallEvent.isReplayViaUrl) && (
                    <Field
                      name="replayUrl"
                      component={ Input }
                      isNarrow
                      formFieldClassName={ styles.linkToDrsField }
                      placeholder="Enter URL"
                    />
                  ) }
                </Fragment>
              )) }
            </RadioGroup>
          ) }
        />
      </FeatureToggle>
    </>
  );
};

export default SettingsFormFields;
