import React, { createContext, useContext, useState } from 'react';

import { useSubscribersContext } from '..';
import ContactRepository from '@/finsight/infrastructure/repository/ContactRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { IAddSubscribersPayload } from '@/condor/domain/vo/FinsightManager/ManagePlans';
import sleep from '@/Framework/dataHelpers/sleep';
import {
  useCheckEmailsSubscriptionContext,
} from '@/condor/application/FinsightManager/Subscribers/AddSubscribersForm/CheckEmailsSubscriptionContext';
import { IInputTag } from '@/Framework/UI/Molecules/Form/InputTags/InputTags';

export interface IEmailTag extends IInputTag {
  userPlan?: string,
}
interface IEmailValue {
  label: string,
  value: string,
}

interface ISubmitValues {
  emails: IEmailValue[],
  plan: string,
  team?: { value: string, label: string },
  withEmail?: boolean,
  emailSubject?: string,
  emailText?: string,
  isExportEnabled?: boolean,
}

const useAddSubscribers = () => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isWithEmail, setIsWithEmail] = useState<boolean>(true);
  const [isCustomMessageBlockVisible, setIsCustomMessageBlockVisible] = useState<boolean>(false);
  const [isAddSubscribersModalVisible, setIsAddSubscribersModalVisible] = useState<boolean>(false);
  const [selectedEmails, setSelectedEmails] = useState<IEmailTag[]>([]);

  const { getCollection } = useSubscribersContext();
  const { setCheckedEmails } = useCheckEmailsSubscriptionContext();
  const { container } = useDIContext();
  const contactRepository = container.get(ContactRepository);

  const resetData = () => {
    setCheckedEmails({});
    setSelectedEmails([]);
    setIsWithEmail(true);
    setIsCustomMessageBlockVisible(false);
    setIsAddSubscribersModalVisible(false);
  };

  const onSubmit = async (values: ISubmitValues) => {
    const formattedPayload: IAddSubscribersPayload = {
      emails: values.emails.map((email) => email.value),
      plan: values.plan,
      team: values.team?.label || '',
      sendEmail: isWithEmail,
      features: [{
        feature: 'xlsExport',
        isEnabled: values.isExportEnabled,
      }],
    };
    if (isWithEmail) {
      formattedPayload.subject = values.emailSubject;
      formattedPayload.emailText = values.emailText === '<p></p>' ? '' : values.emailText;
    }
    setIsSubmitting(true);
    try {
      await contactRepository.addSubscribersByAdmin(formattedPayload);
      const emailsCount = formattedPayload.emails.length;
      const isPlural = emailsCount > 1;
      AlertManager.success(
        `<strong>
                    ${ emailsCount } user${ isPlural ? 's' : '' }
                    </strong> ${ isPlural ? 'were' : 'was' }  successfully subscribed to the ${ formattedPayload.plan } plan`,
      );
      // sleep is needed for sync from users table
      await sleep(2000);
      resetData();
      getCollection();
    } catch (e) {
      AlertManager.error(getErrorMessage(e));
    } finally {
      setIsSubmitting(false);
    }
  };

  const onClose = () => {
    if (isCustomMessageBlockVisible) {
      setIsCustomMessageBlockVisible(false);
      return;
    }
    resetData();
  };

  return {
    isAddSubscribersModalVisible,
    isCustomMessageBlockVisible,
    isWithEmail,
    isSubmitting,

    setIsAddSubscribersModalVisible,
    setIsCustomMessageBlockVisible,
    setIsWithEmail,
    setSelectedEmails,

    selectedEmails,

    onClose,
    onSubmit,
  };
};

const AddSubscribersContext = createContext<ReturnType<typeof useAddSubscribers>>(null);

export const useAddSubscribersFormContext = () => {
  const context = useContext(AddSubscribersContext);
  if (!context) {
    throw new Error('useAddSubscribersFormContext must be used within a AddSubscribersContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const AddSubscribersContextProvider = ({ children }: IProps) => (
  <AddSubscribersContext.Provider value={ useAddSubscribers() }>
    { children }
  </AddSubscribersContext.Provider>
);

export default AddSubscribersContextProvider;
