import { useState } from 'react';
import {
  createAsyncRequest,
  IAsyncRequest,
  TRequestConfig,
  TDefaultMethod,
} from '@/Framework/State/createAsyncRequest';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';

type TItemFactoryPayload<TItem, TGetMethod extends TDefaultMethod> = {
  initialItemState: TItem,
  getRequestConfig: TRequestConfig<TGetMethod>,
};

type TReturnValue<TItem, TGetMethod extends TDefaultMethod> = (
  {
    state: {
      item: TItem,
      isFetching: boolean,
    },
    setItem: (payload: TItem) => void,
    setIsFetching: (payload: boolean) => void,
    getItemData: IAsyncRequest<TGetMethod>,
  }
);

/**
 * This hook can be useful when you need to:
 * 1. refactor modules where the helper-functions exported from 'itemFactory.js' file are used;
 * 2. create logic module for a typical profile page
 *
 * Both of the cases described above are related mostly to Condor.
 */
export default <TItem, TGetMethod extends TDefaultMethod = TDefaultMethod>(
  {
    initialItemState,
    getRequestConfig,
  }: TItemFactoryPayload<TItem, TGetMethod>,
): TReturnValue<TItem, TGetMethod> => {
  const [item, setItem] = useState<TItem>(initialItemState);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const getItemData = (payload: Parameters<TGetMethod>[0]) => {
    const request = createAsyncRequest<TGetMethod>(
      {
        ...getRequestConfig,
        callbacks: {
          onSuccess: ({ response }) => {
            setIsFetching(false);
            setItem(response);
          },
          onError: ({ error }) => {
            AlertManager.error(getErrorMessage(error));
            setIsFetching(false);
          },
          ...(getRequestConfig.callbacks || {}),
        },
      },
    );
    setIsFetching(true);
    return request(payload);
  };

  return {
    state: {
      item,
      isFetching,
    },
    setItem,
    setIsFetching,
    getItemData,
  };
};
