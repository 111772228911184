import { useState } from 'react';
import { ICompany } from '@/users/domain/vo/Company';
import { useDIContext } from '@/Framework/DI/DIContext';
import CompaniesRepository from '@/users/infrastructure/repository/CompaniesRepository';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';

export default function useCompany() {
  const { container } = useDIContext();
  const [verifiedCompanies, setVerifiedCompanies] = useState<ICompany[]>([]);

  const searchCompanies = async (payload?: { query: string, id?: number[], limit?: number }) => {
    try {
      const companiesRepository = container.get<CompaniesRepository>(CompaniesRepository);
      const newPayload = payload
        ? { ...payload, verified: true, limit: payload.limit || 50 }
        : { verified: true, limit: 50 };
      const response = await companiesRepository.list(newPayload);

      setVerifiedCompanies(response.collection);

      return response;
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
      return error;
    }
  };

  return {
    verifiedCompanies,
    searchCompanies,
    setVerifiedCompanies,
  };
}
