import { createContext, useContext } from 'react';
import { useRelatedRoadshows } from './useRelatedRoadshows';

type TRelatedRoadshowsContext = ReturnType<typeof useRelatedRoadshows>;

const RelatedRoadshowsContext = createContext<TRelatedRoadshowsContext>(null);

export const useRelatedRoadshowsContext = () => {
  const context = useContext(RelatedRoadshowsContext);
  if (!context) {
    throw new Error('useRelatedRoadshowsContext must be used within a RelatedRoadshowsContextProvider');
  }
  return context;
};

const RelatedRoadshowsContextProvider = ({ children }) => (
  <RelatedRoadshowsContext.Provider value={ useRelatedRoadshows() }>
    { children }
  </RelatedRoadshowsContext.Provider>
);

export default RelatedRoadshowsContextProvider;
