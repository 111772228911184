import { format } from 'date-fns';
import { useRelatedRoadshowsContext } from './RelatedRoadshowsContext/RelatedRoadshowsContext';
import ImgFetching from '@dealroadshow/uikit/core/components/Loader/ImgFetching';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import IconTrash from '@dealroadshow/uikit/core/components/Icon/IconTrash';
import IconPencil from '@dealroadshow/uikit/core/components/Icon/IconPencil';
import IconRefresh from '@dealroadshow/uikit/core/components/Icon/IconRefresh';
import ActionButton from '@/Framework/UI/Atoms/Button/ActionButton';
import StatusIndicator from '@dealroadshow/uikit/core/components/StatusIndicator';
import getDateFromUnixTime from '@/Framework/DateTime/getDateFromUnixTime';
import files from '@/Framework/url/filesUrl';
import * as constants from '@/condor/ui/common/constants';
import { variantTypes as ActionButtonVariantTypes } from '@/Framework/UI/Atoms/Button/ActionButton/constants';

import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';
import styles from './styles.scss';

export const StatusCellCallback = ({ row }) => {
  const title = row?.isNew ? 'New' : 'Saved';

  return (
    <Tooltip className={ alignStyles.flexCenter } content={ title }>
      <StatusIndicator status={ row?.isNew ? 'unpublished' : 'published' } title={ title } />
    </Tooltip>
  );
};

export const LogoCellCallback = ({ row }) => row.logoUrl && (
  <ImgFetching src={ files.getFileUrl(row.logoUrl) } />
);

export const DeleteAtCellCallback = ({ row }) => (
  row.deletedAt
    ? format(getDateFromUnixTime(row.deletedAt), constants.CONDOR_DEAL_FILES_DATE_TIME_FORMAT)
    : ''
);

export const ActionButtonsCellCallback = ({ row, rowIndex }) => {
  const { showEditModal, showDeleteModal } = useRelatedRoadshowsContext();

  return (
    <div className={ styles.actionsCell }>
      <ActionButton
        title="Edit Related Roadshow"
        icon={ IconPencil }
        variant={ ActionButtonVariantTypes.icon }
        dataTest="editRelatedRoadshowButton"
        onClick={ () => showEditModal({ ...row, rowIndex }) }
      />
      <ActionButton
        title={ `${ row.deletedAt ? 'Restore' : 'Delete' } Related Roadshow` }
        icon={ row.deletedAt ? IconRefresh : IconTrash }
        variant={ ActionButtonVariantTypes.icon }
        dataTest={ `${ row.deletedAt ? 'restore' : 'delete' }RelatedRoadshowButton` }
        onClick={ () => showDeleteModal({ ...row, rowIndex }) }
      />
    </div>
  );
};
