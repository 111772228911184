import { ICurrentUserPermission } from '@/users/domain/vo/IPermission';
import { IValues } from './interfaces';

export const formatPermissions = (values: IValues): ICurrentUserPermission[] => {
  return Object.keys(values).reduce((result, permissionGroupKey) => {
    values[permissionGroupKey].forEach((moduleKey) => {
      result.push({ moduleKey, permissionGroupKey: 'admin' });
    });
    return result;
  }, []);
};
