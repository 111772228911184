import { useState } from 'react';

import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import CompaniesRepository from '@/users/infrastructure/repository/CompaniesRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import { ICompany } from '@/users/domain/vo/Company';

const useCompany = () => {
  const { container } = useDIContext();
  const companiesRepository = container.get<CompaniesRepository>(CompaniesRepository);
  const [totalCount, setTotalCount] = useState(null);
  const [isCollectionFetching, setIsCollectionFetching] = useState(false);
  const [collection, setCollection] = useState<ICompany[]>([]);

  const getCompanies = async (payload) => {
    setIsCollectionFetching(true);
    try {
      const response = await companiesRepository.list(payload);
      setCollection(response?.collection);
      setTotalCount(response?.totalCount);
      setIsCollectionFetching(false);
      return response;
    } catch (err) {
      setIsCollectionFetching(false);
      AlertManager.error(getErrorMessage(err));
      throw err;
    }
  };

  return {
    totalCount,
    isCollectionFetching,
    collection,
    getCompanies,
  };
};

export default useCompany;
