import { createContext, useContext, ReactNode } from 'react';
import { useCustomModal } from './useCustomModal';

interface IContext {
  children: ReactNode,
}

type TCustomModalContext = ReturnType<typeof useCustomModal>;

const CustomModalContext = createContext<TCustomModalContext>(null);

export const useCustomModalContext = () => {
  const context = useContext(CustomModalContext);
  if (!context) {
    throw new Error('useCustomModalContext must be used within a CustomModalContextProvider');
  }
  return context;
};

const CustomModalContextProvider = ({ children }: IContext) => (
  <CustomModalContext.Provider value={ useCustomModal() }>
    { children }
  </CustomModalContext.Provider>
);

export default CustomModalContextProvider;
