import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import UserCondorRepository from '@/condor/infrastructure/repository/UserRepository';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import getMessage from '@/Framework/Message/getMessage';
import { messageCodes } from '@/Framework/Message/messages';
import { ICurrentUserPermission, IGroup, IModule } from '@/users/domain/vo/IPermission';

export const usePermissions = () => {
  const { container } = useDIContext();
  const userCondorRepository = container.get<UserCondorRepository>(UserCondorRepository);

  const [currentUserPermissions, setCurrentUserPermissions] = useState<ICurrentUserPermission[]>(null);
  const [contactPermissions, setContactPermissions] = useState<ICurrentUserPermission[]>(null);
  const [modules, setModules] = useState<IModule[]>(null);
  const [group, setGroup] = useState<IGroup[]>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const getPermissions = async (contactId: number) => {
    try {
      setIsFetching(true);

      const contactPermissionsResponse = await userCondorRepository.getContactPermissions(contactId);
      setContactPermissions(contactPermissionsResponse);

      const modulesResponse = await userCondorRepository.getUserPermissionModules();
      setModules(modulesResponse);

      const groupResponse = await userCondorRepository.getUserPermissionGroup();
      setGroup(groupResponse);

      setIsFetching(false);
    } catch (e) {
      AlertManager.error(getErrorMessage(e));
    }
  };

  const getCurrentUserPermissions = async () => {
    try {
      const response = await userCondorRepository.getCurrentUserPermissions();
      setCurrentUserPermissions(response);
    } catch (e) {
      AlertManager.error(getErrorMessage(e));
    }
  };

  const savePermissions = async (contactId: number, userPermissions: ICurrentUserPermission[]) => {
    setIsFetching(true);

    const callback = ({ status }) => {
      if (status === 'error') {
        AlertManager.error(getMessage(messageCodes.GENERAL_ERROR));
      } else {
        AlertManager.success(getMessage(messageCodes.CONDOR_SAVED));
      }
      setIsFetching(false);
    };

    try {
      await userCondorRepository.subscribeContactPermissionsUpdate({
        contactId,
        userPermissions,
        callback,
      });
      await userCondorRepository.updateContactPermissions({ contactId, userPermissions });
      setContactPermissions(userPermissions);
    } catch (e) {
      AlertManager.error(getErrorMessage(e));
    }
  };

  return {
    currentUserPermissions,
    contactPermissions,
    modules,
    group,
    isFetching,

    getCurrentUserPermissions,
    getPermissions,
    savePermissions,
  };
};
