import cn from 'classnames';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import { Label } from '@dealroadshow/uikit/core/components/Badge/Label';
import ISubsector from '@/finsight/domain/vo/ISubsector';
import ISector from '@/finsight/domain/vo/ISector';
import ISubindustry from '@/finsight/domain/vo/ISubindustry';
import IIndustry from '@/finsight/domain/vo/IIndustry';

import styles from './styles.scss';

const getSectorIndustryTooltipContent = (
  sectorOrIndustryName: string,
  subsectorsOrSubindustries: ISubsector | ISubindustry) => {
  if (!subsectorsOrSubindustries) {
    return (
      <div className={ styles.tooltipContent }>
        { sectorOrIndustryName }
      </div>
    );
  }

  return (
    <div className={ styles.tooltipContent }>
      <p className={ styles.tooltipItem }>{ `${ sectorOrIndustryName }: ${ subsectorsOrSubindustries.name }` }</p>
    </div>
  );
};

interface IProps {
  sectorOrIndustry: IIndustry | ISector,
  subsectorOrSubindustry: ISubindustry | ISubsector,
  className?: string,
}

const SectorIndustryLabel = ({
  sectorOrIndustry,
  subsectorOrSubindustry,
  className,
}: IProps) => {
  if (!sectorOrIndustry) {
    return null;
  }
  return (
    <div data-test="sectorIndustryLabel">
      <Tooltip
        content={ getSectorIndustryTooltipContent(sectorOrIndustry.name, subsectorOrSubindustry) }
      >
        <Label
          className={ cn(styles.label, className) }
          backgroundColor={ sectorOrIndustry.color }
        >
          { sectorOrIndustry.abbreviation }
        </Label>
      </Tooltip>
    </div>
  );
};

export default SectorIndustryLabel;
