import memoize from '@/Framework/dataHelpers/memoize';
import { IAccountProfileItem } from '@/condor/domain/vo/Accounts/AccountProfileItem';
import { IAccountProfileItemView } from '@/condor/domain/vo/Accounts/AccountProfileItemView';
import { MIN_COUNT_TO_CLOSE_EDIT_ACCOUNT } from '@/condor/ui/components/AccountsAndContacts/Accounts/constants';
import { ICurrentUserPermission } from '@/users/domain/vo/IPermission';
import Country from '@/dealroadshow/domain/vo/Country';

import styles from './accounts.scss';

export const getStatus = memoize((isVerified: boolean, deletedAt?: string) => {
  if (deletedAt) {
    return {
      id: 'deleted',
      tooltipContent: 'Deleted Account',
      className: styles.deletedAccount,
    };
  }
  if (isVerified) {
    return {
      id: 'verified',
      tooltipContent: 'Verified Account',
      className: styles.verifiedAccount,
    };
  }

  return {
    id: 'unverified',
    tooltipContent: 'Unverified Account',
    className: styles.unverifiedAccount,
  };
}, (isVerified: boolean, deletedAt: string) => JSON.stringify([isVerified, deletedAt]));

export const initialAccountProfileValues = (
  isCreating: boolean,
  item: IAccountProfileItem,
  countries: Country[],
): Partial<IAccountProfileItemView> => {
  if (isCreating) {
    return {
      verified: true,
      topAccount: false,
      webDomains: [],
      emailDomains: [],
      leis: [],
    };
  }

  const country = item.countryId ? (countries.find(({ id }) => id === item.countryId) || null) : null;

  return {
    ...item,
    contactCount: item.contactCount > 0
      ? `${ item.contactCount } Contact${ item.contactCount === 1 ? '' : 's' }`
      : item.contactCount,
    dealCount: item.dealCount > 0
      ? `${ item.dealCount } Deal${ item.dealCount === 1 ? '' : 's' }`
      : item.dealCount,
    allocationCount: item.allocationCount > 0
      ? `${ item.allocationCount } Mapping${ item.allocationCount === 1 ? '' : 's' }`
      : item.allocationCount,
    evercallCount: item.evercallCount > 0
      ? `${ item.evercallCount } Evercall${ item.evercallCount === 1 ? '' : 's' }`
      : item.evercallCount,
    accountTypeId: !item.accountType ? null : {
      value: item.accountType.id,
      label: item.accountType.name,
    },
    country: country ? { label: country.name, value: country.id, countryCode: country.code } : null,
  };
};

export const getIsAccountRestrictedToEdit = (
    account: IAccountProfileItem,
    userPermisions: ICurrentUserPermission[],
) => {
  const { dealCount, allocationCount, evercallCount, contactCount } = account;
  const isContactPermission = userPermisions.find((permission) => permission.moduleKey === 'user_permissions');
  const isAdmin = isContactPermission && isContactPermission.permissionGroupKey === 'admin';

  if (isAdmin) {
    return false;
  }

  return dealCount >= MIN_COUNT_TO_CLOSE_EDIT_ACCOUNT
      || allocationCount >= MIN_COUNT_TO_CLOSE_EDIT_ACCOUNT
      || evercallCount >= MIN_COUNT_TO_CLOSE_EDIT_ACCOUNT
      || contactCount >= MIN_COUNT_TO_CLOSE_EDIT_ACCOUNT;
};
