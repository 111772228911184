import Section from './Section';
import { useLinks } from './useLinks';

const Links = () => {
  const {
    isVisibleInvalidateModal,
    setIsVisibleInvalidateModal,

    passwordReset,
    setPasswordReset,
    isFetchingPassword,
    passwordResetLink,
    generateUserPasswordResetLink,

    activation,
    setActivation,
    isFetchingActivation,
    activationLink,
    generateUserActivationLink,
  } = useLinks();

  return (
    <>
      <Section
        label="Reset Password"
        linkLabel="PASSWORD RESET LINK"
        link={ passwordResetLink }
        dataTest="resetPasswords"
        selectProps={ {
          name: 'resetPasswordsSelect',
          value: passwordReset,
          onChange: setPasswordReset,
        } }
        isVisibleInvalidateModal={ isVisibleInvalidateModal }
        setIsVisibleInvalidateModal={ setIsVisibleInvalidateModal }
        isGenerating={ isFetchingPassword }
        onGenerateClick={ generateUserPasswordResetLink }
      />
      <Section
        label="Contact Activation"
        linkLabel="ACTIVATION LINK"
        link={ activationLink }
        dataTest="contactActivations"
        selectProps={ {
          name: 'contactActivationsSelect',
          value: activation,
          onChange: setActivation,
        } }
        isGenerating={ isFetchingActivation }
        onGenerateClick={ generateUserActivationLink }
      />
    </>
  );
};

export default Links;
