import { useMemo } from 'react';
import cn from 'classnames';
import UserPermissionsContextProvider, { useUserPermissionsContext } from './UserPermissionsContext';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import Tabs from './Tabs';
import PermissionTable from './PermissionTable';
import PermissionsActions from './PermissionsActions';
import CheckboxArrayContext from '@/Framework/UI/Molecules/CheckBoxArray/CheckboxArrayContext';

import permissionStyles from './userPermissions.scss';
import headerStyles from '@dealroadshow/uikit/core/styles/headers.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';

const UserPermissions = () => {
  const {
    contactPermissions,
    isFetching,
    modules,
    group,
  } = useUserPermissionsContext();

  const initialValues = useMemo(() => {
    if (!modules) return {};
    return Object.keys(modules).reduce((acc, value) => {
      acc[value] = [];
      modules[value].forEach((module) => {
        contactPermissions.forEach((permission) => {
          if (permission.moduleKey === module.key) {
            acc[value].push(permission.moduleKey);
          }
        });
      });
      return acc;
    }, {});
  }, [modules, contactPermissions]);

  return (
    <div className={ permissionStyles.permissionsContainer }>
      <div className={ cn(headerStyles.isH3, spacesStyles.mbl) }>User Permissions</div>
      <Spinner isVisible={ isFetching } overlay />
      {
        (modules && group) && (
          <CheckboxArrayContext initialValues={ initialValues }>
            <Tabs />
            <PermissionTable />
            <PermissionsActions />
          </CheckboxArrayContext>
        )
      }
    </div>
  );
};

export default () => (
  <UserPermissionsContextProvider>
    <UserPermissions />
  </UserPermissionsContextProvider>
);
