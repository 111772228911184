import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { useDIContext } from '@/Framework/DI/DIContext';
import { useField, useForm } from '@/Framework/UI/Organisms/FinalForm';
import CreditFlowResearchRepository
  from '@/condor/infrastructure/repository/creditFlowResearch/CreditFlowResearchRepository';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import {
  ArticleFormData,
  IOptionDeal,
  IOptionTheme,
} from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/ArticleFormData';

const MIN_LENGTH_QUERY = 3;
const FIELD_THEMES_NAME = 'themes';

const useDeals = () => {
  const { container } = useDIContext();
  const creditFlowResearchRepository = container.get<CreditFlowResearchRepository>(CreditFlowResearchRepository);
  const { change } = useForm<ArticleFormData>();
  const { input: { value: themes = [] } } = useField<IOptionTheme[]>(FIELD_THEMES_NAME);
  const [themeIds, setThemeIds] = useState<string[]>([]);
  const [currentOptions, setCurrentOptions] = useState<IOptionDeal[]>([]);

  const loadDeals = async (
    query: string,
    callback: (options: IOptionDeal[]) => void,
  ) => {
    if (query.length < MIN_LENGTH_QUERY) {
      callback([]);

      return;
    }

    try {
      const payload = {
        searchString: query,
        filters: {
          includedThemeIds: themes.map(({ value }) => value),
        },
      };

      setCurrentOptions([]);

      const deals = await creditFlowResearchRepository.getDeals(payload);
      const data = deals.collection.map(({ id, name, ...other }) => ({ value: id, label: name, ...other }));

      setCurrentOptions(data);
      callback(data);
    } catch (error) {
      AlertManager.error(getErrorMessage(error));

      callback([]);
    }
  };

  const loadDealsOptions = debounce(loadDeals, 500);

  useEffect(() => {
    const ids = themes.map((theme) => theme.value);
    const difference = themeIds.filter((id) => !ids.includes(id));

    if (difference.length) {
      change('deals', []);
    }

    setThemeIds(ids);
  }, [JSON.stringify(themes)]);

  return {
    loadDealsOptions,
    currentDealsOptions: currentOptions,
    isDisabled: !themes.length,
  };
};

export default useDeals;
