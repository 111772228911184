import React, { createContext, useContext, useState } from 'react';

import ContactRepository from '@/finsight/infrastructure/repository/ContactRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { IInputTag } from '@/Framework/UI/Molecules/Form/InputTags/InputTags';

export interface IEmailTag extends IInputTag {
  userPlan?: string,
}

export interface ICheckedEmails {
  PRO?: string[],
  PREMIUM?: string[],
  STARTER?: string[],
}

const useCheckEmailsSubscription = () => {
  const [isChecking, setIsChecking] = useState<boolean>(false);
  const [checkedEmails, setCheckedEmails] = useState<ICheckedEmails>({});

  const { container } = useDIContext();
  const contactRepository = container.get(ContactRepository);

  const checkNewEmails = async (newEmails: IEmailTag[]) => {
    if (!newEmails) return;
    const emails = [];
    newEmails.filter((newEmail) => newEmail.isValid).forEach((validEmail) => {
      emails.push(validEmail.value);
    });
    if (!emails.length) return;
    try {
      setIsChecking(true);
      const response = await contactRepository.checkEmailsStatus({ emails });
      setCheckedEmails(response);
    } catch (e) {
      AlertManager.error(getErrorMessage(e));
    } finally {
      setIsChecking(false);
    }
  };

  return {
    isChecking,

    checkedEmails,
    setCheckedEmails,
    checkNewEmails,
  };
};

const CheckEmailsSubscriptionContext = createContext<ReturnType<typeof useCheckEmailsSubscription>>(null);

export const useCheckEmailsSubscriptionContext = () => {
  const context = useContext(CheckEmailsSubscriptionContext);
  if (!context) {
    throw new Error('useCheckEmailsSubscriptionContext must be used within a CheckEmailsSubscriptionContext');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const CheckEmailsSubscriptionContextProvider = ({ children }: IProps) => (
  <CheckEmailsSubscriptionContext.Provider value={ useCheckEmailsSubscription() }>
    { children }
  </CheckEmailsSubscriptionContext.Provider>
);

export default CheckEmailsSubscriptionContextProvider;
