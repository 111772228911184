import { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';
import {
  createCompanyOptionWithIssuerTicker,
  createCompanyOptionWithIssuerTickerAndAbbreviation,
} from './createCompanyOption';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import { getTime } from 'date-fns';
import { getOptionMapper, mapToDefaultOption } from '@/Framework/dataHelpers/selectOptionMappers';
import {
  DEAL_PROFILE_FIELDS,
  DEAL_PROFILE_FIELDS_ARRAY,
  DEAL_PROFILE_TRANCHE_FIELDS_ARRAY,
  TRANCHE_FIELDS,
} from '../constants';
import { IDealProfileFormField, IDealProfileOptions } from '@/condor/domain/vo/Finsight/Deal/DealProfileForm';
import { IRatingAgency } from '@/finsight/domain/vo/RatingAgency/IRatingAgency';
import { IPrimaryIssuer } from '@/finsight/domain/vo/IDeal';
import { DealProfilePayload, IDealPayloadTranche } from '@/condor/domain/vo/Finsight/Deal/DealProfilePayload';
import { editorInitialValue } from '@/condor/application/Finsight/constants';

const getTextFieldsInitialValues = (fields: IDealProfileFormField[], values: object = {}) => fields.reduce(
  (result, currentField) => {
    const { isSelect, isDate, isBoolean, isNumber, isRadio } = currentField;
    return {
      ...result,
      ...(
        !isSelect &&
        !isDate &&
        !isBoolean &&
        !isNumber &&
        !isRadio &&
        (currentField.name !== DEAL_PROFILE_FIELDS.TRANCHE.name) &&
        (currentField.name !== DEAL_PROFILE_FIELDS.RELATIVE_VALUE.name) &&
        {
          [currentField.name]: values[currentField.name] ? values[currentField.name] : '',
        }
      ),
    };
  },
  {},
);

const getRadioFieldsInitialValues = (fields: IDealProfileFormField[], values: object = {}) => fields.reduce(
    (result, currentField) => {
      const { isRadio } = currentField;
      return {
        ...result,
        ...(
            isRadio &&
            (currentField.name !== DEAL_PROFILE_FIELDS.TRANCHE.name) &&
            {
              [currentField.name]: isBoolean(values[currentField.name]) ? values[currentField.name] : '',
            }
        ),
      };
    },
    {},
);

const getDateFieldsInitialValues = (fields: IDealProfileFormField[], values: object = {}) => fields.reduce(
  (result, currentField) => {
    const getDateValue = () => {
      if (!values[currentField.name]) {
        return null;
      }
      switch (typeof values[currentField.name]) {
        case 'object': // In Deal object date values are objects */
          const { timezone, dateTime } = values[currentField.name];
          return getTime(utcToZonedDate(timezone.timeZone, new Date(dateTime)));
        default: // In Tranche object date values are strings
          return getTime(utcToZonedDate('UTC', new Date(values[currentField.name])));
      }
    };

    return (
      {
        ...result,
        ...(currentField.isDate && { [currentField.name]: getDateValue() }),
      }
    );
  },
  {},
);

const getNumberFieldsInitialValues = (fields: IDealProfileFormField[], values: object = {}) => fields.reduce(
  (result, currentField) => (
    {
      ...result,
      ...(
        currentField.isNumber && {
          [currentField.name]: (
            (!!values[currentField.name] || (values[currentField.name] === 0))
              ? values[currentField.name].toString()
              : ''
          ),
        }
      ),
    }
  ),
  {},
);

const getNewObjectSelectFieldsInitialValues = (fields: IDealProfileFormField[]) => fields.reduce(
  (result, currentSelectField) => (
    {
      ...result,
      ...(currentSelectField.isSelect && { [currentSelectField.name]: null }),
    }
  ),
  {},
);

const getNewObjectBooleanFieldsInitialValues = (fields: IDealProfileFormField[]) => fields.reduce(
  (result, currentField) => (
    {
      ...result,
      ...(currentField.isBoolean && { [currentField.name]: false }),
    }
  ),
  {},
);

const getNewTrancheInitialValues = (ratingAgencies: IRatingAgency[], primaryIssuer: IPrimaryIssuer) => (
  {
    ...getTextFieldsInitialValues(DEAL_PROFILE_TRANCHE_FIELDS_ARRAY),
    ...getNewObjectBooleanFieldsInitialValues(DEAL_PROFILE_TRANCHE_FIELDS_ARRAY),
    ...getNumberFieldsInitialValues(DEAL_PROFILE_TRANCHE_FIELDS_ARRAY),
    ...getDateFieldsInitialValues(DEAL_PROFILE_TRANCHE_FIELDS_ARRAY),
    ...getNewObjectSelectFieldsInitialValues(DEAL_PROFILE_TRANCHE_FIELDS_ARRAY),
    [TRANCHE_FIELDS.ISSUER.name]: createCompanyOptionWithIssuerTicker(primaryIssuer),
    [TRANCHE_FIELDS.RATINGS.name]: ratingAgencies.reduce(
      (result, agency) => ({ ...result, [agency.abbreviation]: null }), {},
    ),
  }
);

const mapToAbbreviationOption = getOptionMapper({ pathToLabel: 'abbreviation' });

const mapBenchmarkToAbbreviationOption = (benchmark) => (
  {
    value: benchmark.id,
    label: benchmark.abbreviation,
    disabled: !benchmark.trusted,
  }
);

const getDealDetailsInitialSelectValues = (deal) => (
  {
    [DEAL_PROFILE_FIELDS.PARENT.name]: mapToDefaultOption(deal.parent),
    [DEAL_PROFILE_FIELDS.PRODUCT.name]: mapToAbbreviationOption(deal.product),
    [DEAL_PROFILE_FIELDS.PRIMARY_REGION.name]: deal.country ? mapToAbbreviationOption(deal.country.region) : null,
    [DEAL_PROFILE_FIELDS.COUNTRY.name]: !deal.country ? null : {
      ...mapToDefaultOption(deal.country),
      region: mapToAbbreviationOption(deal.country.region),
    },
    [DEAL_PROFILE_FIELDS.ABS_SECTOR_SUBSECTOR.name]: !(deal.sector && deal.subsector) ? null : {
      value: deal.subsector.id,
      sectorId: deal.sector.id,
      label: `${ deal.sector.name } - ${ deal.subsector.name }`,
    },
  }
);

const getNewDealInitialValues = ({ initialIssuer, initialDeal, ratingAgencies, isSecFiling }:
  { initialIssuer: IPrimaryIssuer, initialDeal: object, ratingAgencies: IRatingAgency[], isSecFiling: boolean },
) => {
  const getNewDealMultiSelectValuesInitialValues = (fields: IDealProfileFormField[]) => fields.reduce(
    (result, curr) => (
      {
        ...result,
        ...((curr.isMultiSelect || curr.companySelect) && { [curr.name]: [] }),
      }
    ),
    {},
  );

  return {
    ...getTextFieldsInitialValues(DEAL_PROFILE_FIELDS_ARRAY),
    ...getNewObjectBooleanFieldsInitialValues(DEAL_PROFILE_FIELDS_ARRAY),
    ...getNumberFieldsInitialValues(DEAL_PROFILE_FIELDS_ARRAY),
    ...getDateFieldsInitialValues(DEAL_PROFILE_FIELDS_ARRAY),
    ...getNewObjectSelectFieldsInitialValues(DEAL_PROFILE_FIELDS_ARRAY),
    ...getNewDealMultiSelectValuesInitialValues(DEAL_PROFILE_FIELDS_ARRAY),
    ...(!isEmpty(initialDeal) && getDealDetailsInitialSelectValues(initialDeal)),
    [DEAL_PROFILE_FIELDS.TRANCHES.name]: isSecFiling ? [] : [getNewTrancheInitialValues(ratingAgencies, initialIssuer)],
    [DEAL_PROFILE_FIELDS.PRIMARY_ISSUER.name]: !isEmpty(initialIssuer)
      ? createCompanyOptionWithIssuerTicker(initialIssuer)
      : null,
  };
};

const getExistingDealInitialValues = ({ item, ratingAgencies, options }: {
  item: DealProfilePayload, ratingAgencies: IRatingAgency[], options: IDealProfileOptions,
}) => (
  {
    ...item,
    [DEAL_PROFILE_FIELDS.RELATIVE_VALUE.name]: item.relativeValue || editorInitialValue,
    // DEAL DETAILS SELECT VALUES
    ...getDealDetailsInitialSelectValues(item),
    [DEAL_PROFILE_FIELDS.PRIMARY_ISSUER.name]: !isEmpty(item.primaryIssuer)
        ? createCompanyOptionWithIssuerTicker(item.primaryIssuer)
        : null,
    // TEXT VALUES AND DEAL ID
    ...getTextFieldsInitialValues(DEAL_PROFILE_FIELDS_ARRAY, item),
    [DEAL_PROFILE_FIELDS.DEAL_ID.name]: item.id,
    // NUMBER INPUT VALUES
    ...getNumberFieldsInitialValues(DEAL_PROFILE_FIELDS_ARRAY, item),
    // DATEPICKER VALUES
    ...getDateFieldsInitialValues(DEAL_PROFILE_FIELDS_ARRAY, item),
    // CHECKBOX VALUES OF TINYINT TYPE
    [DEAL_PROFILE_FIELDS.RESET.name]: !!item.reset,
    [DEAL_PROFILE_FIELDS.REFINANCING.name]: !!item.refinancing,
    // MULTI SELECT VALUES
    [DEAL_PROFILE_FIELDS.ADDITIONAL_ISSUERS.name]: (
      item.additionalIssuers?.map(createCompanyOptionWithIssuerTickerAndAbbreviation) || []
    ),
    [DEAL_PROFILE_FIELDS.ADDITIONAL_REGIONS.name]: item.regions
      ? item.regions
        .filter((region) => region.id !== item.country.region.id)
        .map(mapToAbbreviationOption)
      : [],
    [DEAL_PROFILE_FIELDS.STRUCTURING_LEADS.name]: (
      item.structuringLeads?.map(createCompanyOptionWithIssuerTickerAndAbbreviation) || []
    ),
    [DEAL_PROFILE_FIELDS.JOINT_LEADS.name]: (
      item.jointLeads?.map(createCompanyOptionWithIssuerTickerAndAbbreviation) || []
    ),
    [DEAL_PROFILE_FIELDS.LM_TYPE.name]: item?.lmTypeIds?.length
        ? options.lmTypeList.filter((lmType) => item.lmTypeIds.includes(lmType.value))
        : [],
    // TRANCHE
    [DEAL_PROFILE_FIELDS.TRANCHES.name]: item.tranches?.map(
      (tranche: IDealPayloadTranche) => (
        {
          ...tranche,
          [TRANCHE_FIELDS.IS_EXCLUDED.name]: false,
          ...getTextFieldsInitialValues(DEAL_PROFILE_TRANCHE_FIELDS_ARRAY, tranche),
          [TRANCHE_FIELDS.ORDERING_POSITION.name]: (
            tranche[TRANCHE_FIELDS.ORDERING_POSITION.name]
          ),
          [TRANCHE_FIELDS.ISSUER.name]: !isEmpty(tranche.issuer)
              ? createCompanyOptionWithIssuerTicker(tranche.issuer)
              : null,
          ...getNumberFieldsInitialValues(DEAL_PROFILE_TRANCHE_FIELDS_ARRAY, tranche),
          ...getRadioFieldsInitialValues(DEAL_PROFILE_TRANCHE_FIELDS_ARRAY, tranche),
          [TRANCHE_FIELDS.SIZE.name]: tranche.size?.size.toString() || '',
          ...getDateFieldsInitialValues(DEAL_PROFILE_TRANCHE_FIELDS_ARRAY, tranche),
          // CODES VALUES
          [TRANCHE_FIELDS.CUSIPS.name]: tranche.cusips?.join(',') || '',
          [TRANCHE_FIELDS.CUSIPS_REGS.name]: tranche.cusipsRegs?.join(',') || '',
          [TRANCHE_FIELDS.ISINS.name]: tranche.isins?.join(',') || '',
          // SELECT VALUES
          [TRANCHE_FIELDS.CURRENCY.name]: (
            options.currencies.find((cur) => tranche.currencyId === cur.value)
          ),
          [TRANCHE_FIELDS.RANKING.name]: (
            tranche.ranking ? mapToDefaultOption(tranche.ranking) : null
          ),
          [TRANCHE_FIELDS.COUPON_TYPE.name]: (
            tranche.couponType ? mapToDefaultOption(tranche.couponType) : null
          ),
          [TRANCHE_FIELDS.LISTING.name]: (
              tranche.listingId ? options.listingList.find((i) => i.value === tranche.listingId) : null
          ),
          [TRANCHE_FIELDS.UOP.name]: (
              tranche.uopId ? options.uopList.find((i) => i.value === tranche.uopId) : null
          ),
          [TRANCHE_FIELDS.ESG.name]: (
              tranche.esgId ? options.esgList.find((i) => i.value === tranche.esgId) : null
          ),
          [TRANCHE_FIELDS.DEBUT.name]: (
              tranche.debutId ? options.debutList.find((i) => i.value === tranche.debutId) : null
          ),
          [TRANCHE_FIELDS.REGISTRATION.name]: (
            tranche.registration ? mapToDefaultOption(tranche.registration) : null
          ),
          [TRANCHE_FIELDS.BENCHMARK.name]: (
            tranche.benchmark ? mapBenchmarkToAbbreviationOption(tranche.benchmark) : null
          ),
          [TRANCHE_FIELDS.STRUCTURING_LEADS.name]: (
            tranche.structuringLeads?.map(createCompanyOptionWithIssuerTickerAndAbbreviation) || []
          ),
          [TRANCHE_FIELDS.LEFT_LEADS.name]: (
            tranche.leftLeads?.map(createCompanyOptionWithIssuerTickerAndAbbreviation) || []
          ),
          [TRANCHE_FIELDS.B_D_LEADS.name]: (
            tranche.billingDelivering?.map(createCompanyOptionWithIssuerTickerAndAbbreviation) || []
          ),
          [TRANCHE_FIELDS.DEALER_MANAGERS.name]: (
            tranche.dealerManagers?.map(createCompanyOptionWithIssuerTickerAndAbbreviation) || []
          ),
          [TRANCHE_FIELDS.JOINT_LEADS.name]: (
            tranche.jointLeads?.map(createCompanyOptionWithIssuerTickerAndAbbreviation) || []
          ),
          [TRANCHE_FIELDS.ACTIVE_LEADS.name]: (
            tranche.activeLeads?.map(createCompanyOptionWithIssuerTickerAndAbbreviation) || []
          ),
          [TRANCHE_FIELDS.PASSIVE_LEADS.name]: (
            tranche.passiveLeads?.map(createCompanyOptionWithIssuerTickerAndAbbreviation) || []
          ),
          [TRANCHE_FIELDS.CO_MANAGERS.name]: (
            tranche.coManagers?.map(createCompanyOptionWithIssuerTickerAndAbbreviation) || []
          ),
          [TRANCHE_FIELDS.COORDINATING_MANAGER.name]: (
            tranche.coordinatingManagers?.map(createCompanyOptionWithIssuerTickerAndAbbreviation) || []
          ),
          [TRANCHE_FIELDS.DI_COORDINATOR.name]: (
            tranche.diCoordinators?.map(createCompanyOptionWithIssuerTickerAndAbbreviation) || []
          ),
          // RATINGS VALUES
          [TRANCHE_FIELDS.RATINGS.name]: ratingAgencies.reduce(
            (resultRatings, currentAgency) => (
              {
                ...resultRatings,
                [currentAgency.abbreviation]: tranche.ratings[currentAgency.id]
                  ? mapToAbbreviationOption(tranche.ratings[currentAgency.id][0])
                  : null,
              }
            ),
            {},
          ),
        }
      ),
    ) || [],
  }
);

const useDealInitialValues = (
  {
    item,
    ratingAgencies,
    isInitialized,
    isCreateNewDeal,
    options,
    initialIssuer,
    initialDeal,
    isSecFiling,
  },
) => {
  const [initialValues, setInitialValues] = useState({});

  useEffect(
    () => {
      if (isInitialized) {
        setInitialValues(
          isCreateNewDeal
            ? getNewDealInitialValues({ initialIssuer, initialDeal, ratingAgencies, isSecFiling })
            : getExistingDealInitialValues({ item, ratingAgencies, options }),
        );
      }
    },
    [isInitialized, initialIssuer],
  );

  return initialValues;
};

export default useDealInitialValues;
export { getNewTrancheInitialValues };
